import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as DOMPurify from "dompurify";
import "@styles/readers.scss";
import { Image } from "semantic-ui-react";

import { Div, Button } from "@components/Generics.react";

export default function EventReader(props) {
    const { deck, currentState, recordAttempt } = props;
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const event_date = new Date(deck.content?.date); // Replace this with your event_date
    const localeDate = event_date.toLocaleDateString("en-IN", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });

    const linkValid = typeof deck.content?.link === "string";

    // ========================= Render Function =================================
    return (
        <Div white rounded fluid fullht>
            <Div rounded noOverflow>
                <Image src={deck.content?.image} />
            </Div>
            <Div fluid left-align fullht padded>
                <Div txtColor="#333333" big bold uppercase padTop>
                    {deck.content?.name}
                </Div>
                <Div gutter italics>
                    {localeDate}
                </Div>
                <Div
                    gutter
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(deck.content?.description),
                    }}
                />
                {linkValid && (
                    <a href={deck.content?.link}>
                        <Button primary content={t("deck.news.buttonText")} />
                    </a>
                )}
                <br />
                <br />
            </Div>
        </Div>
    );
}
