// Import from NPM
// -------------------------------------
import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { Div } from "@components/Generics.react";

export default function ActionCard(props) {
    const { icon, header, content, link } = props;

    // ========================= Render Function =================================
    return (
        <NavLink to={link}>
            <Div
                white
                medpad
                flex
                spaceAround
                noWrap
                txtCharcoal
                clickable
                snug
            >
                <Div
                    headline
                    txtGrey
                    basepad
                    style={{ borderRight: "3px solid #ffbf00" }}
                >
                    <Icon name={icon} />
                </Div>
                <Div padSides>
                    <Div big bold uppercase>
                        {header}
                    </Div>
                    <Div>{content}</Div>
                </Div>
                <Div huge>
                    <Icon name="chevron right" bordered inverted color="grey" />
                </Div>
            </Div>
        </NavLink>
    );
}
