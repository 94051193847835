import React, { useState } from "react";
import _ from "lodash";
import { Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, Button } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";

export default function Anagram(props) {
    const { data, slideSeq, trackData, deckId, pageNumber, setPageNumber } =
        props;
    const [correct, setCorrect] = useState(null);
    const [ansval, setAnsval] = useState("");
    const [showOverlay, setShowOverlay] = useState(false);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    function handleChange(e) {
        setAnsval(e.target.value);
    }

    function trackInteraction() {
        const isCorrect = data.answer.toLowerCase() === ansval.toLowerCase();
        setCorrect(isCorrect);
        setShowOverlay(true);
        if (trackData) {
            trackData({
                correct: isCorrect,
                response: ansval,
            });
            dispatch(
                trackQuestion({
                    deckId: deckId,
                    slideId: slideSeq,
                    userId: userId,
                    response: ansval,
                })
            );
        }
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
    }

    // ========================= Render Function =================================
    return (
        <Div fluid fullht zoom superpad relative>
            <Div big gutter>
                {data.question}
            </Div>
            <Div peat basepad center-align gutter>
                {_.shuffle(data.answer).join("")}
            </Div>
            <Div small italics>
                {t("deck.qdf.anagram")}
            </Div>
            <Div padTop>
                <Input
                    size="big"
                    fluid
                    placeholder={data.answer.replace(/[^-\s]/g, "*")}
                    value={ansval}
                    onChange={handleChange}
                />
                <Button
                    content={"Submit"}
                    fluid
                    primary
                    onClick={trackInteraction}
                />
            </Div>
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
