// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
    useGetArticleBuilderQuery,
    useDeleteArticleMutation,
} from "@api/apiV6";

import { Div, Image } from "@components/Generics.react";
import "@styles/navbars.scss";
import { text_truncate } from "@utilities/helpers";

import { Loader, Confirm, Icon } from "semantic-ui-react";
import ActionCard from "@components/ActionCard.react";
import PreviewLayout from "@layouts/PreviewLayout.react";
import { deckFormats } from "@schemas/deckFormats";

export default function ArticleView(props) {
    const { id, setTarget } = props;
    const { data: article, isSuccess, refetch } = useGetArticleBuilderQuery(id);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const articleType =
        _.find(deckFormats, {
            readerType: article?.category,
        }) || deckFormats[article?.category];
    const [deleteArticle] = useDeleteArticleMutation();
    const { t } = useTranslation("common");

    function doDelete() {
        const storedRecents = window.localStorage.getItem("libraryRecents");
        let libraryRecents = [];
        if (storedRecents) {
            libraryRecents = JSON.parse(storedRecents);
        }
        _.remove(libraryRecents, {
            key: article._id,
        });
        window.localStorage.setItem(
            "libraryRecents",
            JSON.stringify(libraryRecents)
        );
        deleteArticle(article._id);
    }

    const actionMap = {
        setup: `/builder/articles/${articleType?.category}/${article?._id}/setup`,
        edit: `/builder/editor/${
            deckFormats[articleType?.category]?.readerType
        }/${article?.decks[0]}/${article?.name}`,
        share: `/builder/articles/${articleType?.category}/${article?._id}/share`,
        // analytics: `/builder/analytics`,
    };

    // ========================= Render Function =================================
    return isSuccess && article ? (
        <Div
            flex
            noWrap
            spaceAround
            flexStart
            fullht
            basepad
            noBottomPad
            noOverflow
        >
            <Div basepad txtCharcoal fullht autoOverflowY>
                <Div flex spaceBetween gapped>
                    <Div
                        fullht
                        wd="15px"
                        ht="210px"
                        style={{ background: "#ff69b4" }}
                    />
                    <Div wd="calc(100% - 30px)">
                        <Div
                            float-right
                            big
                            clickable
                            style={{ marginTop: "15px" }}
                        >
                            <Icon
                                bordered
                                color="olive"
                                inverted
                                circular
                                name="refresh"
                                onClick={refetch}
                            />
                            <Icon
                                bordered
                                color="red"
                                inverted
                                circular
                                name="trash"
                                onClick={() => setDeleteConfirm(true)}
                            />
                        </Div>
                        <Div uppercase>
                            {t("components.edit")}{" "}
                            {articleType?.name || article.category || "Article"}
                            :
                        </Div>
                        <Div massive altText uppercase gutter>
                            {text_truncate(article.name, 30)}
                        </Div>
                        <Image src={article.image} snubbed />{" "}
                    </Div>
                </Div>
                <Div clear maxWd="460px">
                    <br />
                    <Div basepad ash snubbed>
                        {_.map(_.keys(actionMap), (act, idx) => (
                            <ActionCard
                                key={`${article._id}-action-${idx}`}
                                icon={t(
                                    `builder.viewPanels.article.${act}.icon`
                                )}
                                header={t(
                                    `builder.viewPanels.article.${act}.header`
                                )}
                                content={t(
                                    `builder.viewPanels.article.${act}.content`
                                )}
                                link={actionMap[act]}
                            />
                        ))}
                    </Div>
                </Div>
            </Div>
            <PreviewLayout
                url={`/content/${article.category}/${article.identifier}`}
            />
            <Confirm
                open={deleteConfirm}
                onCancel={() => setDeleteConfirm(false)}
                onConfirm={() => {
                    setTarget(false);
                    doDelete();
                    setDeleteConfirm(false);
                    toast("Article Deleted");
                }}
            />
        </Div>
    ) : (
        <Loader active inverted />
    );
}
