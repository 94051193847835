import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { Card, Image, Label } from "semantic-ui-react";
import "@styles/components.scss";
import { text_truncate, snaked, formatDateAgo } from "@utilities/helpers";
import AbstractImageGenerator from "./AbstractImageGenerator.react";
import { deckFormats } from "@schemas/deckFormats";

export default function PortraitCard(props) {
    const { article, noImage, feedStyle } = props;
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const icon =
        deckFormats[article?.category]?.icons[0] ||
        deckFormats.default.icons[0];

    const arStyle = {
        width: "100%",
        height: "0",
        paddingBottom: "133.33%",
    };
    const imgSection = !noImage && (
        <Div style={arStyle}>
            {article?.image ? (
                <Div rounded relative noOverflow style={arStyle}>
                    <Image
                        src={article?.image}
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            height: "100%",
                            width: "auto",
                            maxWidth: "500%",
                            transform: "translate(-25%)",
                        }}
                    />
                </Div>
            ) : (
                <Div rounded danger style={arStyle} />
            )}
        </Div>
    );

    const headerSection = (
        <Div fluid big bold style={{ margin: "5px auto" }}>
            {text_truncate(article?.name, 40)}
        </Div>
    );

    const tagsSection = (
        <Div ht="1.5em" noOverflow clear>
            {_.map(article?.tags, (tag, idx) => {
                return (
                    <Label
                        key={`article-tag-${article?.id}-${idx}`}
                        horizontal
                        size="mini"
                    >
                        {!_.startsWith(t(`tags.${snaked(tag.name)}`), "tags")
                            ? t(`tags.${snaked(tag.name)}`)
                            : tag.name}
                    </Label>
                );
            })}
        </Div>
    );

    const metaSection = `${article?.uniques || 0} View${
        article?.uniques === 1 ? "" : "s"
    } | ${formatDateAgo(article?.createdAt)}`;
    // ========================= Render Function =================================

    return (
        <Div
            small
            relative
            clickable
            onClick={() =>
                navigate(`/content/${article?.category}/${article?.identifier}`)
            }
            rimmed
            className="portrait-article-card"
        >
            <Div relative>
                {imgSection}
                <Div
                    absolute
                    rounded
                    style={{ ...arStyle, top: 0 }}
                    className="pc-overlay"
                >
                    <Div
                        big
                        absolute
                        slightShadow
                        midnight
                        rimmed
                        pureCircle
                        style={{ top: "10px", right: "10px" }}
                    >
                        <i
                            className={`icon icm icon-${icon}`}
                            style={{ margin: 0 }}
                        />
                    </Div>
                    <Div
                        medpad
                        txtIvory
                        left-align
                        absolute
                        style={{ bottom: 0 }}
                    >
                        {headerSection}
                        {metaSection}
                    </Div>
                </Div>
            </Div>
            {/* 
                {tagsSection}
                 */}
        </Div>
    );
}
