// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Image } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import ImageUploader from "@components/ImageUploader.react";

export default function ImageField(props) {
    const {
        target,
        header,
        help,
        value,
        setValue,
        height,
        aspectRatio = 0.5625,
    } = props;

    const [imgEditorOpen, setImgEditorOpen] = useState(false);
    const { t } = useTranslation("common");

    const thisImage = target && value ? value[target] || "" : value;
    // ========================= Render Function =================================
    return (
        <Div
            minHt={height}
            ht={height}
            medpad
            snug
            ivory
            style={{ paddingLeft: "15px" }}
        >
            <Div
                float-left
                snubbed
                noOverflow
                wd={`${90 / aspectRatio - 20}px`}
                style={{ marginRight: "20px" }}
            >
                <Image src={thisImage} />
            </Div>
            <Div big rimmed>
                {header}
                {help && (
                    <Div tiny italics txtHalf gapSlice>
                        {help}
                    </Div>
                )}
            </Div>
            <Div rimmed>
                <Button
                    content={`${t("components.change")} ${header}`}
                    primary
                    onClick={() => setImgEditorOpen(true)}
                />
                {thisImage && (
                    <Button
                        icon="trash"
                        danger={+true}
                        onClick={() => setValue(target ? { [target]: null } : null)}
                    />
                )}
                <ImageUploader
                    modalOpen={imgEditorOpen}
                    handleModalClose={() => setImgEditorOpen(false)}
                    handleModalSave={(image) => {
                        setValue(target ? { [target]: image } : image);
                        setImgEditorOpen(false);
                    }}
                    value={thisImage}
                    aspectRatio={aspectRatio}
                    mWidth={aspectRatio < 0.75 ? 512 : 384}
                />
            </Div>
        </Div>
    );
}
