// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { Dropdown, Divider } from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";
import SettingsToggle from "../formComponents/SettingsToggle.react";

export default function QdfSettings(props) {
    const { deck, setDeck } = props;
    const { t } = useTranslation("common");
    // ========================= Render Function =================================
    return (
        <Div>
            {settings.languages && settings.languages.length > 1 && (
                <Div compact>
                    <Div gapBottom bold>
                        {t("builder.dic.selectLanguage")}:
                    </Div>
                    <Dropdown
                        placeholder={t("builder.dic.selectLanguage")}
                        fluid
                        selection
                        options={_.map(settings.languages, (l) => ({
                            key: l.code,
                            text: l.identifier,
                            value: l.code,
                        }))}
                        defaultValue={"en"}
                        value={deck.lang}
                        onChange={(e, { value }) =>
                            setDeck({
                                ...deck,
                                ...{ lang: value },
                            })
                        }
                    />
                </Div>
            )}
            <Divider />
            <Div gapBottom bold compact>
                {t("builder.dic.funcToggle")}:
            </Div>
            <SettingsToggle
                {...props}
                label={t("builder.deck.enableNav")}
                targetKey={"navEnabled"}
            />
            <SettingsToggle
                {...props}
                label={t("builder.deck.enableFeedback")}
                targetKey={"enableFeedback"}
            />
            <SettingsToggle
                {...props}
                label={t("builder.deck.enableMenu")}
                targetKey={"jumpAhead"}
            />
            <SettingsToggle
                {...props}
                label={t("builder.deck.enableVO")}
                targetKey={"voEnabled"}
            />
        </Div>
    );
}
