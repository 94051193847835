// -------------------------------------
// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Label, Search, Icon } from "semantic-ui-react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { useSendSearchHistoryMutation } from "@api/apiV6";

// -------------------------------------
// Import Styles
// -------------------------------------
import "@styles/components.scss";

// -------------------------------------
// Constants
// -------------------------------------
// prettier-ignore
const blacklist = ["","and","or","but","nor","for","yet","so","in","on","at","by","to","from","with","over","under","into","through","after","before","during","about","above","below","behind","beside","beyond","since","until","unless","although","because","if","where","whether","while","than","throughout","whereas","wherever","though","whenever","whilst","however","notwithstanding","provided","providing","as","that","inasmuch","till","lest","much","order","only","even","rather","when",
];

export default function SearchField(props) {
    const { t } = useTranslation("common");
    const { placeholder = t("search.placeholder") } = props;
    const articles = useSelector((state) => state.articles);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredArticles, setFilteredArticles] = useState([]);

    const navigate = useNavigate();
    const [saveSearch] = useSendSearchHistoryMutation();

    const handleSearch = (searchText) => {
        const findWordWithSubstring = (str, substr) => {
            const cleanedStr = str.replace(/[^a-zA-Z0-9_ -]/g, "");
            const cleanedSubstr = substr.replace(/[^a-zA-Z0-9_ -]/g, "");
            const substrWords = cleanedSubstr.split(" ");

            // Split the string into an array of words
            const words = cleanedStr.split(" ");

            const foundSubstrings = [];

            for (let i = 0; i <= words.length - substrWords.length; i++) {
                const windowWords = words.slice(i, i + substrWords.length);
                const windowString = windowWords.join(" ");

                if (
                    windowString
                        .toLowerCase()
                        .startsWith(cleanedSubstr.toLowerCase())
                ) {
                    foundSubstrings.push(_.capitalize(windowString));
                }
            }

            return foundSubstrings.join(" ");
        };

        const countUniqueElements = (arr) => {
            // Use lodash's `countBy` function to get the count of each element
            const elementCountMap = _.countBy(arr);

            // Convert the elementCountMap to the desired format
            const result = Object.entries(elementCountMap).map(
                ([title, count]) => ({ title, count })
            );

            return result;
        };
        const lowerCaseSearchTerm = searchText.toLowerCase();
        const searchResultTerms = _.map(
            _.orderBy(
                _.filter(articles.list, (article) => {
                    const articleName = article.name.toLowerCase();
                    return articleName.includes(lowerCaseSearchTerm);
                }),
                ["priority"],
                ["desc"]
            ),
            (thisArticle) => findWordWithSubstring(thisArticle.name, searchText)
        ).slice(0, 7);

        const customComparator = (a, b) =>
            _.isEqual(a.toLowerCase(), b.toLowerCase());
        let fArticles = _.orderBy(
            countUniqueElements(
                _.differenceWith(searchResultTerms, blacklist, customComparator)
            ),
            ["count"],
            ["desc"]
        );
        const allTags = _.keys(articles.taggedList);
        const matchingTags =
            lowerCaseSearchTerm.length > 2
                ? _.map(
                      _.filter(allTags, (tag) =>
                          tag.includes(lowerCaseSearchTerm)
                      ),
                      (thisTag) => {
                          return {
                              title: _.capitalize(thisTag).replace(/_/g, " "),
                          };
                      }
                  )
                : [];
        setFilteredArticles(_.union(matchingTags, fArticles));
        setSearchTerm(searchText);
    };

    const handleKeyUp = (e) => {
        if (e.key === "Enter" && searchTerm.length > 2) {
            handleSubmit(searchTerm);
        }
    };

    const handleResultSelect = (event, { result }) => {
        setSearchTerm(result.title);
        handleSubmit(result.title);
    };

    function handleSubmit(term) {
        saveSearch(term);
        navigate(`/search?text=${term}`);
    }

    return (
        <Div inline>
            <Search
                fluid
                input={{
                    icon: (
                        <Icon
                            name="search"
                            link
                            onClick={() => handleSubmit(searchTerm)}
                        />
                    ),
                }}
                placeholder={placeholder}
                minCharacters={3}
                value={searchTerm}
                onSearchChange={(event, data) => handleSearch(data.value)}
                onResultSelect={handleResultSelect}
                onKeyUp={handleKeyUp}
                results={filteredArticles}
                resultRenderer={({ title, count }) => (
                    <div>
                        {title}
                        {count ? (
                            <Label style={{ float: "right" }}>{count}</Label>
                        ) : (
                            <Icon style={{ float: "right" }} name="tag" />
                        )}
                    </div>
                )}
            />
        </Div>
    );
}
