import React, { useState, useEffect, useRef } from "react";
import _, { set } from "lodash";
import { Segment, Dropdown, } from "semantic-ui-react";
import ReactTable from "react-table-v6";
import { Header, Loader, Grid, Icon} from "semantic-ui-react";
import { useGetDeckPathQuery, useGetSurveyStatusPathQuery } from "@api/apiV6";
import { Div, Button } from "@components/Generics.react";
export default function SurveyLearnersResponse(props) {
  const isPortrait = window.innerHeight > window.innerWidth;
  const reactTableRef = useRef(null);

  const [questionData, setQuestionData] = useState(null);
  const [learnerData, setLearnerData] = useState(null);
  const [activeLearner, setActiveLearner] = useState(null);

  const [questionResponse, setQuestionResponse] = useState(null);
  const [setSelectedLearner] = useState(null);



  const { activeTopic, results } = props;
  const assessmentId = activeTopic;
  const deckId = activeTopic;

  const { data: assessmentData } = useGetDeckPathQuery({ deckId: deckId });
  const { data: surveyData } = useGetSurveyStatusPathQuery({ deckId: deckId });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionData = assessmentData?.content.map((slide, index) => ({
          key: assessmentId + "#" + slide.slideSeq,
          value: assessmentId + "#" + slide.slideSeq,
          text: slide.data.question,
          template: slide.template,
          data: slide.data,
          sequence: index,

        }));
        const learnerSet = _.map(surveyData?.learners, (learner, index) => {
          let user = _.find(results, {
            user_id: learner._id,
          });
          return {
            key: learner._id,
            value: learner._id,
            sequence: index,
            text: user ? user.username : "",
            data: learner,
          };
        });
        if (learnerSet) {
          setQuestionData(questionData);
          setLearnerData(learnerSet);
          setSelectedLearner(deckId || learnerData.learners[0]._id);

        }

      } catch (e) {

      }
    };
    fetchData();
  }, [results, surveyData, assessmentData, assessmentId, deckId]);

  useEffect(() => {
    const selectedLearner = _.find(learnerData, {
      key: activeLearner,
    });

    if (selectedLearner) {
      const learnerResponse = _.map(
        _.sortBy(selectedLearner.data.questions, "seq"),
        (question) => {
          const selectedQuestion = _.find(questionData, {
            key: question.slideId,
          });
          return {
            name: selectedQuestion?.text,
            response: handleResponse(
              selectedQuestion?.template,
              selectedQuestion?.data,
              question.response
            ),
          };
        }
      );
      if (learnerResponse) {
        setQuestionResponse(learnerResponse);
      }
    }


  }, [
    activeLearner,
    questionData,
    learnerData,
  ]);

  const handleResponse = (template, data, response) => {
    switch (template) {
      case "true_false":
      case "single_select":
        return data.options[response].text;
      case "multiple_select":
        return response.join(", ");
      case "uploader":
        return (
          <a href={response} download="response">
            Download Response
          </a>
        );
      case "fill_in_the_blanks":
      default:
        return response;
    }
  };

  const downloadCSV = () => {
    const items = _.map(
      reactTableRef.getResolvedState().sortedData,
      "_original"
    );

    if (items.length > 0) {
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );

      csv.unshift(header.join(","));
      csv = csv.join("\r\n");

      const hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download =
        props.course.name.split(" ").join("_") + "-data.csv";
      hiddenElement.click();
    }
  };

  const fullColumns = [
    {
      Header: "Question",
      accessor: "name",
      minWidth: 128,
      style: { textAlign: "left", whiteSpace: "unset" },
    },
    {
      Header: "Response",
      id: "response",
      accessor: (d) => d.response,
      style: { textAlign: "center", whiteSpace: "unset" },
    },
  ];

  return (
    <Div vapor rounded relaxed={!isPortrait} small>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column teal>
            <Div spaced charcol bold nopad left-align large uppercase>
              Select a Learner
            </Div>
          </Grid.Column>
          <Grid.Column floated='left'>
            <Div spaced
              vapor
              right-align
            >
              <Button charcol>
                <Icon name='download' />
                Download CSV
              </Button>
            </Div>

          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid.Row>
        <Dropdown
          placeholder="Select Learners"
          search
          fluid
          selection
          //  value={learnerData._id}
          options={learnerData}
          onChange={(e, { value }) => setActiveLearner(value)}
        />
        <br />
        <ReactTable
          ref={reactTableRef}
          data={questionResponse || []}
          filterable
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id]).includes(filter.value)
              : true;
          }}
          pageSizeOptions={[5, 10]}
          columns={fullColumns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </Grid.Row>
    </Div>
  );
}
