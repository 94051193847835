// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Tab, Menu } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { primaryStyle, darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";
import "@styles/layouts.scss";

// Import Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import UserAvatar from "@components/UserAvatar.react";
import Badge from "@components/Badge.react";
import Certificate from "@components/Certificate.react";
import Carousel from "@components/Carousel.react";
import Reward from "@components/Reward.react";

export default function CourseRewards(props) {
    const { course } = props;
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    // ------------------------------------------
    // Collectibles Pane Mapping
    // ------------------------------------------
    function showCollectibles(readerType, Collectible) {
        return _.map(
            _.filter(course?.decks, { readerType: readerType }),
            (elm, i) => {
                return (
                    <Div
                        inline
                        className="small"
                        key={`${readerType}-reward-${i}`}
                    >
                        <Collectible
                            deck={elm?.blob}
                            disabled={!elm?.currentState?.completion}
                            logo={course?.logo}
                            small
                        />
                    </Div>
                );
            }
        );
    }
    const panes = [
        {
            menuItem: (
                <Menu.Item key="badges">{t(`course.rewards.badges`)}</Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <Div fluid center-aligned>
                        {showCollectibles("badgedeck", Badge)}
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="certificates">
                    {t(`course.rewards.certificates`)}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <Carousel dots arrows>
                        {showCollectibles("certdeck", Certificate)}
                    </Carousel>
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="rewards">
                    {t(`course.rewards.rewards`)}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <Carousel dots arrows>
                        {showCollectibles("rewarddeck", Reward)}
                    </Carousel>
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Div
            fullht
            fluid
            rounded
            padded
            padTop
            small
            style={darkStyle(settings.colors.main, "to bottom right")}
        >
            <br />
            <Div
                fit-content
                large
                centered
                relaxed
                circled
                style={primaryStyle(course?.colors)}
            >
                {t("course.rewards.header")}
            </Div>
            <Div center-align relaxed centered>
                <br />
                <UserAvatar
                    name={user.username}
                    image={user.image}
                    avatarConfig={user.avatarConfig}
                    size={100}
                />
            </Div>
            <Tab className="welcome-kit" menu={{ widths: 3 }} panes={panes} />
        </Div>
    );
}
