import React from "react";
import { useTranslation } from "react-i18next";
import { Div } from "@components/Generics.react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import TicketsTable from "./panels/TicketsTable.react";
import TicketForm from "./panels/TicketForm.react";
import HelpFaqs from "./panels/HelpFaqs.react";

export default function CreatorHelpDesk(props) {
    const { t } = useTranslation("common");

    const pageContent = (
        <Div fluid flex flexStart fullht>
            <Div wd="calc(100% - 360px)" fullht>
                <Div flex fluid flexStart fullht medpad>
                    <Div wd="360px" fullht medpad>
                        <TicketForm />
                    </Div>
                    <Div wd="calc(100% - 360px)" fullht medpad>
                        <TicketsTable />
                    </Div>
                </Div>
            </Div>
            <Div autoOverflowY wd="360px" fullht>
                <HelpFaqs />
            </Div>
        </Div>
    );

    return (
        <CreatorLayout
            title={t("helpdesk.header")}
            navName={t("helpdesk.header")}
            pageCode={"helpdesk"}
            mainPanel={pageContent}
            rootPage={false}
        />
    );
}
