import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "@components/Accordion.react";
import { faqSchema } from "@schemas/supportSchema";
import { Div } from "@components/Generics.react";
import { Input } from "semantic-ui-react";
export default function HelpFaqs(props) {
    const { t } = useTranslation("common");

    const [searchText, setSearchText] = useState("");

    const filteredFAQ = faqSchema.filter((item) =>
        item.question.toLowerCase().includes(searchText.toLowerCase())
    );

    // ========================= Render Function =================================
    return (
        <Div basepad ash fullht>
            <Div big bold uppercase gutter>
                {t("helpdesk.faq")}:
            </Div>
            <Input
                fluid
                placeholder={t("components.search")}
                icon={{ name: "search" }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
            <Div padTop ht="90%" autoOverflowY>
            <Accordion
                panels={filteredFAQ.map((item) => ({
                    title: item.question,
                    content: item.answer,
                }))}
            />
            </Div>
        </Div>
    );
}
