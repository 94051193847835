import React, { useEffect, useRef } from "react";
import _ from "lodash";
import * as DOMPurify from "dompurify";

import "@styles/readers.scss";
import { Div } from "@components/Generics.react";
import Accordion from "@components/Accordion.react";
import { Image } from "semantic-ui-react";

export default function ProductFAQ(props) {
    const { deck, recordAttempt } = props;
    const imageRef = useRef(null);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let panels = _.map(deck?.content?.questions, (item) => {
        return {
            title: <Div small>{item?.title}</Div>,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item?.description),
                    }}
                />
            ),
        };
    });

    return (
        <Div
            fluid
            flex
            maxWd="480px"
            fullht
            priText
            autoOverflowY
            className="product-faq-reader"
        >
            <Div relative topRounded noOverflow>
                <div ref={imageRef}>
                    <Image fluid src={deck?.content?.image} />
                    <Div
                        midnight
                        centered
                        center-align
                        bold
                        topRounded
                        className="product-name"
                        uppercase
                    >
                        {deck?.content?.name}
                    </Div>
                </div>
            </Div>
            <Div
                fluid
                ht={`calc(100% - ${imageRef.current?.clientHeight}px)`}
                relative
                layer={2}
            >
                <Accordion panels={panels}></Accordion>
            </Div>
        </Div>
    );
}
