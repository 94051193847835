import React, { useRef } from "react";
import _ from "lodash";

import ReactTable from "react-table-v6";
import { Div,Button } from "@components/Generics.react";
import { Dropdown, Statistic, Icon, Grid, Loader, Segment, Image } from "semantic-ui-react";

export default function AssessmentCompletionSubPanel(props) {
  const reactTableRef = useRef(null);
  const { aggregation, results } = props;

  const downloadCSV = () => {
    const items = _.map(
      reactTableRef.current.getResolvedState().sortedData,
      "_original"
    );
    if (items.length > 0) {
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      let csv = items.map((row) =>
        header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")
      );
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");
      let csvData = new Blob([csv], { type: "text/csv" });
      var csvUrl = URL.createObjectURL(csvData);
      var hiddenElement = document.createElement("a");
      hiddenElement.href = csvUrl;
      hiddenElement.target = "_blank";
      hiddenElement.download = props.course.name.split(" ").join("_") + "-data.csv";
      hiddenElement.click();
    }
  };

  let isPortrait = window.innerHeight > window.innerWidth;
  let pageStyle = {
    summarySection: {
      base: {
        width: isPortrait ? "92%" : "98%",
        textAlign: "center",
        padding: "0",
        margin: "10px 0 10px 15px",
      },
      radial: {
        width: "165px",
        display: "inline-block",
        padding: "0",
        margin: "2px 0",
      },
      h3Header: {
        margin: "0",
        textAlign: "center",
      },
      details: {
        width: "500px",
        display: "inline-block",
        padding: "0 0 0 15px",
        textAlign: "left",
        fontWeight: "bold",
      },
      detailHeader: {
        width: "128px",
        display: "inline-block",
      },
      statistic: {
        width: "165px",
        display: "inline-block",
        padding: "0",
        bottom: "10px",
      },
    },
  };

  let fullColumns = [
    {
      Header: "Learner",
      accessor: "name",
      minWidth: 128,
    },
    {
      Header: "Accessed",
      id: "accessed",
      accessor: (d) => d.view_attempts,
      Cell: ({ value }) =>
        parseInt(value, 0) === 0
          ? "No"
          : "Yes" +
          (value === null ? "" : " (" + value + " views)"),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return parseInt(row[filter.id], 0) !== 0;
        }
        return parseInt(row[filter.id], 0) === 0;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "Completion",
      id: "completion",
      accessor: (d) => d.completion,
      Cell: ({ value }) => (value === null ? "0%" : value + "%"),
      filterMethod: (filter, row) => {
        switch (filter.value) {
          case "zero":
            return parseInt(row[filter.id], 0) === 0;
          case "low":
            return (
              parseInt(row[filter.id], 0) < 40 &&
              parseInt(row[filter.id], 0) > 0
            );
          case "medium":
            return (
              parseInt(row[filter.id], 0) < 80 &&
              parseInt(row[filter.id], 0) > 40
            );
          case "high":
            return (
              parseInt(row[filter.id], 0) < 100 &&
              parseInt(row[filter.id], 0) > 80
            );
          case "full":
            return parseInt(row[filter.id], 0) === 100;
          case "all":
          default:
            return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="zero">0%</option>
          <option value="low">0-40%</option>
          <option value="medium">40-80%</option>
          <option value="high">80-99%</option>
          <option value="full">100%</option>
        </select>
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "Score",
      id: "score",
      accessor: (d) => d.score,
      Cell: ({ value }) =>
        value === null ? "Incomplete" : value,
      filterMethod: (filter, row) => {
        switch (filter.value) {
          case "incomplete":
            return row[filter.id] === null;
          case "fail":
            return row._original.complete === false
              ? row[filter.id]
              : "";
          case "passed":
            return row._original.complete === true
              ? row[filter.id]
              : "";
          case "full":
            return parseInt(row[filter.id], 0) === 100;
          case "all":
          default:
            return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="incomplete">Incomplete</option>
          <option value="fail">Failed</option>
          <option value="passed">Passed</option>
          <option value="full">Full Marks</option>
        </select>
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "TimeSpent",
      id: "time_spent",
      accessor: (d) => d.time_spent,
      Cell: ({ value }) =>
        parseInt(value, 0) === null
          ? "0 secs"
          : parseInt(value, 0) < 60
            ? parseInt(value, 0) + " secs"
            : Math.round(parseInt(value, 0) / 60, 0) + " mins",
      filterable: false,
      style: { textAlign: "center" },
    },
  ];

  return (
    <Div vapor rounded >
      <Segment basic style={pageStyle.summarySection.base}>
        <Grid.Column floated='left'>
          <Div spaced
            vapor
            right-align
          >
            <Button charcol>
              <Icon name='download' />
              Download CSV
            </Button>
          </Div>

        </Grid.Column>
        <Grid columns={3} divided>
          <Grid.Row>
            <Grid.Column>
              <Div centered basepad huge>
                {parseInt(aggregation.accessed_by, 0)}
                <h3>
                  Accessed by Audience Members
                </h3>
              </Div>
            </Grid.Column>
            <Grid.Column>
              <Div centered basepad huge>
                {parseInt(aggregation.completed_by, 0)}
                <h3>
                  Completed by Audience Members
                </h3>
              </Div>
            </Grid.Column>
            <Grid.Column>
              <Div centered basepad huge>
                {parseInt(aggregation.average_completion, 0)}
                <h3>
                  Avrage completions
                </h3>
              </Div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <ReactTable
        ref={reactTableRef}
        data={results}
        filterable
        defaultFilterMethod={(filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
        }}
        columns={fullColumns}
        defaultPageSize={5}
        className="-striped -highlight"
      />
    </Div>


  );
};


