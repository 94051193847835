// Import from NPM
// -------------------------------------
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import { Accordion } from "semantic-ui-react";
import { Div, Button, Icon } from "@components/Generics.react";
import "@styles/editor.scss";

export default function SubformBlock(props) {
    const { value, setValue, field, hasStructure } = props;
    const [contentArray, setContentArray] = useState(value);
    const FieldComponent = field.formField[0];
    useEffect(() => {
        if (contentArray !== value)
            setValue(
                field.target ? { [field.target]: contentArray } : contentArray
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentArray]);
    const { t } = useTranslation("common");

    function updateContentArray(data, index) {
        if (field.radioField) {
            let newContentArray = [...contentArray];
            if (data.correct === "checked") {
                newContentArray = newContentArray.map((item, index) => ({
                    ...item,
                    correct: "",
                }));
            }
            newContentArray[index] = data;
            setContentArray(newContentArray);
        } else {
            let newContentArray = [...contentArray];
            newContentArray[index] = data;
            setContentArray(newContentArray);
        }
    }

    function updateSubform(data, index, fieldName) {
        let newContentArray = [...contentArray];
        newContentArray[index] = {
            ...newContentArray[index],
            ...{ [fieldName]: data[fieldName] },
        };
        setContentArray(newContentArray);
    }

    function addItem() {
        let newContentArray = contentArray ? [...contentArray] : [];
        newContentArray.push(hasStructure ? {} : "");
        setContentArray(newContentArray);
    }
    function deleteItem(index) {
        let newContentArray = [...contentArray];
        newContentArray.splice(index, 1);
        setContentArray(newContentArray);
    }

    const flatComponent =
        !hasStructure &&
        _.map(
            field.count && contentArray
                ? contentArray.slice(0, field.count)
                : contentArray,
            (subfieldContent, idy) => {
                return (
                    <Div
                        key={`form-subfield-${idy}`}
                        wd={field.width}
                        float-left={field.width != null}
                        clear={field.width == null}
                        relative
                        noOverflow
                    >
                        {!field.count && (
                            <Div
                                className="sub-block-delete"
                                absolute
                                medpad
                                danger
                                big
                                layer={2}
                                dropShadow
                                clickable
                                onClick={() => deleteItem(idy)}
                            >
                                <Icon name="trash" />
                            </Div>
                        )}
                        <FieldComponent
                            {...field}
                            {...{ help: null, header: field.subheader }}
                            value={subfieldContent}
                            setValue={(data) => updateContentArray(data, idy)}
                        />
                    </Div>
                );
            }
        );

    const structuredComponent =
        hasStructure &&
        _.map(contentArray, (subfieldContent, idy) => ({
            key: `accordion-panel-${idy}`,
            title:
                subfieldContent[field.subheader] ||
                t("builder.deck.newSubform"),
            content: {
                content: (
                    <Div
                        key={`form-subfield-${idy}`}
                        wd={field.width}
                        float-left={field.width != null}
                        clear={field.width == null}
                        relative
                        noOverflow
                    >
                        <Div
                            className="sub-block-delete"
                            absolute
                            medpad
                            danger
                            big
                            layer={2}
                            dropShadow
                            clickable
                            onClick={() => deleteItem(idy)}
                        >
                            <Icon name="trash" />
                        </Div>
                        <Div rimmed vapor bordered>
                            {_.map(field.formField, (sectionField, idz) => {
                                if (_.isArray(sectionField.formField)) {
                                    return (
                                        <Div
                                            key={`form-sectionField-${idz}`}
                                            wd={sectionField.width}
                                            float-left={
                                                sectionField.width != null
                                            }
                                            clear={sectionField.width == null}
                                        >
                                            <SubformBlock
                                                field={sectionField}
                                                hasStructure={
                                                    typeof sectionField
                                                        .formField[0] ===
                                                    "object"
                                                }
                                                value={
                                                    sectionField.target
                                                        ? subfieldContent[
                                                              sectionField
                                                                  .target
                                                          ]
                                                        : subfieldContent
                                                }
                                                setValue={(data) =>
                                                    updateSubform(
                                                        data,
                                                        idy,
                                                        sectionField.target
                                                    )
                                                }
                                            />
                                        </Div>
                                    );
                                } else {
                                    const FieldComponent =
                                        sectionField.formField;
                                    return (
                                        <Div
                                            key={`form-sectionField-${idz}`}
                                            wd={sectionField.width}
                                            float-left={
                                                sectionField.width != null
                                            }
                                            clear={sectionField.width == null}
                                        >
                                            <FieldComponent
                                                {...sectionField}
                                                value={subfieldContent}
                                                setValue={(data) =>
                                                    updateSubform(
                                                        data,
                                                        idy,
                                                        sectionField.target
                                                    )
                                                }
                                            />
                                        </Div>
                                    );
                                }
                            })}
                        </Div>
                    </Div>
                ),
            },
        }));

    // ========================= Render Function =================================
    return (
        <Div snug medpad ivory>
            <Div big rimmed>
                {field.header}
                {field.help && (
                    <Div tiny italics txtHalf gapSlice>
                        {field.help}
                    </Div>
                )}
            </Div>
            <Div>
                {contentArray && contentArray.length > 0 ? (
                    <>
                        {hasStructure ? (
                            <Accordion
                                styled
                                fluid
                                panels={structuredComponent}
                            />
                        ) : (
                            flatComponent
                        )}{" "}
                    </>
                ) : (
                    <Div fluid relaxed ash>
                        {t("builder.deck.noContent")}
                    </Div>
                )}
                {(!field.count ||
                    !contentArray ||
                    (field.count && contentArray.length < field.count)) && (
                    <Button
                        fluid
                        extra={+true}
                        icon="plus"
                        labelPosition="right"
                        content={t("builder.deck.addItem")}
                        onClick={addItem}
                    />
                )}
            </Div>
        </Div>
    );
}
