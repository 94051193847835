import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { snaked } from "@utilities/helpers";

const initialState = { list: [], taggedList: {} };

export const articlesSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {
        clearArticles: () => {
            return initialState;
        },
        organizeArticles: (state, action) => {
            const tagList = _.chain(action.payload.list)
                .flatMap((article) =>
                    article.tags.map((tag) => ({
                        tag_name: snaked(tag.name),
                        _id: tag._id,
                        article: article,
                    }))
                )
                .groupBy("tag_name")
                .mapValues((groupedTags) => ({
                    _id: groupedTags[0]._id,
                    articles: groupedTags.map((tag) => tag.article),
                }))
                .value();
            const categoryList = _(action.payload.list)
                .groupBy("category")
                .mapValues((articles) => ({ articles }))
                .value();
            state.list = _.keyBy(action.payload.list, "identifier");
            state.taggedList = _.merge(tagList, categoryList);
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { clearArticles, organizeArticles } = articlesSlice.actions;

export default articlesSlice.reducer;
