import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import { Divider, Icon } from "semantic-ui-react";
import { useFeatures } from "flagged";

import {
    useGetArticleInventoryQuery,
    useGetCourseInventoryQuery,
    useGetProgramInventoryQuery,
} from "@api/apiV6";

import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div, Button } from "@components/Generics.react";
import BrowserBar from "@components/BrowserBar.react";
import CourseView from "./panels/CourseView.react";
import ArticleView from "./panels/ArticleView.react";
import ProgramView from "./panels/ProgramView.react";
import NewContentModal from "./panels/NewContentModal.react";

export default function BuilderLibrary(props) {
    const { data: articles, isSuccess: articlesSuccess } =
        useGetArticleInventoryQuery();
    const { data: courses, isSuccess: coursesSuccess } =
        useGetCourseInventoryQuery();
    const { data: programs, isSuccess: programsSuccess } =
        useGetProgramInventoryQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const features = useFeatures();
    const { t } = useTranslation("common");

    const [target, setTarget] = useState(null);
    const [newModalOpen, setNewModalOpen] = useState(false);

    useEffect(() => {
        const thisTarget = searchParams.get("target");
        const thisSection = searchParams.get("section");
        if (thisTarget != null && thisSection != null) {
            setTarget({
                key: thisTarget,
                type: "child",
                section: thisSection,
            });
        }
    }, [searchParams]);

    const setSelected = (selectedTarget) => {
        setSearchParams(
            selectedTarget.type === "child"
                ? {
                      target: selectedTarget.key,
                      section: selectedTarget.section,
                  }
                : {}
        );
        setTarget(selectedTarget);
    };

    // Content Structure passed has to be {_id, name, parent:{_id, name}}
    const contentSections = [
        features.courseware && {
            section: "courses",
            name: t("courseware"),
            data: coursesSuccess
                ? features.courses.maxCourses === "Inf"
                    ? courses
                    : courses.slice(0, features.courses.maxCourses)
                : [],
            extra: programsSuccess
                ? _.filter(
                      programs,
                      (program) =>
                          program.node_list.length === 0 &&
                          program.list_type === "Course"
                  )
                : [],
            pin: [t("builder.dic.pubLib")],
            collection: t("builder.dic.programs"),
            groupBy: null,
        },
        features.learningCenter && {
            section: "articles",
            name: t("learningCenter"),
            data: articlesSuccess
                ? _.map(articles, (article) => {
                      return {
                          ...article,
                          parent:
                              article.parent != null
                                  ? article.parent
                                  : {
                                        _id: t("builder.dic.generalContent"),
                                        name: t("builder.dic.generalContent"),
                                    },
                      };
                  })
                : [],
            extra: programsSuccess
                ? _.filter(
                      programs,
                      (program) =>
                          program.node_list.length === 0 &&
                          program.list_type === "Article"
                  )
                : [],
            pin: [t("builder.dic.generalContent")],
            collection: t("builder.dic.series"),
            groupBy: "category",
        },
    ].filter(Boolean);

    const btnSections = [
        {
            name: t("builder.dic.createNew"),
            icon: "add",
            action: () => setNewModalOpen(true),
        },
    ];

    const activeItem = searchParams.get("target");

    function displaySection() {
        let component = (
            <Div flex ht="calc(100% - 60px)" fluid>
                <Div ash megapad center-align slightShadow>
                    <Icon
                        size="huge"
                        name="sitemap"
                        circular
                        inverted
                        color="grey"
                    />
                    <Div padTop headline uppercase fluid altText gutter>
                        {t("builder.welcome.contentLibrary")}
                    </Div>
                    <Div large>{t("builder.welcome.selectFile")}</Div>
                    <Divider horizontal>{t("components.or")}</Divider>
                    <Button
                        primary
                        content={t("builder.dic.createNew")}
                        onClick={() => setNewModalOpen(true)}
                    />
                </Div>
            </Div>
        );
        if (target && target.key.length === 24)
            switch (target.section) {
                case "courses":
                    component =
                        target.type === "parent" ? (
                            <ProgramView
                                id={target.key}
                                type={t("builder.dic.program")}
                                setTarget={setSelected}
                            />
                        ) : (
                            <CourseView
                                id={target.key}
                                setTarget={setSelected}
                            />
                        );
                    break;
                case "articles":
                    component =
                        target.type === "parent" ? (
                            <ProgramView
                                id={target.key}
                                type={t("builder.dic.serie")}
                                setTarget={setSelected}
                            />
                        ) : (
                            <ArticleView
                                id={target.key}
                                setTarget={setSelected}
                            />
                        );
                    break;
                default:
                    component = (
                        <ProgramView
                            id={target.key}
                            type={t("builder.dic.program")}
                            setTarget={setSelected}
                        />
                    );
            }
        return component;
    }

    const pageContent = (
        <>
            <Div
                fullht
                wd="300px"
                float-left
                vapor
                relative
                layer={2}
                slightShadow
            >
                <BrowserBar
                    name={t("builder.welcome.browserHeader")}
                    contentSections={contentSections}
                    btnSections={btnSections}
                    setTarget={setSelected}
                    activeItem={activeItem}
                />
            </Div>
            <Div fullht wd="calc(100% - 300px)" float-left ivory>
                {displaySection()}
            </Div>
            <NewContentModal
                programs={programs}
                newModalOpen={newModalOpen}
                setNewModalOpen={setNewModalOpen}
                setSelected={setSelected}
            />
        </>
    );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={t("appCreator")}
            navName={t("builder.welcome.library")}
            pageCode={"library"}
            mainPanel={pageContent}
            rootPage={true}
        />
    );
}
