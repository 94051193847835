import React from "react";
import { Div } from "@components/Generics.react";
import * as DOMPurify from "dompurify";

export default function Paragraph(props) {
    const { paragraph } = props.data;

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            fullht
            zoom
            superpad
            big
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(paragraph),
            }}
        />
    );
}
