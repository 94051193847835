import React from "react";
import * as DOMPurify from "dompurify";
import { Div, Button } from "@components/Generics.react";
export default function ExternalLink(props) {
    const { paragraph } = props.data;
    return (
        <Div>
            <Div
                big
                superpad
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph),
                }}
            />
            <Div huge superpad>
                <a
                    href={props.data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button primary fluid>
                        Go
                    </Button>
                </a>
            </Div>
        </Div>
    );
}
