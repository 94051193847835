// Import from NPM
// -------------------------------------
import React from "react";
import { Image } from "semantic-ui-react";
import { Div } from "@components/Generics.react";

export default function BuilderHeader(props) {
    const { image, header, description, imgWidth = "256px" } = props;

    // ========================= Render Function =================================
    return (
        <Div medpad white flex snubbed hintShadow>
            <Div wd={imgWidth} noOverflow half rimmed>
                <Image src={image} fluid />
            </Div>
            <Div wd={`calc(100% - ${imgWidth})`}>
                <Div fluid relaxed basepad ash big bold>
                    {header}
                </Div>
                <Div fluid relaxed basepad>
                    {description}
                </Div>
            </Div>
        </Div>
    );
}
