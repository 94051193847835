import React from "react";
import _ from "lodash";
import { useGetCourseBuilderQuery } from "@api/apiV6";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div } from "@components/Generics.react";
import ReportsWrapper from "./panels/ReportWrappers.react";
import LeaderboardWrapper from "./panels/LeaderboardWrapper.react";
import { useParams } from "react-router-dom";
import BuilderHeader from "@components/BuilderHeader.react";
export default function BuilderAnalytics(props) {
    const { id } = useParams();
    const { data: course } = useGetCourseBuilderQuery(id);
    const { t } = useTranslation("common");
    const pageContent = (
        <Div basepad>
            <Grid superpad>
                <Grid.Column width={11}>
                    <BuilderHeader
                        image={course.image}
                        header={`${t(
                            "builder.course.headers.analytics.header"
                        )}: ${course.name}`}
                        description={t("builder.course.headers.analytics.help")}
                    />
                    <Div basepad vapor rounded center-align>
                        <ReportsWrapper />
                    </Div>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Div>
                        <LeaderboardWrapper />
                    </Div>
                </Grid.Column>
            </Grid>
        </Div>
    );
    return (
        <CreatorLayout
            title={`QuoDeck Creator`}
            navName={"Library"}
            pageCode={"library"}
            mainPanel={pageContent}
            rootPage={false}
        />
    );
}
