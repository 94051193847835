import React, { useState } from "react";
import { Comment, Form, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Div, Button } from "@components/Generics.react";
import { formatDateAgo } from "@utilities/helpers";

const CommentsSection = ({ comments, onAddComment }) => {
    const { t } = useTranslation("common");
    const [commentText, setCommentText] = useState("");
    const handleCommentTextChange = (event) => {
        setCommentText(event.target.value);
    };

    const handleAddComment = () => {
        onAddComment(commentText);
        setCommentText("");
    };

    const isCommentEmpty = commentText.trim() === "";

    return (
        <Comment.Group>
            <Header as="h3">{t("article.commentsHeader")}</Header>

            <Div clear gutter>
                <Form reply>
                    <Form.TextArea
                        rows={3}
                        value={commentText}
                        onChange={handleCommentTextChange}
                    />
                    <Button
                        content={t("article.commentCTA")}
                        labelPosition="left"
                        icon="edit"
                        primary
                        onClick={handleAddComment}
                        disabled={isCommentEmpty}
                    />
                </Form>
            </Div>
            {comments &&
                comments.length > 0 &&
                comments.map((comment, idx) => (
                    <CustomComment
                        key={`${comment.id}-${idx}`}
                        comment={comment}
                        style={{ clear: "both" }}
                    />
                ))}
        </Comment.Group>
    );
};

const CustomComment = ({ comment }) => {
    // Generate a hash code from the text
    const hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    // Generate a unique light pastel color code based on the hash code
    const generateColorCode = (hashCode) => {
        const saturation = "70%";
        const lightness = "80%";
        const hue = hashCode % 360;
        return `hsl(${hue}, ${saturation}, ${lightness})`;
    };

    const getInitials = (name) => {
        const names = name.split(" ");
        const initials = names.map((n) => n.charAt(0).toUpperCase());
        return initials.join("");
    };

    const author =
        comment.owner &&
        `${comment.owner.first_name} ${comment.owner.last_name}`;

    // Generate the color code based on the text
    const colorCode = generateColorCode(hashCode(author));

    return (
        <Comment style={{ clear: "both" }}>
            {comment.avatar ? (
                <Comment.Avatar src={comment.avatar} />
            ) : (
                <Div
                    float-left
                    medpad
                    large
                    spaceRight
                    style={{ backgroundColor: colorCode }}
                >
                    {getInitials(author)}
                </Div>
            )}
            <Comment.Content>
                <Comment.Author as="a">{author}</Comment.Author>
                <Comment.Metadata>
                    <div>{formatDateAgo(comment.createdAt)}</div>
                </Comment.Metadata>
                <Comment.Text>
                    <div>{comment.comment}</div>
                </Comment.Text>
            </Comment.Content>
        </Comment>
    );
};

export default CommentsSection;
