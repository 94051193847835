// Import from NPM
// -------------------------------------
import React, {
    useRef,
    useEffect,
    useState,
    createContext,
    useContext,
} from "react";
import _ from "lodash";
import { Image } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { clientAssetsPath } from "@api/apiV6";
import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import { lightOrDark, getSlideImage, gradient } from "@utilities/helpers";
import { LayoutContext } from "@layouts/MasterLayout.react";

export const SlideContext = createContext({});

export function SlideLayout(props) {
    const { title, subtitle, image, sequence, colors, children } = props;
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth;

    // ------------------------------------------
    // Error Handlers
    // ------------------------------------------
    function handleImageError(e) {
        e.target.src = `/assets/images/abstractBanners/${parseInt(
            Math.random() * 10,
            0
        )}.jpg`;
        setHeaderHt(slideHeaderRef.current?.getBoundingClientRect().height);
    }

    // ------------------------------------------
    // Common Styles
    // ------------------------------------------
    const hasHeader = title !== "" || subtitle !== "";
    const headerStyle = {
        background: gradient(colors?.primary, "to top left", true),
        color: colors?.primaryText,
    };

    // ------------------------------------------
    // Slide Layout: Portrait View
    // ------------------------------------------
    const slideRef = useRef();
    const slideHeaderRef = useRef();
    const slideHt = slideRef.current?.getBoundingClientRect().height;
    const [headerHt, setHeaderHt] = useState(
        slideHeaderRef.current?.getBoundingClientRect().height
    );
    const headerImg =
        image && !_.startsWith(image, "data:image")
            ? `${clientAssetsPath}/uploads/qdf/slides/${image.id}${image.extension}`
            : image;

    useEffect(() => {
        setHeaderHt(slideHeaderRef.current?.getBoundingClientRect().height);
    }, [sequence]);

    function portraitSlideLayout() {
        return (
            <Div
                fluid
                fullht
                autoOverflow
                left-align
                bgColor={colors?.base}
                txtColor={
                    lightOrDark(colors?.base) === "light"
                        ? "#000000"
                        : "#ffffff"
                }
            >
                <div ref={slideHeaderRef}>
                    {image && (
                        <Div
                            topRounded
                            noOverflow
                            slideDown
                            key={`slideimg-${sequence}`}
                            style={{ height: 0, paddingBottom: "50%" }}
                        >
                            <Image
                                fluid
                                src={headerImg}
                                onError={handleImageError}
                            />
                        </Div>
                    )}
                    {hasHeader && (
                        <Div
                            fluid
                            superpad
                            altText
                            huge
                            slideUp
                            key={`slideheader-${sequence}`}
                            style={headerStyle}
                        >
                            <Div gapTop altText>
                                {title}
                            </Div>
                            <Div micro fluid gapTop>
                                {subtitle}
                            </Div>
                        </Div>
                    )}
                    <Div
                        fluid
                        padded
                        zoom
                        bgColor={colors?.primaryVariation}
                        key={`slidebar-${sequence}`}
                    />
                </div>
                <Div
                    zoom
                    ht={`${slideHt - headerHt - (isPortrait ? 32 : 0)}px`}
                    key={`slidechildren-${sequence}-${headerHt}`}
                >
                    {children}
                </Div>
            </Div>
        );
    }

    // ------------------------------------------
    // Slide Layout: Landscape View
    // ------------------------------------------
    function landscapeSlideLayout() {
        if (hasHeader)
            return (
                <Div
                    fluid
                    fullht
                    bgColor={colors?.base}
                    txtColor={
                        lightOrDark(colors?.base) === "light"
                            ? "#000000"
                            : "#ffffff"
                    }
                >
                    <Div
                        fluid
                        superpad
                        slideDown
                        float-left
                        left-align
                        key={`slideheader-${sequence}`}
                        style={{
                            ...headerStyle,
                            paddingLeft: "60px",
                            paddingRight: "60px",
                        }}
                    >
                        <Div headline altText>
                            {title}
                        </Div>
                        <Div large fluid gapTop>
                            {subtitle}
                        </Div>
                    </Div>
                    <Div
                        fluid
                        padded
                        zoom
                        float-left
                        bgColor={colors?.primaryVariation}
                        key={`slidebar-${sequence}`}
                    />
                    {image && (
                        <Div
                            wd="50%"
                            float-left
                            superpad
                            slideDown
                            key={`slideimg-${sequence}`}
                        >
                            <Div rounded dropShadow>
                                <Image
                                    fluid
                                    src={headerImg}
                                    onError={handleImageError}
                                    style={{ borderRadius: "10px" }}
                                />
                            </Div>
                        </Div>
                    )}
                    <Div
                        wd={image ? "50%" : "100%"}
                        float-left
                        delayAnim
                        superpad
                        left-align
                        ht={`${slideHt - headerHt - (isPortrait ? 32 : 0)}px`}
                        key={`slidechildren-${sequence}-${headerHt}`}
                    >
                        {children}
                    </Div>
                </Div>
            );
        else
            return (
                <Div
                    fluid
                    fullht
                    bgColor={colors?.base}
                    txtColor={
                        lightOrDark(colors?.base) === "light"
                            ? "#000000"
                            : "#ffffff"
                    }
                >
                    {image && (
                        <Div
                            wd="40%"
                            float-left
                            superpad
                            slideDown
                            key={`slideimg-${sequence}`}
                        >
                            <Div rounded dropShadow>
                                <Image
                                    fluid
                                    src={headerImg}
                                    onError={handleImageError}
                                    style={{ borderRadius: "10px" }}
                                />
                            </Div>
                        </Div>
                    )}
                    <Div
                        big
                        zoom
                        fluid
                        fullht
                        delayAnim
                        key={`slidechildren-${sequence}-${headerHt}`}
                    >
                        {children}
                    </Div>
                </Div>
            );
    }
    // ========================= Render Function =================================
    return (
        <div
            ref={slideRef}
            className={`slide-container ${fullScreen && "fullScreen"}`}
        >
            <SlideContext.Provider value={{ colors }}>
                {fullScreen ? landscapeSlideLayout() : portraitSlideLayout()}
            </SlideContext.Provider>
            <div className="slide-rim"></div>
        </div>
    );
}
