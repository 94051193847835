import React from 'react'
import { Div } from "@components/Generics.react";
import { useSelector } from "react-redux";

import ReactTable from "react-table-v6";

import {
    useGetDeckLearnerPathQuery,
} from "@api/apiV6";
export default function SingalTopicAnalysis(props) {

    const { deckId, userId, courseId } = props;

    const scope = useSelector((state) =>
        state.auth.user.license.license_type === "Creator" ||
            state.auth.user.license.license_type === "Admin" ||
            state.auth.user.license.license_type === "Inductor" ||
            state.auth.user.license.license_type === "FreeInductor"
            ? "universe"
            : "team"
    );
    const {
        data: learnerData,
    } = useGetDeckLearnerPathQuery({ userId, courseId, deckId: deckId, scope });
    let fullColumns = [
        {
            Header: "Learner",
            accessor: "name",
            minWidth: 128
        },
        {
            Header: "Accessed",
            id: "accessed",
            accessor: d => d.view_attempts,
            Cell: ({ value }) =>
                value === 0 ? "No" : "Yes (" + value + " views)",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "true") {
                    return row[filter.id] !== 0;
                }
                return row[filter.id] === 0;
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            ),
            style: { textAlign: "center" }
        },
        {
            Header: "Completion",
            id: "completion",
            accessor: d => d.completion,
            Cell: ({ value }) => (value === null ? "0%" : value + "%"),
            filterMethod: (filter, row) => {
                switch (filter.value) {
                    case "zero":
                        return row[filter.id] === 0;
                    case "low":
                        return row[filter.id] < 40 && row[filter.id] > 0;
                    case "medium":
                        return row[filter.id] < 80 && row[filter.id] > 40;
                    case "high":
                        return row[filter.id] < 100 && row[filter.id] > 80;
                    case "full":
                        return row[filter.id] === 100;
                    case "all":
                    default:
                        return true;
                }
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="zero">0%</option>
                    <option value="low">0-40%</option>
                    <option value="medium">40-80%</option>
                    <option value="high">80-99%</option>
                    <option value="full">100%</option>
                </select>
            ),
            style: { textAlign: "center" }
        },
        {
            Header: "Points",
            id: "points",
            accessor: (d) => d.points,
            Cell: ({ value }) => (value === null ? "-" : value + ""),
            filterMethod: (filter, row) => {
                switch (filter.value) {
                    case "zero":
                        return row[filter.id] === 0;
                    case "low":
                        return row[filter.id] < 40 && row[filter.id] > 0;
                    case "medium":
                        return row[filter.id] < 80 && row[filter.id] > 40;
                    case "high":
                        return row[filter.id] < 100 && row[filter.id] > 80;
                    case "full":
                        return row[filter.id] === 100;
                    case "all":
                    default:
                        return true;
                }
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="zero">0%</option>
                    <option value="low">0-40%</option>
                    <option value="medium">40-80%</option>
                    <option value="high">80-99%</option>
                    <option value="full">100%</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "Time Spent",
            id: "time_spent",
            accessor: d => d.time_spent,
            Cell: ({ value }) =>
                value === null
                    ? "0 secs"
                    : value < 120
                        ? value + " secs"
                        : Math.round(value / 60, 0) + " mins",
            filterMethod: (filter, row) => row[filter.id] >= filter.value,
            style: { textAlign: "center" }
        },

    ];
    return (
        <Div>
            <ReactTable
                data={learnerData}
                filterable
                defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                        ? String(row[id])
                            .toLowerCase()
                            .includes(filter.value.toLowerCase())
                        : true;
                }}
                columns={fullColumns}
                pageSizeOptions={[5, 10]}
                defaultPageSize={10}
                className="-striped -highlight"
            />
        </Div>
    )
}
