const GameSchema = [
    {
        name: "Movie Spin",
        folder: "movie_spin",
        restricted: true,
        premium: false,
        description:
            "A Slot Machine game where you answer questions to win spins and earn money. ",
        instructions: [
            {
                header: "Spin it",
                image: "movie_spin1.jpg",
                text: "Click or Tap on handle to collect as many coins as you can, before your turns run out.",
            },
            {
                header: "Watch the Timer",
                image: "movie_spin2.jpg",
                text: "You will get a question every time you land on a Question Mark Icon of the Display. Answer the questions carefully. This will top up your spins and earn more coins.",
            },
            {
                header: "Watch the Timer",
                image: "movie_spin3.jpg",
                text: "You will get a question every time you land on a Question Mark Icon of the Display. Answer the questions carefully. This will top up your spins and earn more coins.",
            },
            {
                header: "Levelling Up",
                image: "movie_spin4.jpg",
                text: "As you collect points, you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Jackpot Bus",
        folder: "jackpot_bus",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "jackpot_bus1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "jackpot_bus2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Special icons",
                image: "jackpot_bus3.jpg",
                text: "The special icons gives you some jackpot, double or extra coins, etc.",
            },
            {
                header: "Levelling Up",
                image: "jackpot_bus4.jpg",
                text: "As you collect points you level up and the character will move forward. Help each character to complete the rope path. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Hotel Jackpot",
        folder: "hotel_jackpot",
        restricted: true,
        premium: false,
        description:
            "Shoot down casino to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "hotel_jackpot1.jpg",
                text: "Collect stars by shooting down casino on the shelves. Different casino give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "hotel_jackpot2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time sweet to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "hotel_jackpot3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Tight Rope ICICI",
        folder: "tight_rope_icici",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "tight_rope_icici1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "tight_rope_icici2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "tight_rope_icici3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "tight_rope_icici4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Haunted Castle",
        folder: "haunted_castle",
        restricted: true,
        premium: false,
        description:
            "Capture ghosts to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "haunted_castle1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "haunted_castle2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "haunted_castle3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Cross The Street",
        folder: "cross_the_street",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "cross_the_street1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "cross_the_street2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "cross_the_street3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "cross_the_street4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Gear Up",
        folder: "gear_up",
        restricted: true,
        premium: false,
        description:
            "Tap on gear ups to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "gear_up1.jpg",
                text: "Collect stars by tapping on gear ups on the shelves. Different gear ups give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "gear_up2.jpg",
                text: "When the timer reaches zero, your game is over. Tap the Helmet to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "gear_up3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Sweet Surprise",
        folder: "sweet_surprise",
        restricted: true,
        premium: false,
        description:
            "Shoot down sweets to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "sweet_surprise1.jpg",
                text: "Collect stars by shooting down sweets on the shelves. Different sweets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "sweet_surprise2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time sweet to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "sweet_surprise3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Yeti Busters",
        folder: "yeti_busters",
        restricted: true,
        premium: false,
        description:
            "Collect yetis to get points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Yetis",
                image: "yeti_busters1.jpg",
                text: "Collect yetis by tapping on them, before you runs out of time. Your time goes down faster if more yetis are visible",
            },
            {
                header: "Watch the Timer",
                image: "yeti_busters2.jpg",
                text: "When your time reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "yeti_busters3.jpg",
                text: "As you collect yetis, you will level up. With each level you go up, the time drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Cruise Ship",
        folder: "cruise_ship",
        restricted: true,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "cruise_ship1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "cruise_ship2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the right lane next to a fuel station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "cruise_ship3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Quiz Spin",
        folder: "carnival_slots",
        restricted: true,
        premium: false,
        description:
            "A Slot Machine game where you answer questions to win spins and earn money. ",
        instructions: [],
    },
    {
        name: "Product Launch Roulette",
        folder: "product_launch_roulette",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "product_launch_roulette1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "product_launch_roulette2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "product_launch_roulette3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "product_launch_roulette4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Strategy Popper",
        folder: "strategy_popper",
        restricted: true,
        premium: false,
        description:
            "Brust those fireworks to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "stratergy_popper1.jpg",
                text: "Collect stars by bursting the rockets that are flying up. Different rockets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "stratergy_popper2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time Lantern to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "stratergy_popper3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "The Berkshire Buffet",
        folder: "berkshire_buffet",
        restricted: true,
        premium: false,
        description:
            "Run through the casino, collect that points and answer questions to get a boost.",
        instructions: [
            {
                header: "Click to jump Upwards and move forward",
                image: "the_berkshire_buffet1.jpg",
                text: "Click or tap to jump and collect the points. Crash into a obstacle and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "the_berkshire_buffet2.jpg",
                text: "Jump and collect a tip jar to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "the_berkshire_buffet3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Balloon Burst",
        folder: "balloon_burst",
        restricted: false,
        premium: false,
        description:
            "Pop those balloons to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "balloon_burst1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "balloon_burst2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "balloon_burst3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Digital Marketing Slot",
        folder: "digital_marketing_slot",
        restricted: true,
        premium: false,
        description:
            "A Slot Machine game where you answer questions to win spins and earn money. ",
        instructions: [
            {
                header: "Spin it",
                image: "digital_marketing_slot1.jpg",
                text: "Click or Tap on handle to collect as many coins as you can, before your turns run out.",
            },
            {
                header: "Watch the Timer",
                image: "digital_marketing_slot2.jpg",
                text: "You will get a question every time you land on a Question Mark Icon of the Display. Answer the questions carefully. This will top up your spins and earn more coins.",
            },
            {
                header: "Levelling Up",
                image: "digital_marketing_slot3.jpg",
                text: "As you collect points, you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Supply Run",
        folder: "supply_run",
        restricted: true,
        premium: false,
        description:
            "Drive down the casino, delivery food and avoid traffic and answer questions to top up your time regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "supply_run1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a bus and it is game over!",
            },
            {
                header: "Watch the time",
                image: "supply_run2.jpg",
                text: "When the time reaches zero, your game is over. Stop in the right lane next to a bus stop to get a question. Answer it correctly to top up your time.",
            },
            {
                header: "Levelling Up",
                image: "supply_run3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the casino road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Bottle Breaker",
        folder: "bottle_breaker",
        restricted: false,
        premium: false,
        description:
            "Shoot down bottles to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "bottle_breaker1.jpg",
                text: "Collect stars by shooting down bottles on the shelves. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "bottle_breaker2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time bottle to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "bottle_breaker3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "CorpoLadder",
        folder: "corpoladder",
        restricted: false,
        premium: false,
        description:
            "Climb the corporate ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "corpoladder1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "corpoladder2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "corpoladder3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "FieldIt",
        folder: "fieldit",
        restricted: true,
        premium: false,
        description:
            "A Cricket Game where you answer questions to take those wickets and win match after match.",
        instructions: [],
    },
    {
        name: "Jet Setter",
        folder: "jet_setter",
        restricted: true,
        premium: false,
        description:
            "Fly through space, dodge asteroids, collect money and powerups and answer questions to gain life.",
        instructions: [],
    },
    {
        name: "Fruit Graffiti",
        folder: "fruit_graffiti",
        restricted: true,
        premium: false,
        description:
            "Slice those fruits, avoid the bombs and if you can't, answer questions to save your lives.",
        instructions: [],
    },
    {
        name: "Taxi Driver",
        folder: "taxi_driver",
        restricted: false,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "taxi_driver1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "taxi_driver2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "taxi_driver3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "StarFall",
        folder: "starfall",
        restricted: false,
        premium: false,
        description:
            "Connect the stars to destroy them, and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Connect the Stars",
                image: "starfall1.jpg",
                text: "Connect the stars to destroy them. The longer the chain, the more points you get.",
            },
            {
                header: "Watch the Timer",
                image: "starfall2.jpg",
                text: "When the timer reaches zero, your game is over. Connect the question marks to get a question. Answer it correctly to get more time. The longer the chain, the more time you get.",
            },
            {
                header: "Levelling Up",
                image: "starfall3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the stars fall a little slower and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Freedom Rocks",
        folder: "freedom_rocks",
        restricted: true,
        premium: false,
        description:
            "Connect the rocks to build chains, and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Connect the Rocks",
                image: "freedom_rocks1.jpg",
                text: "Connect similar symbols to build chains. The longer the chain, the more points you get.",
            },
            {
                header: "Watch the Timer",
                image: "freedom_rocks2.jpg",
                text: "When the timer reaches zero, your game is over. Connect the question marks to get a question. Answer it correctly to get more time. The longer the chain, the more time you get.",
            },
            {
                header: "Levelling Up",
                image: "freedom_rocks3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the rocks fall a little slower and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "City Energy Saver",
        folder: "digital_marketing",
        restricted: true,
        premium: false,
        description:
            "Capture ghosts to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "ghost_dusters1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "ghost_dusters2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "ghost_dusters3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Ghost Dusters",
        folder: "ghost_dusters",
        restricted: true,
        premium: false,
        description:
            "Capture ghosts to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "ghost_dusters1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "ghost_dusters2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "ghost_dusters3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Fireworks",
        folder: "fireworks",
        restricted: true,
        premium: false,
        description:
            "Burst those fireworks to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "fireworks1.jpg",
                text: "Collect stars by bursting the rockets that are flying up. Different rockets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "fireworks2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time Lantern to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "fireworks3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Energy Saver",
        folder: "energy_saver",
        restricted: true,
        premium: false,
        description:
            "Save Energy by tapping on windows who's light in on and answer questions to gain more time to do it in. ",
        instructions: [
            {
                header: "Save Energy",
                image: "energy_saver1.jpg",
                text: "Turn off the lights by tapping on them to save light energy, before your battery runs out. Your battery goes down faster if  more light energy are visible.",
            },
            {
                header: "Watch the Battery",
                image: "energy_saver2.jpg",
                text: "When your battery reaches zero, you game is over. Click the question icon to get quiz. Answer it correctly and your battery gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "energy_saver3.jpg",
                text: "As you save light energy,  you will level up. With each level you go up, the battery drops a little faster and the game gets a little tougher.",
            },
        ],
    },
];

export { GameSchema };
