import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as DOMPurify from "dompurify";
import { trackQuestion } from "@reducers/trackerSlice";

import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import { Divider, Rating } from "semantic-ui-react";

export default function RatingReader(props) {
    const { deck, recordAttempt } = props;
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function trackInteraction(rating, index) {
        dispatch(
            trackQuestion({
                deckId: deck.id,
                slideId: index + 1,
                userId: userId,
                response: rating,
            })
        );
    }
    // ========================= Render Function =================================
    return (
        <Div fullht centered compact noOverflow relative>
            <Div
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(deck.content?.instructions),
                }}
            />
            <Divider />
            {_.map(deck.content.questions, (question, idx) => {
                return (
                    <Div
                        padded
                        vapor
                        flex
                        spaceBetween
                        snug
                        key={`rating-question-${idx}`}
                    >
                        <Div wd={`calc(100% - ${deck.content.scale * 19}px)`}>
                            {question.questions}
                        </Div>
                        <Div>
                            <Rating
                                icon="star"
                                maxRating={deck.content.scale}
                                onRate={(e, { rating }) =>
                                    trackInteraction(rating, idx)
                                }
                            />
                        </Div>
                    </Div>
                );
            })}
        </Div>
    );
}
