export const Tutorials = [
    {
        name: "Understand Courseware",
        meta: "Video Tutorial",
        description: "Let us explain how you can use courses on the platform",
        image: "https://picsum.photos/200/100",
        video: "youtube.com",
    },
    {
        name: "Leverage the Learning Center",
        meta: "Video Tutorial",
        description:
            "The Learning Center is your ultimate on-demand learning destination",
        image: "https://picsum.photos/210/105",
        video: "youtube.com",
    },
    {
        name: "The Power of Social Learning",
        meta: "Video Tutorial",
        description: "Peer to peer learning can be leveraged in the Community",
        image: "https://picsum.photos/220/110",
        video: "youtube.com",
    },
    {
        name: "Reports and Analytics",
        meta: "Video Tutorial",
        description:
            "Experience the power of big data analytics to drive adoption ",
        image: "https://picsum.photos/230/115",
        video: "youtube.com",
    },
];
