// Import from NPM
// -------------------------------------
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, Label, Icon as XIcon } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFeatures } from "flagged";

// Import from Config
// -------------------------------------
import {
    apiV6,
    useSendTrackerMutation,
    useGetSessionsQuery,
    useGetUnreadCountQuery,
    useSendUserSelfUpdateMutation,
} from "@api/apiV6";

import { trackClear } from "@reducers/trackerSlice";
import { clearAttempt } from "@reducers/attemptSlice";

import { darkStyle, text_truncate } from "@utilities/helpers";
import { Div, Icon } from "@components/Generics.react";
import "@styles/navbars.scss";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import UserAvatar from "./UserAvatar.react";
import SearchField from "./SearchField.react";
import Notifications from "./Notifications.react";

export default function Navbar(props) {
    const { logo, name, rootPage = false, isolated = false, closeDeck } = props;
    const user = useSelector((state) => state.auth.user);
    const tracker = useSelector((state) => state.tracker);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const features = useFeatures();
    const [updateProfile] = useSendUserSelfUpdateMutation();
    const { t } = useTranslation("common");

    const stages = settings.gamification.stages;
    const username = `${user?.first_name} ${user?.last_name}`;
    const isPortrait = window.innerWidth < window.innerHeight;
    const userStage = user ? settings.home[user?.stage || stages.length] : 1;

    const [notifications, setNotifications] = useState(false);
    const { data: unread } = useGetUnreadCountQuery(user?._id);
    //---------------------------------------------------------------------------
    // Sync Data with server when the tracker is set to pushable or when called manually. Also sync sessions.
    // Please Note: This makes the Navbar a control element. It must always be included in all pages.
    //---------------------------------------------------------------------------
    useGetSessionsQuery(user?._id);
    const [sendTracker] = useSendTrackerMutation();

    useEffect(() => {
        (async () => {
            if (tracker.pushable) await syncData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracker.pushable]);

    async function syncData() {
        try {
            await sendTracker({ trackers: tracker }).unwrap();
            await dispatch(trackClear());
            await dispatch(clearAttempt());
        } catch (err) {
            console.error(`Failed to send trackers to server. Reason: ${err}`);
        }
    }
    async function resyncData() {
        try {
            await syncData();
            await dispatch(apiV6.util.resetApiState());
            navigate("/");
            toast("Progress updated to server and content refreshed!");
        } catch (err) {
            console.error(`Failed to send trackers to server. Reason: ${err}`);
        }
    }
    async function handleUpdateLanguage(lang) {
        try {
            await updateProfile({
                id: user?._id,
                lang: lang,
            });
        } catch (error) {
            console.log(error);
        }
    }
    // ========================= Render Function =================================
    return (
        <Div
            fluid
            className="qd-nav"
            style={isPortrait && darkStyle(settings.colors.main)}
        >
            <Div fullht className="nav-inner" padded={!isPortrait}>
                {isPortrait && !rootPage && (
                    <Icon
                        name="chevron left"
                        large
                        float-left
                        fitted
                        padTop
                        onClick={() => (closeDeck ? closeDeck() : navigate(-1))}
                    />
                )}
                <Div
                    float-left
                    rounded={!isPortrait}
                    white
                    className="logo-block"
                    onClick={() => navigate(isolated ? "#" : "/")}
                >
                    <img
                        src={logo || "/assets/images/configurable/logo.png"}
                        alt="Brand Logo"
                        className="logo"
                    />
                </Div>
                <Div padded={!isPortrait} fullht className="pagename">
                    {isolated && !isPortrait ? (
                        <Div padTop altText uppercase>
                            {name ? name : t("appName")}
                        </Div>
                    ) : (
                        <Div inline altText>
                            {name ? name : t("appName")}
                        </Div>
                    )}
                    {!isPortrait && !rootPage && (
                        <Div
                            fit-content
                            padded
                            ivory
                            snubbed
                            small
                            clickable
                            hoverFade
                            slightShadow
                            nudgeRight
                            txtHalf
                            onClick={() =>
                                closeDeck ? closeDeck() : navigate(-1)
                            }
                        >
                            <Icon name="chevron left" />
                        </Div>
                    )}
                </Div>
                <Div float-right small={window.innerWidth < 360}>
                    {!isPortrait &&
                        !isolated &&
                        userStage &&
                        !userStage.hideSearch && (
                            <Div inlineBlock nudgeRight>
                                <SearchField />
                            </Div>
                        )}
                    <Div inlineBlock nudgeLeft fitted clickable>
                        <XIcon
                            name="sync"
                            title="Refresh platform data"
                            onClick={resyncData}
                        />
                    </Div>
                    <Div inlineBlock padTop fitted>
                        {location.pathname === "/" &&
                            settings.languages &&
                            settings.languages.length > 1 && (
                                <Dropdown
                                    inline
                                    trigger={
                                        <Div>
                                            <Icon name="language" />
                                        </Div>
                                    }
                                    className="notification-icon"
                                    direction="left"
                                    scrolling
                                    onClick={() => setNotifications(true)}
                                    onClose={() => setNotifications(false)}
                                >
                                    <Dropdown.Menu>
                                        {_.map(
                                            settings.languages,
                                            (lang, langIdx) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={`lang-${langIdx}`}
                                                        onClick={() =>
                                                            handleUpdateLanguage(
                                                                lang.code
                                                            )
                                                        }
                                                    >
                                                        <Div wd="150px">
                                                            {lang.name}{" "}
                                                            <Icon
                                                                name="chevron right"
                                                                float-right
                                                            />
                                                        </Div>
                                                    </Dropdown.Item>
                                                );
                                            }
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        {!isolated && (
                            <Dropdown
                                inline
                                trigger={
                                    <Div>
                                        <Icon name="bell" />
                                        {unread?.count > 0 && (
                                            <Label
                                                color="blue"
                                                size="mini"
                                                floating
                                            >
                                                {unread?.count}
                                            </Label>
                                        )}
                                    </Div>
                                }
                                className="notification-icon"
                                direction="left"
                                scrolling
                                onClick={() => setNotifications(true)}
                                onClose={() => setNotifications(false)}
                            >
                                <Dropdown.Menu>
                                    {notifications ? (
                                        <Notifications />
                                    ) : (
                                        <Div />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <span className="username">
                            {text_truncate(username, 18)}
                        </span>
                        <UserAvatar
                            name={username}
                            image={user?.image}
                            avatarConfig={user?.avatarConfig}
                            size={32}
                        />
                        <Dropdown
                            pointing
                            direction="left"
                            icon="ellipsis vertical"
                            className="ellipsis"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    icon={"pencil"}
                                    text={t("navbar.editProfile")}
                                    onClick={() =>
                                        navigate(
                                            `/editProfile${
                                                isolated ? "/update" : "/avatar"
                                            }`
                                        )
                                    }
                                />
                                {/* {features.ability.builder.visible &&
                                    !isPortrait && (
                                        <Dropdown.Item
                                            icon={"object group"}
                                            text={t("navbar.creatorPanel")}
                                            onClick={() => navigate(`/builder`)}
                                        />
                                    )}
                                {features.ability.adminPanel.visible &&
                                    !isPortrait && (
                                        <Dropdown.Item
                                            icon={"dashboard"}
                                            text={t("navbar.adminPanel")}
                                            onClick={() => navigate(`/builder`)}
                                        />
                                    )} */}
                                {/* {location.pathname === "/" && (
                                    <Dropdown.Item
                                        icon={"sync"}
                                        text={t("navbar.syncData")}
                                        onClick={resyncData}
                                    />
                                )} */}
                                {isPortrait &&
                                    settings.functionality.helpdeskActive && (
                                        <Dropdown.Item
                                            icon={"headphones"}
                                            text={t("navbar.needHelp")}
                                            onClick={() =>
                                                navigate("/app/helpdesk")
                                            }
                                        />
                                    )}
                                {!settings.functionality.noLogout && (
                                    <Dropdown.Item
                                        icon={"log out"}
                                        text={t("navbar.logout")}
                                        onClick={() => {
                                            const exitRoute =
                                                settings.auth.routes.logout;
                                            if (exitRoute.includes("http"))
                                                window.location.href =
                                                    exitRoute;
                                            else navigate(exitRoute);
                                        }}
                                    />
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
