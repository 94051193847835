import React, { useState } from "react";
import { TextArea } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, Button } from "@components/Generics.react";

export default function OpenAnswer(props) {
    const { data, slideSeq, deckId, pageNumber, setPageNumber } =
        props;
    const [ansval, setAnsval] = useState("");
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    function handleChange(e) {
        setAnsval(e.target.value);
    }

    function trackInteraction() {
        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: slideSeq,
                userId: userId,
                response: ansval,
            })
        );
        setPageNumber(pageNumber + 1);
    }

    // ========================= Render Function =================================
    return (
        <Div fluid fullht zoom superpad relative>
            <Div big gutter>
                {data.question}
            </Div>
            <Div small italics>
                {t("deck.qdf.openAnswer")}
            </Div>
            <Div padTop>
                <TextArea
                    rows={5}
                    value={ansval}
                    onChange={handleChange}
                    style={{ width: "100%", padding: "15px" }}
                />
                <Button
                    content={"Submit"}
                    fluid
                    primary
                    onClick={trackInteraction}
                />
            </Div>
        </Div>
    );
}
