import React, { useState, useEffect } from "react";
import _ from "lodash";

import { TemplateList } from "@schemas/templateSchema";
import { SlideLayout } from "@layouts/SlideLayout.react";
import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import DeckToolbar from "@components/DeckToolbar.react";

export default function AssessmentReader(props) {
    const { deck: rawDeck, recordAttempt, isPreview, slideNum } = props;
    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Assessment Setup
    //---------------------------------------------------------------------------
    const [deck, setDeck] = useState(rawDeck);

    useEffect(() => {
        if (
            rawDeck &&
            parseInt(rawDeck.qcount, 0) !== rawDeck.content?.length
        ) {
            if (isPreview) {
                setDeck(rawDeck);
            } else {
                let newDeck = _.cloneDeep(rawDeck);
                const content = _.sampleSize(newDeck.content, newDeck.qcount);
                _.map(content, (slide, index) => {
                    slide["masterSeq"] = slide["slideSeq"];
                    slide["slideSeq"] = index;
                });
                newDeck.content = content;
                setDeck(newDeck);
            }
        }
    }, [rawDeck, isPreview]);

    //---------------------------------------------------------------------------
    // Slide Navigation
    //---------------------------------------------------------------------------
    const [pageNumber, setPageNumber] = useState(0);
    const [slideData, setSlideData] = useState(null);

    useEffect(() => {
        const currentSlide = _.find(deck.content, {
            slideSeq: pageNumber,
        });
        setSlideData(currentSlide);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, deck]);

    //---------------------------------------------------------------------------
    // Creator Slide Navigation
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (slideNum != null && pageNumber !== slideNum)
            setPageNumber(slideNum);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideNum, pageNumber]);

    //---------------------------------------------------------------------------
    // Slide Content
    //---------------------------------------------------------------------------
    const templateSchema = TemplateList[slideData?.template];
    const SlideComponent = templateSchema?.template;

    const title = templateSchema?.showHeader ? slideData.data.title : "";
    const subtitle = templateSchema?.showHeader ? slideData.data.subtitle : "";

    //---------------------------------------------------------------------------
    // Question Data Tracking
    //---------------------------------------------------------------------------
    const [questionsAttempted, setQuestionsAttempted] = useState(0);
    const [questionsCorrect, setQuestionsCorrect] = useState(0);
    function trackData(response) {
        setQuestionsAttempted(questionsAttempted + 1);
        if (response.correct) setQuestionsCorrect(questionsCorrect + 1);
    }

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (questionsAttempted > 0) {
            let score = parseInt((questionsCorrect * 100) / deck.qcount, 0);
            recordAttempt({
                viewedPercentage: Math.min(
                    100,
                    parseInt((questionsAttempted * 100) / deck.qcount, 0)
                ),
                completion: score >= deck.passingPercentage,
                pointsDelta:
                    score && deck.points
                        ? Math.max(
                              (score * deck.points) / 100 -
                                  (deck.currentState.points || 0),
                              0
                          )
                        : 0,
                score: score,
                deckIsFinalExam: deck.isFinal,
                showEnding: questionsAttempted >= deck.qcount,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsAttempted]);

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            ht={
                isPreview
                    ? "100%"
                    : `calc(100vh - ${
                          isPortrait
                              ? /iPad|iPhone|iPod/.test(navigator.userAgent)
                                  ? 195
                                  : 155
                              : 125
                      }px)`
            }
            centered
            center-align
            noOverflow={isPreview}
            className="fullscreen-target"
        >
            <SlideLayout
                title={title}
                subtitle={subtitle}
                image={slideData?.data?.image}
                sequence={slideData?.slideSeq}
                colors={deck?.colors}
            >
                {SlideComponent && (
                    <SlideComponent
                        {...slideData}
                        deckId={deck._id}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        trackData={trackData}
                    />
                )}
            </SlideLayout>
            {!isPreview && (
                <DeckToolbar
                    {...{
                        pageNumber,
                        setPageNumber,
                        numPages: deck.qcount,
                        navConfig: { next: false, prev: false },
                    }}
                />
            )}
        </Div>
    );
}
