import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import { Dropdown } from "semantic-ui-react";
import AssessmentCompletionSubPanel from "./AssessmentCompletionSubPanel.react";
import AssessmentLearnersSubPanel from "./AssessmentLearnersSubPanel.react"
import QuizeQuestionResponses from "./QuizeQuestionResponses.react";
import {
  useGetCourseBuilderQuery,
} from "@api/apiV6";
import {
  useGetDeckPathQuery,
  useGetDeckLearnerPathQuery,
} from "@api/apiV6";
const isPortrait = window.innerHeight > window.innerWidth;

export default function AppReports(props) {
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [activeQuize, setActiveQuize] = useState(null);
  const [aggregation, setAggregation] = useState({
    enrolled_team: 0,
    completed_by: 0,
    total_views: 0,
    not_started_by: 0,
    time_spent: 0,
    average_score: 0,
    average_completion: 0,
  });
  const courseId = id;
  const deckId = activeQuize;
  const userId = user._id;

  const { data: courses } =
    useGetCourseBuilderQuery(id);


  const scope = useSelector((state) =>
    state.auth.user.license.license_type === "Creator" ||
      state.auth.user.license.license_type === "Admin"
      ? "universe"
      : "team"
  );

  const {
    data: learnerData,
  } = useGetDeckLearnerPathQuery({ userId, courseId, deckId: deckId, scope });

  useEffect(() => {
    if (learnerData) {
      setAggregation(aggregateData(learnerData));
    }
  }, [learnerData]);



  const deckOptione = _.flatten(
    _.map(courses.locations, (location) => {
      return _.map(
        _.filter(location.contentList, (deck) => {
          return deck.readerType === "assessment";
        }),
        (deck) => {
          return {
            icon: "treatment",
            key: deck._id,
            text: location.name + " :: " + deck.name,
            value: deck._id,
            readertype: deck.readerType,
          };
        }
      );
    })
  );

  const aggregateData = (learnerData) => {
    return {
      enrolled_team: learnerData.length,
      accessed_by: _.filter(learnerData, (learner) => {
        return parseInt(learner.view_attempts, 0) > 0;
      }).length,
      completed_by: _.filter(learnerData, (learner) => {
        return learner.complete;
      }).length,
      total_views: _.sumBy(learnerData, "view_attempts"),
      not_started_by: _.filter(learnerData, (learner) => {
        return learner.completion === 0;
      }).length,
      time_spent: _.sumBy(learnerData, "time_spent"),
      average_score: parseInt(_.sumBy(learnerData, "score") / learnerData.length, 0),
      average_completion:
        learnerData.length > 0
          ? parseInt(_.sumBy(learnerData, "completion") / learnerData.length, 0)
          : 0,
    };
  };

  const panes = [
    {
      menuItem: "Compltion Status",
      render: () => (
        <Tab.Pane>
          <AssessmentCompletionSubPanel
            aggregation={aggregation}
            activeTopic={activeQuize}
            results={learnerData}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Learner Responses",
      render: () => <Tab.Pane>
        <AssessmentLearnersSubPanel
          aggregation={aggregation}
          activeTopic={activeQuize}
          results={learnerData}
        />
      </Tab.Pane>,
    },
    {
      menuItem: "Question Responses",
      render: () => <Tab.Pane><QuizeQuestionResponses
        aggregation={aggregation}
        activeTopic={activeQuize}
        results={learnerData}
      /></Tab.Pane>,
    },
  ];

  return (
    <Div>
      <Div medpad vapor>
        <Div gapTop big uppercase gapBottom bold>
          Select a Course:
        </Div>
        <Dropdown
          placeholder="Select a course to get progress details"
          search
          fluid
          selection
          upward={isPortrait}
          options={deckOptione}
          onChange={(e, { value }) => setActiveQuize(value)}
        />
        <br />
      </Div>
      <Tab panes={panes} />
    </Div>
  );
}
