// Import from NPM
// -------------------------------------
import React, { useState, createContext } from "react";
import { Helmet } from "react-helmet";

// Import from Config
// -------------------------------------
import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import gcss from "@styles/variables.module.scss";
import { parsePx } from "@utilities/helpers";

import { settings } from "@config/settings/app.settings";
import Sidebar from "@components/Sidebar.react";
import Topbar from "@components/Topbar.react";

export const LayoutContext = createContext({});

export default function CreatorLayout(props) {
    const { title, mainPanel, navName, pageCode, rootPage, editMode } = props;
    const [fullScreen, setFullScreen] = useState(false);
    const isPortrait = window.innerHeight > window.innerWidth;

    // ------------------------------------------
    // Page Design
    // ------------------------------------------
    const pgHt = Math.min(
        window.innerHeight - parsePx(gcss.navbarHt),
        parsePx(gcss.maxHt) - parsePx(gcss.navbarHt)
    );

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            className={`master-container creator`}
            autoOverflowY={isPortrait}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="theme-color" content={settings.colors.main} />
            </Helmet>
            <LayoutContext.Provider
                value={{ fullScreen, setFullScreen, height: pgHt }}
            >
                <Div fluid pageht slightShadow noOverflow>
                    <Sidebar {...{ navName, pageCode, rootPage, editMode }} />
                    <Div fullht fluid float-left vapor>
                        <Topbar />
                        <Div
                            fluid
                            fullht
                            wd="calc(100% - 60px)"
                            style={{ paddingTop: "60px", marginLeft: "60px" }}
                        >
                            {mainPanel}
                        </Div>
                    </Div>
                </Div>
            </LayoutContext.Provider>
        </Div>
    );
}
