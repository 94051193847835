import React, { useState } from "react";
import { Div, Button } from "@components/Generics.react";
import {
    useSendSocialCommentsMutation,
    useSendSocialLikesMutation,
    useSendSocialDeleteMutation,
} from "@api/apiV6";
import PostCard from "@components/PostCard.react";
import { Confirm } from "semantic-ui-react";

export default function PostHandler(props) {
    const { post } = props;
    const [sendSocialComments] = useSendSocialCommentsMutation();
    const [sendSocialLikes] = useSendSocialLikesMutation();
    const [deletePost] = useSendSocialDeleteMutation();

    const [thisPost, setThisPost] = useState(post);
    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const handleLike = () => {
        sendSocialLikes({ id: thisPost._id });
    };

    const handleCommentToggle = () => {
        setThisPost({
            ...thisPost,
            showCommentInput: !thisPost.showCommentInput,
        });
    };

    const addCommentToPost = (thisPostId, comment) => {
        sendSocialComments({ id: thisPost._id, comment });
    };

    function deleteIt() {
        deletePost(post._id);
        setDeleteConfirm(false);
    }

    return (
        <Div flex top-aligned>
            <Div wd="calc(100% - 60px)">
                <PostCard
                    key={`Post-${post._id}`}
                    post={thisPost}
                    onLike={handleLike}
                    onCommentToggle={handleCommentToggle}
                    onAddComment={addCommentToPost}
                />
            </Div>
            <Div wd="60px" flex flexStart gapped>
                <Button
                    danger={+true}
                    icon="trash"
                    onClick={() => setDeleteConfirm(true)}
                />
            </Div>
            <Confirm
                open={deleteConfirm}
                onCancel={() => setDeleteConfirm(false)}
                onConfirm={deleteIt}
            />
        </Div>
    );
}
