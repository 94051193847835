import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUserLoggedIn: false,
    token: null,
    user: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isUserLoggedIn = true;
            state.user = action.payload.user;
            state.token = action.payload.token;
            return state;
        },
        logout: (state) => {
            state.isUserLoggedIn = false;
            state.user = null;
            state.token = null;
            return state;
        },
        updateUser: (state, action) => {
            state.user.first_name = action.payload.user.first_name;
            state.user.last_name = action.payload.user.last_name;
            state.user.lang = action.payload.user.lang;
            state.user.avatarConfig = action.payload.user.avatarConfig;
            state.user.image = action.payload.user.image;
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { login, logout, updateUser } = authSlice.actions;

export default authSlice.reducer;
