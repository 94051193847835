// import ArrangedCards from "mobile/course/wrappers/formals/ArrangedCards.react";
// import FeedCards from "mobile/course/wrappers/formals/FeedCards.react";
// import SimpleListing from "mobile/course/wrappers/formals/SimpleListing.react";
// import StackedImages from "mobile/course/wrappers/formals/StackedImages.react";
// import TinderCards from "mobile/course/wrappers/formals/TinderCards.react";

export const WrapperList = {
    none: {},
    medieval: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "surf",
                top: "82",
                left: "79",
                width: "8",
                opacity: 0.5,
            },
            {
                name: "surf",
                top: "90",
                left: "27",
                width: "6",
                rotate: 45,
            },
            {
                name: "boat",
                top: "79",
                left: "0",
                width: "16",
            },
            {
                name: "smoke",
                top: "-18",
                left: "72.5",
                width: "17",
                opacity: 0.3,
            },
            {
                name: "smoke",
                top: "58",
                left: "55",
                width: "8",
                opacity: 0.5,
            },
            {
                name: "smoke",
                top: "-20",
                left: "11.5",
                width: "17",
                opacity: 0.5,
            },
            {
                name: "horse",
                top: "43",
                left: "82",
                width: "6",
            },
            {
                name: "ripples",
                top: "86",
                left: "83",
                width: "20",
                opacity: 0.3,
                rotate: "-60",
            },
        ],
        positions: [
            { sequence: 0, top: 86, left: 8 },
            { sequence: 1, top: 70, left: 33 },
            { sequence: 2, top: 83, left: 40 },
            { sequence: 3, top: 77, left: 69 },
            { sequence: 4, top: 51, left: 92 },
            { sequence: 5, top: 58, left: 62 },
            { sequence: 6, top: 31, left: 79 },
            { sequence: 7, top: 9, left: 52 },
            { sequence: 8, top: 30, left: 19 },
            { sequence: 9, top: 55, left: 44 },
            { sequence: 10, top: 48, left: 33 },
            { sequence: 11, top: 20, left: 43 },
        ],
    },
    cityscape: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "85",
                top: "90",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "58",
                top: "79",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "7",
                top: "87",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "16",
                top: "62",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "45",
                top: "64",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "70",
                top: "57",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "36",
                top: "36",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "88",
                top: "21",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "4",
                top: "34",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "60",
                top: "33",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "60",
                top: "12",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "34",
                top: "15",
                width: "9.6",
                height: "0",
            },
        ],
    },
    carnival: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            { sequence: 0, top: 60, left: 8 },
            { sequence: 1, top: 70, left: 25 },
            { sequence: 2, top: 68, left: 40 },
            { sequence: 3, top: 71, left: 70 },
            { sequence: 4, top: 56, left: 80 },
            { sequence: 5, top: 41, left: 81 },
            { sequence: 6, top: 18, left: 55 },
            { sequence: 7, top: 14, left: 44 },
            { sequence: 8, top: 36, left: 53 },
            { sequence: 9, top: 43, left: 33 },
            { sequence: 10, top: 41, left: 11 },
            { sequence: 11, top: 19, left: 15 },
        ],
    },
    supermarket: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            { sequence: 0, top: 41, left: 8 },
            { sequence: 1, top: 30, left: 22 },
            { sequence: 2, top: 37.94921875, left: 34.583333333333336 },
            { sequence: 3, top: 48.44921875, left: 48.66666666666667 },
            { sequence: 4, top: 54, left: 59 },
            { sequence: 5, top: 45, left: 65 },
            { sequence: 6, top: 33, left: 67 },
            { sequence: 7, top: 32, left: 49 },
            { sequence: 8, top: 17, left: 46 },
            { sequence: 9, top: 17, left: 62 },
            { sequence: 10, top: 21, left: 74 },
            { sequence: 11, top: 26, left: 86 },
        ],
    },
    atlantis: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "A",
                top: "49",
                left: "30",
                width: "23",
            },
            {
                name: "B",
                top: "76",
                left: "52",
                width: "13",
            },
            {
                name: "C",
                top: "40",
                left: "73",
                width: "16",
            },
            {
                name: "D",
                top: "22",
                left: "86",
                width: "14",
            },
            {
                name: "E",
                top: "76",
                left: "18",
                width: "9",
            },
            {
                name: "F",
                top: "30",
                left: "61",
                width: "9",
            },
            {
                name: "Water",
                top: "0",
                left: "0",
                width: "100",
            },
        ],
        positions: [
            { sequence: 0, top: 73.87472221175939, left: 44.57831325301205 },
            { sequence: 1, top: 67.96866171983879, left: 73.4136546184739 },
            { sequence: 2, top: 40, left: 89 },
            { sequence: 3, top: 52, left: 63 },
            { sequence: 4, top: 52, left: 49 },
            { sequence: 5, top: 38.3178274134619, left: 51.80722891566265 },
            { sequence: 6, top: 42, left: 22 },
            { sequence: 7, top: 27.83155674413349, left: 81.20481927710843 },
            { sequence: 8, top: 17.224754228031188, left: 71.96787148594377 },
            { sequence: 9, top: 18.912200082865642, left: 55.983935742971894 },
            { sequence: 10, top: 14.452521752231723, left: 35.18072289156626 },
            { sequence: 11, top: 28.19315228445516, left: 24.819277108433734 },
        ],
    },
    mountain_adventure: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "Boat_1",
                top: "82",
                left: "9",
                width: "5",
                height: "5",
            },
            {
                name: "Boat_1",
                top: "82",
                left: "89",
                width: "5",
                height: "5",
            },
            {
                name: "Flag",
                top: "77",
                left: "53",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "41",
                left: "59",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "11",
                left: "58",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "30",
                left: "33",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "31",
                left: "72",
                width: "2",
                height: "2",
            },
            {
                name: "Flag",
                top: "25",
                left: "21",
                width: "2",
                height: "2",
            },
            {
                name: "Fog",
                top: "0",
                left: "0",
                width: "100",
                height: "100",
            },
            {
                name: "Sea",
                top: "0",
                left: "0",
                width: "100",
                height: "100",
            },
            {
                name: "gondala set 2",
                top: "59",
                left: "15",
                width: "3",
                height: "3",
            },
            {
                name: "gondala set 1",
                top: "51",
                left: "21",
                width: "3",
                height: "3",
            },
        ],
        positions: [
            { sequence: 0, top: 77.21762048192771, left: 53.73493975903615 },
            { sequence: 1, top: 60, left: 13 },
            { sequence: 2, top: 31, left: 33 },
            { sequence: 3, top: 59.50677710843374, left: 79.03614457831326 },
            { sequence: 4, top: 53, left: 67 },
            { sequence: 5, top: 50, left: 84 },
            { sequence: 6, top: 47, left: 79 },
            { sequence: 7, top: 42, left: 60 },
            { sequence: 8, top: 32, left: 73 },
            { sequence: 9, top: 27.699548192771083, left: 21.044176706827308 },
            { sequence: 10, top: 15.289909638554217, left: 57.188755020080315 },
            { sequence: 11, top: 9.494728915662651, left: 49.47791164658635 },
        ],
    },
    apartments: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "Car 1",
                top: "60",
                left: "80",
                width: "25",
            },
            {
                name: "Car 4",
                top: "63",
                left: "87",
                width: "25",
            },
            {
                name: "Car 3",
                top: "72",
                left: "-7",
                width: "20",
            },
            {
                name: "Car 2",
                top: "72",
                left: "-3",
                width: "20",
            },
            {
                name: "Water lake",
                top: "35",
                left: "43",
                width: "15",
            },
            {
                name: "Pool party",
                top: "14",
                left: "48",
                width: "16",
            },
            {
                name: "Terrace party",
                top: "2",
                left: "18",
                width: "19",
            },
            {
                name: "Terrace party",
                top: "2",
                left: "18",
                width: "19",
            },
            {
                name: "swing",
                top: "29",
                left: "39",
                width: "6",
            },
            {
                name: "Ringlight",
                top: "29",
                left: "26",
                width: "7",
            },
            {
                name: "Ringlight",
                top: "50",
                left: "45",
                width: "10",
            },
            {
                name: "Ringlight",
                top: "26",
                left: "67",
                width: "6",
            },
            {
                name: "restaurant",
                top: "6",
                left: "85",
                width: "10",
            },
        ],
        positions: [
            { sequence: 0, top: 59, left: 77 },
            { sequence: 1, top: 67, left: 30 },
            { sequence: 2, top: 46, left: 45 },
            { sequence: 3, top: 37.35357263927078, left: 49.558232931726906 },
            { sequence: 4, top: 22, left: 42 },
            { sequence: 5, top: 32, left: 64 },
            { sequence: 6, top: 11, left: 70 },
            { sequence: 7, top: 14, left: 86 },
            { sequence: 8, top: 13, left: 49 },
            { sequence: 9, top: 6, left: 36 },
            { sequence: 10, top: 12, left: 21 },
            { sequence: 11, top: 56, left: 13 },
        ],
    },
    aquarium: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "rightbottom",
                top: "68",
                left: "71",
                width: "17",
            },
            {
                name: "flask",
                top: "71",
                left: "10",
                width: "6",
            },
            {
                name: "red fish",
                top: "10",
                left: "64",
                width: "11",
                opacity: 0.8,
            },
            {
                name: "single fish",
                top: "14",
                left: "68",
                width: "10",
                opacity: 0.5,
            },
            {
                name: "yellow fish",
                top: "10",
                left: "75",
                width: "6",
                opacity: 0.7,
            },
            {
                name: "tank",
                top: "9",
                left: "42",
                width: "16",
            },
            {
                name: "bubble",
                top: "5",
                left: "38",
                width: "19",
            },
            {
                name: "tank2",
                top: "34",
                left: "42",
                width: "16",
            },
            {
                name: "bubble",
                top: "34",
                left: "42",
                width: "15",
            },
            {
                name: "single fish 2",
                top: "39",
                left: "45",
                width: "10",
            },
            {
                name: "single fish 3.1",
                top: "5",
                left: "44",
                width: "10",
            },
            {
                name: "left",
                top: "8",
                left: "24",
                width: "9",
            },
            {
                name: "ripples",
                top: "86",
                left: "83",
                width: "20",
                opacity: 0.3,
                rotate: "-60",
            },
        ],
        positions: [
            { sequence: 0, top: 84, left: 75 },
            { sequence: 1, top: 60, left: 82 },
            { sequence: 2, top: 64, left: 53 },
            { sequence: 3, top: 66, left: 13 },
            { sequence: 4, top: 56, left: 36 },
            { sequence: 5, top: 32, left: 77 },
            { sequence: 6, top: 37, left: 50 },
            { sequence: 7, top: 27, left: 61 },
            { sequence: 8, top: 15, left: 78 },
            { sequence: 9, top: 13, left: 40 },
            { sequence: 10, top: 15, left: 17 },
            { sequence: 11, top: 41, left: 10 },
        ],
    },
    asylum: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "book reading",
                top: "49",
                left: "84",
                width: "7",
            },
            {
                name: "chandelier1",
                top: "40",
                left: "46",
                width: "9",
            },
            {
                name: "hospital charac",
                top: "60",
                left: "14",
                width: "3",
            },
            {
                name: "hotel left",
                top: "22",
                left: "36",
                width: "9",
                opacity: 0.8,
            },
            {
                name: "right2",
                top: "22",
                left: "56",
                width: "8",
                opacity: 0.8,
            },
            {
                name: "layer 3",
                top: "41",
                left: "62",
                width: "18",
                opacity: 0.8,
            },
            {
                name: "Men On Call",
                top: "51",
                left: "54",
                width: "2",
            },
            {
                name: "Men On sofa",
                top: "80",
                left: "22",
                width: "5",
            },
            {
                name: "old man",
                top: "63",
                left: "13",
                width: "28",
            },
            {
                name: "reception",
                top: "76",
                left: "69",
                width: "9",
            },
            {
                name: "waiter 2",
                top: "24",
                left: "64",
                width: "12",
            },
        ],
        positions: [
            { sequence: 0, top: 74, left: 50 },
            { sequence: 1, top: 71, left: 88 },
            { sequence: 2, top: 57, left: 38 },
            { sequence: 3, top: 46.51399299408641, left: 81.44578313253012 },
            { sequence: 4, top: 44, left: 54 },
            { sequence: 5, top: 42.05431466345249, left: 32.53012048192771 },
            { sequence: 6, top: 22.64868733285623, left: 80.64257028112449 },
            { sequence: 7, top: 22.889751026404006, left: 58.152610441767074 },
            { sequence: 8, top: 19.635391163508984, left: 22.971887550200805 },
            { sequence: 9, top: 11, left: 69.87951807228916 },
            { sequence: 10, top: 10.461674639346115, left: 45.46184738955824 },
            { sequence: 11, top: 11, left: 17 },
        ],
    },
    bus_station: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "board",
                top: "33",
                left: "37",
                width: "13",
            },
            {
                name: "box2",
                top: "7",
                left: "51",
                width: "7",
            },
            {
                name: "bus left",
                top: "75",
                left: "-1",
                width: "40",
            },
            {
                name: "bus right",
                top: "68",
                left: "53",
                width: "46",
            },
            {
                name: "car",
                top: "67",
                left: "25",
                width: "30",
            },
            {
                name: "talking",
                top: "47",
                left: "55",
                width: "10",
            },
            {
                name: "women walkin",
                top: "42",
                left: "6",
                width: "9",
            },
            {
                name: "women walkin",
                top: "57",
                left: "65",
                width: "10",
            },
        ],
        positions: [
            { sequence: 0, top: 33.96460843373494, left: 22.48995983935743 },
            { sequence: 1, top: 38, left: 39 },
            { sequence: 2, top: 36, left: 48 },
            { sequence: 3, top: 11.675451807228916, left: 30.522088353413658 },
            { sequence: 4, top: 25, left: 58 },
            { sequence: 5, top: 6, left: 51 },
            { sequence: 6, top: 48, left: 48 },
            { sequence: 7, top: 40, left: 68 },
            { sequence: 8, top: 36, left: 93 },
            { sequence: 9, top: 49, left: 74 },
            { sequence: 10, top: 56, left: 65 },
            { sequence: 11, top: 55, left: 88 },
        ],
    },
    club: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "84",
                left: "60",
                width: "40",
                sequence: 0,
            },
            {
                top: "83",
                left: "30",
                width: "45",
                sequence: 1,
            },
            {
                top: "69",
                left: "68",
                width: "40",
                sequence: 2,
            },
            {
                top: "69",
                left: "14",
                width: "48",
                sequence: 3,
            },
            {
                top: "58",
                left: "18",
                width: "40",
                sequence: 4,
            },
            {
                top: "37",
                left: "74",
                width: "35",
                sequence: 5,
            },
            {
                top: "21",
                left: "33",
                width: "37",
                sequence: 6,
            },
            {
                top: "31",
                right: "63",
                width: "36",
                sequence: 7,
            },
            {
                top: "14",
                left: "63",
                width: "35",
                sequence: 8,
            },
            {
                top: "45",
                left: "1",
                width: "40",
                sequence: 9,
            },
            {
                top: "86",
                right: "64",
                width: "50",
                sequence: 10,
            },
            {
                top: "15",
                left: "15",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
};

export const PickedLocations = {
    1: [0],
    2: [0, 11],
    3: [0, 5, 11],
    4: [0, 4, 8, 11],
    5: [0, 3, 6, 9, 11],
    6: [0, 2, 4, 6, 9, 11],
    7: [0, 2, 4, 6, 8, 10, 11],
    8: [0, 1, 2, 4, 6, 8, 10, 11],
    9: [0, 1, 2, 5, 6, 7, 9, 10, 11],
    10: [0, 1, 2, 4, 5, 6, 7, 9, 10, 11],
    11: [0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11],
    12: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
};
