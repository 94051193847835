// Import from NPM
// -------------------------------------
import React from "react";
import { useNavigate } from "react-router-dom";
import { useFeatures } from "flagged";
import _ from "lodash";
import { Popup } from "semantic-ui-react";
import { Div, Icon } from "@components/Generics.react";
import "@styles/components.scss";
import "@styles/icomoon.css";
import { darkStyle } from "@utilities/helpers";

import { useTranslation } from "react-i18next";
import { settings } from "@config/settings/app.settings";

export default function Sidebar(props) {
    const { navName, pageCode, rootPage, editMode } = props;
    const navigate = useNavigate();
    const features = useFeatures();
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Div
            fullht
            wd="60px"
            float-left
            white={!editMode}
            half={editMode}
            slightShadow
            className="creator-sidebar"
            fixed
            layer={3}
        >
            <Div
                white
                clickable
                className="nav-logo"
                onClick={() => navigate("/builder")}
                style={darkStyle(settings.colors.main, "to top right")}
            >
                <img
                    src={"/assets/images/configurable/logo-inverted.png"}
                    alt="Brand Logo"
                />
            </Div>
            <Div>
                {!editMode &&
                    _.map(
                        _.filter(
                            settings.sidebar,
                            (elm) =>
                                (elm !== "social" || features.social) &&
                                (elm !== "contests" || features.contests)
                        ),
                        (elm, idx) => {
                            return pageCode === elm ? (
                                <Div
                                    primary
                                    className="nav-item"
                                    key={`sidebar-${idx}`}
                                >
                                    <Div basepad>
                                        <Icon
                                            name={t(
                                                `builder.sidebar.${elm}.icon`
                                            )}
                                        />
                                    </Div>
                                </Div>
                            ) : (
                                <Popup
                                    inverted
                                    key={`sidebar-${idx}`}
                                    position="right center"
                                    on="hover"
                                    trigger={
                                        <Div
                                            className="nav-item"
                                            onClick={() =>
                                                navigate(
                                                    t(
                                                        `builder.sidebar.${elm}.link`
                                                    )
                                                )
                                            }
                                        >
                                            <Div basepad>
                                                <Icon
                                                    name={t(
                                                        `builder.sidebar.${elm}.icon`
                                                    )}
                                                />
                                            </Div>
                                        </Div>
                                    }
                                >
                                    <Popup.Header>
                                        {t(`builder.sidebar.${elm}.header`)}
                                    </Popup.Header>
                                    <Popup.Content>
                                        {t(`builder.sidebar.${elm}.tooltip`)}
                                    </Popup.Content>
                                </Popup>
                            );
                        }
                    )}
            </Div>
            <Div txtWhite={editMode} className="nav-name">
                {navName}
            </Div>
            <Div
                className="nav-back"
                clickable={!rootPage}
                onClick={() => (rootPage ? null : navigate(-1))}
            >
                <Div peat basepad>
                    {!rootPage && <Icon name="chevron left" />}
                </Div>
            </Div>
        </Div>
    );
}
