// Import from NPM
// -------------------------------------
import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { LayoutContext } from "@layouts/MasterLayout.react";
import {
    AbsWrapper,
    Container,
    Div,
    Image,
    Overlay,
} from "@components/Generics.react";
import { WrapperList } from "@schemas/wrapperSchema";
import "@styles/courseLayout.scss";

// Import Components
// -------------------------------------
import Marker from "@components/Marker.react";
import FogOverlay from "@components/FogOverlay.react";
import LocationMenu from "@components/LocationMenu.react";
import { ScrollDrag } from "@components/ScrollDrag.react";
import CourseHeader from "./CourseHeader.react";

export default function VisualGame(props) {
    const {
        locData,
        setActiveLoc,
        activeLoc,
        course,
        showMenu,
        closeMenu,
        openDeck,
        hasFog = false,
        courseHeader = true,
    } = props;
    const { height } = useContext(LayoutContext);
    const [zoomLevel, setZoomLevel] = useState(90);
    const [zoomFinish, setZoomFinish] = useState(false);
    const [activeMarker, setActiveMarker] = useState(0);

    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Handle scrolling to active location
    //---------------------------------------------------------------------------
    useEffect(() => {
        let scroller = document.getElementById("scroller");
        let activeLocation = document.getElementById("location-active");
        let timer;
        if (scroller && zoomLevel < 100 && !zoomFinish && isPortrait) {
            timer = setTimeout(() => {
                if (activeLocation) {
                    scroller.scrollLeft =
                        activeLocation.offsetLeft - window.innerWidth / 2;
                    scroller.scrollTop =
                        activeLocation.offsetTop - scroller.offsetHeight / 2;
                }
                setZoomLevel(zoomLevel + 2);
            }, 10);
        } else if (!zoomFinish) {
            setZoomFinish(true);
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomLevel, zoomFinish]);

    useEffect(() => {
        let scroller = document.getElementById("scroller");
        let activeLocation = document.getElementById("location-active");
        if (activeLocation) {
            scroller.scrollLeft =
                activeLocation.offsetLeft - window.innerWidth / 2;
            scroller.scrollTop =
                activeLocation.offsetTop - scroller.offsetHeight / 2;
        }
    }, [activeMarker]);

    let zoomFactor =
        window.location === window.parent.location // If this is not in an iFrame
            ? zoomLevel / 100
            : 1;
    let gameDimensions = {
        width: isPortrait ? 1.5 * height * zoomFactor : "100%",
        height: isPortrait ? height * zoomFactor : "0",
        paddingBottom: isPortrait ? "0" : "66.67%",
    };

    //---------------------------------------------------------------------------
    // Handle content overlays
    //---------------------------------------------------------------------------
    const gifOverlay = _.map(
        WrapperList[course.wrapper]?.gifMap,
        (img, index) => {
            return (
                <img
                    key={"aGif-" + index}
                    src={"/assets/images/giflib/" + img.name + ".gif"}
                    style={{
                        position: "absolute",
                        top: img.top + "%",
                        left: img.left + "%",
                        width: img.width + "%",
                        opacity: img.opacity || 1,
                        transform:
                            img.rotate && `rotate3d(1,1,1,${img.rotate}deg)`,
                    }}
                    alt="Visual embellishment"
                />
            );
        }
    );
    let fogOverlay = [];
    const locationOverlay = locData.map((thisLoc, locIndex) => {
        if (thisLoc != null) {
            const locCoords = WrapperList[course.wrapper].positions[locIndex]; // Get the location position details from WrapperSchema
            if (thisLoc.status === "complete" || thisLoc.status === "current") {
                fogOverlay.push(locCoords); // Add location to highlight through fog
            }
            if (thisLoc.status === "current" && locIndex !== activeMarker)
                setActiveMarker(locIndex);
            return (
                <Marker
                    id={thisLoc.status === "current" ? "location-active" : ""}
                    key={"location-" + locIndex}
                    locIndex={locIndex}
                    courseColors={course.colors}
                    wrapper={course.wrapper}
                    locCoords={locCoords}
                    content={_.omit(thisLoc, "status")}
                    status={thisLoc.status}
                    clickable={
                        !course.sequential || thisLoc.status !== "incomplete"
                    }
                    sequential={course.sequential}
                    handleClick={setActiveLoc}
                />
            );
        } else {
            return <Div key={"location-" + locIndex} className="no-loc" />;
        }
    });

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padded padTop clear>
            {courseHeader && !isPortrait && <CourseHeader course={course} />}
            <Container noOverflow id="viewport">
                <ScrollDrag rootClass="scroller" rootId="scroller">
                    <AbsWrapper noOverflow rounded style={gameDimensions}>
                        <Image
                            src={
                                "/assets/images/wrappers/" +
                                course.wrapper +
                                "/play_backdrop.png"
                            }
                            className="wrapper-image"
                        />
                        <AbsWrapper>
                            {locationOverlay}
                            {gifOverlay}
                            {hasFog &&
                                fogOverlay.length > 0 &&
                                !course.currentState?.completion && (
                                    <Div className="overlay">
                                        {zoomFinish ? (
                                            <FogOverlay
                                                width={gameDimensions.width}
                                                height={gameDimensions.height}
                                                visible={fogOverlay}
                                            />
                                        ) : (
                                            <Overlay
                                                color={"#121212"}
                                                opacity={
                                                    1 -
                                                    (zoomLevel - 102) * 0.005
                                                }
                                            />
                                        )}
                                    </Div>
                                )}
                        </AbsWrapper>
                    </AbsWrapper>
                </ScrollDrag>
                {showMenu && <Div className="overlay loc-menu" />}
                <LocationMenu
                    sequential={course.sequential}
                    wrapper={course.wrapper}
                    showMenu={showMenu}
                    content={locData[activeLoc]}
                    openDeck={openDeck}
                    closeMenu={closeMenu}
                    locCoords={WrapperList[course.wrapper].positions[activeLoc]}
                />
            </Container>
        </Div>
    );
}
