import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "@styles/readers.scss";
import { Image } from "semantic-ui-react";
import { Div, Button, Icon } from "@components/Generics.react";
import * as DOMPurify from "dompurify";

export default function ProductGrid(props) {
    const { deck, currentState, recordAttempt } = props;
    const imageRef = useRef(null);
    const [activeSectionIdx, setActiveSectionIdx] = useState(0);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const sections = deck?.content?.sections;
    const activeSection = sections ? sections[activeSectionIdx] : null;

    return (
        <Div
            fluid
            maxWd="400px"
            ht="500px"
            medpad
            centered
            smoke
            rounded
            center-align
        >
            <Div fluid fullht ash priText className="product-grid-reader">
                <Div relative topRounded noOverflow>
                    <div ref={imageRef}>
                        <Image fluid src={deck?.content?.image} />
                        <Div
                            charcoal
                            centered
                            bold
                            topRounded
                            className="product-grid-name"
                            uppercase
                        >
                            {deck?.content?.name}
                        </Div>
                    </div>
                </Div>

                {sections && (
                    <Div
                        fluid
                        ht={`calc(100% - ${imageRef.current?.clientHeight}px)`}
                        bottomRounded
                        noOverflow
                    >
                        <Div fullht autoOverflowY relative>
                            <Div className="button-container" flex>
                                {sections.slice(0, 9).map((section, index) => (
                                    <Div
                                        className="product-grid-btn"
                                        key={`product-grid-btn-${index}`}
                                        spaced
                                        clickable
                                    >
                                        <Div
                                            className="product-grid-btn-img"
                                            pureCircle
                                            noOverflow
                                        >
                                            <Image
                                                src={section.image}
                                                onClick={() =>
                                                    setActiveSectionIdx(index)
                                                }
                                            />
                                        </Div>
                                        <Div tiny uppercase gapTop altText>
                                            {section.title}
                                        </Div>
                                    </Div>
                                ))}
                            </Div>
                            {activeSection && (
                                <Div
                                    className="product-grid-content"
                                    relative
                                    fullht
                                    flex
                                    left-align
                                    layer={100}
                                >
                                    <Div
                                        fullht
                                        white
                                        basepad
                                        className="content-block"
                                    >
                                        <Div flex spaceAround gutter>
                                            <Div wd="30%">
                                                <Image
                                                    src={activeSection.image}
                                                />
                                            </Div>
                                            <Div padded big altText wd="70%">
                                                {activeSection.title}
                                            </Div>
                                        </Div>
                                        {activeSection.description && (
                                            <Div
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(
                                                        activeSection.description
                                                    ),
                                                }}
                                            />
                                        )}
                                        {activeSection.buttons && (
                                            <Div gapTop flex flexStart gapped>
                                                {activeSection.buttons.map(
                                                    (btn, index) => {
                                                        if (!btn.btnLink)
                                                            return (
                                                                <Div>
                                                                    {btn.option}
                                                                </Div>
                                                            );
                                                        else if (
                                                            btn.btnLink.includes(
                                                                "http"
                                                            )
                                                        )
                                                            return (
                                                                <a
                                                                    href={
                                                                        btn.btnLink
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    key={`prod-card-link-${index}`}
                                                                >
                                                                    <Div
                                                                        snug
                                                                        fluid
                                                                    >
                                                                        <Button
                                                                            primary
                                                                            fluid
                                                                        >
                                                                            {
                                                                                btn.text
                                                                            }
                                                                        </Button>
                                                                    </Div>
                                                                </a>
                                                            );
                                                        else
                                                            return (
                                                                <NavLink
                                                                    to={
                                                                        btn.btnLink
                                                                    }
                                                                    key={`prod-card-link-${index}`}
                                                                >
                                                                    <Div
                                                                        snug
                                                                        fluid
                                                                    >
                                                                        <Button
                                                                            primary
                                                                            fluid
                                                                        >
                                                                            {
                                                                                btn.text
                                                                            }
                                                                        </Button>
                                                                    </Div>
                                                                </NavLink>
                                                            );
                                                    }
                                                )}
                                            </Div>
                                        )}
                                    </Div>
                                    <Div
                                        className="button-bar"
                                        flex
                                        column
                                        style={{ gap: "2px" }}
                                    >
                                        <Div
                                            midnight
                                            padded
                                            clickable
                                            onClick={() =>
                                                setActiveSectionIdx(null)
                                            }
                                        >
                                            <Icon large name="close" />
                                        </Div>
                                        <Div
                                            className="support-btn"
                                            charcoal
                                            padded
                                            clickable
                                            onClick={() =>
                                                setActiveSectionIdx(
                                                    activeSectionIdx - 1
                                                )
                                            }
                                        >
                                            <Icon big name="chevron left" />
                                        </Div>
                                        <Div
                                            className="support-btn"
                                            charcoal
                                            padded
                                            clickable
                                            onClick={() =>
                                                setActiveSectionIdx(
                                                    activeSectionIdx + 1
                                                )
                                            }
                                        >
                                            <Icon big name="chevron right" />
                                        </Div>
                                    </Div>
                                </Div>
                            )}
                        </Div>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
