import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import "@styles/editor.scss";

import { Menu, Icon, Tab, Divider, Dropdown, Confirm } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import InlineInput from "@components/InlineInput.react";
import InlineTextarea from "@components/InlineTextarea.react";

import PreviewLayout from "@layouts/PreviewLayout.react";
import { text_truncate } from "@utilities/helpers";
import { deckFormats } from "@schemas/deckFormats";
import { NavLink } from "react-router-dom";
import ContestContentModal from "./ContestContentModal.react";

export default function ContestContent(props) {
    const {
        contest,
        updateContest,
        updateDeck,
        deleteDeck,
        moveDeck,
        deleteTopic,
    } = props;

    const [activeTab, setActiveTab] = useState(1);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [deleteDeckConfirm, setDeleteDeckConfirm] = useState(false);
    const [deleteTopicConfirm, setDeleteTopicConfirm] = useState(false);
    const [targetDeck, setTargetDeck] = useState(null);
    const { t } = useTranslation("common");

    async function addTopic() {
        const locations = [...contest.locations];
        locations.push({
            name: t("builder.contest.newTopic"),
            description: t("builder.contest.newDescription"),
            image: null,
            sequence: locations.length,
            contentList: [],
        });
        await updateContest({
            id: contest._id,
            locations: locations,
        }).unwrap();
        toast(t("builder.contest.topicAdded"));
    }

    async function handleTopicUpdate(target, data, topicId) {
        let locations = _.cloneDeep(contest.locations);
        let thisLocation = _.find(locations, { _id: topicId });
        if (thisLocation) {
            thisLocation[target] = data;
            await updateContest({
                id: contest._id,
                locations: locations,
            }).unwrap();
            toast(t("builder.contest.topicUpdated"));
        }
    }
    async function moveTopic(topicId, direction) {
        let locations = _.cloneDeep(contest.locations);
        const locIndex = _.findIndex(locations, { _id: topicId });
        const loc2Index = direction === "up" ? locIndex - 1 : locIndex + 1;
        if (locIndex) {
            const temp = locations[locIndex]; // Store the value at index1 in a temporary variable.
            locations[locIndex] = locations[loc2Index]; // Set the value at index1 to the value at loc2Index.
            locations[loc2Index] = temp;
            locations[locIndex].sequence = locIndex;
            locations[loc2Index].sequence = loc2Index;
            await updateContest({
                id: contest._id,
                locations: locations,
            }).unwrap();
            toast(t("builder.contest.topicMoved"));
        }
    }
    async function doDeleteTopic() {
        await deleteTopic({
            id: contest.locations[activeTab - 1]._id,
            contestId: contest._id,
        });
        setDeleteTopicConfirm(false);
        toast(t("builder.contest.topicDeleted"));
    }

    async function renameDeck(id, name) {
        await updateDeck({
            id: id,
            name: name,
        }).unwrap();
        toast(t("builder.deck.renamed"));
    }

    async function doMoveDeck(deckId, direction) {
        await moveDeck({
            id: deckId,
            direction: direction,
        }).unwrap();
        toast(t("builder.deck.moved"));
    }
    async function moveDeckToTopic(deckId, topicId) {
        await updateDeck({
            id: deckId,
            topic_id: topicId,
            contest_id: contest._id,
        }).unwrap();
        toast(t("builder.deck.moved"));
    }

    function attemptDeleteDeck(deckId) {
        setTargetDeck(deckId);
        setDeleteDeckConfirm(true);
    }

    async function doDeleteDeck() {
        await deleteDeck(targetDeck).unwrap();
        setTargetDeck(null);
        setDeleteDeckConfirm(false);
        toast(t("builder.deck.deleted"));
    }

    function handleTabChange(e, { activeIndex }) {
        if (activeIndex < contest.locations.length + 1 && activeIndex !== 0)
            setActiveTab(activeIndex);
    }

    function decksPanel(topic) {
        return _.map(
            _.orderBy(topic.contentList, ["sequence"], ["asc"]),
            (deck, idy) => {
                return (
                    <Div
                        flex
                        spaceBetween
                        white
                        snug
                        rimmed
                        key={`content-piece-${idy}`}
                    >
                        <Div wd="calc(100% - 75px)" flex flexStart gapped>
                            <Div half compact uppercase tiny wd="100px">
                                {
                                    _.find(deckFormats, {
                                        readerType: deck.readerType,
                                    })?.name
                                }
                            </Div>
                            <Div wd="calc(100% - 110px)">
                                <InlineInput
                                    text={deck.name}
                                    handleSave={(data) => {
                                        renameDeck(deck._id, data);
                                    }}
                                    showHelp={false}
                                />
                            </Div>
                        </Div>
                        <Div rightAlign clickable>
                            <NavLink
                                to={`/builder/editor/${deck.readerType}/${deck._id}/${deck.name}`}
                            >
                                <Div
                                    primary
                                    compact
                                    inline
                                    small
                                    hoverBold
                                    snubbed
                                >
                                    {t("components.edit")}
                                </Div>
                            </NavLink>
                            <Dropdown
                                icon="ellipsis vertical"
                                direction="right"
                            >
                                <Dropdown.Menu position="right">
                                    <Dropdown.Item
                                        icon="chevron up"
                                        text={t("builder.deck.moveUp")}
                                        disabled={idy === 0}
                                        onClick={() =>
                                            doMoveDeck(deck._id, "up")
                                        }
                                    />
                                    <Dropdown.Item
                                        icon="chevron down"
                                        text={t("builder.deck.moveDown")}
                                        disabled={
                                            idy === topic.contentList.length - 1
                                        }
                                        onClick={() =>
                                            doMoveDeck(deck._id, "down")
                                        }
                                    />
                                    <Dropdown
                                        item
                                        text={t("builder.deck.moveTo")}
                                    >
                                        <Dropdown.Menu position="left">
                                            {_.map(
                                                _.filter(
                                                    contest.locations,
                                                    (t) => t._id !== topic._id
                                                ),
                                                (t) => (
                                                    <Dropdown.Item
                                                        text={t.name}
                                                        onClick={() =>
                                                            moveDeckToTopic(
                                                                deck._id,
                                                                t._id
                                                            )
                                                        }
                                                    />
                                                )
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        icon="trash"
                                        text={t("builder.deck.delete")}
                                        onClick={() =>
                                            attemptDeleteDeck(deck._id)
                                        }
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Div>
                    </Div>
                );
            }
        );
    }

    function topicPanel(topic, idx) {
        return (
            <Tab.Pane key={`folder-panel-${idx}`}>
                <Div big bold uppercase>
                    <Div float-right clickable>
                        <Icon
                            name="chevron up"
                            onClick={() => moveTopic(topic._id, "up")}
                        />
                        <Icon
                            name="chevron down"
                            onClick={() => moveTopic(topic._id, "down")}
                        />
                        <Icon
                            name="trash"
                            onClick={() => setDeleteTopicConfirm(true)}
                        />
                    </Div>
                    {t("builder.contest.topic")} {idx + 1}:
                </Div>
                <Divider />
                <Div bold>
                    <InlineInput
                        text={topic.name}
                        handleSave={(data) =>
                            handleTopicUpdate("name", data, topic._id)
                        }
                    />
                </Div>
                <Div gapBottom>
                    <InlineTextarea
                        text={topic.description}
                        handleSave={(data) =>
                            handleTopicUpdate("description", data, topic._id)
                        }
                    />
                </Div>
                <Div gapBottom>
                    <Button
                        size="small"
                        primary
                        fluid
                        icon="plus"
                        labelPosition="left"
                        content={t("builder.contest.addContent")}
                        onClick={() => setNewModalOpen(true)}
                    />
                </Div>
                <Div ash medpad>
                    <Div bold compact gapBottom uppercase>
                        {t("builder.contest.contentList")}:
                    </Div>
                    {decksPanel(topic, idx)}
                </Div>
                <ContestContentModal
                    {...{
                        newModalOpen,
                        setNewModalOpen,
                        contestId: contest._id,
                        topicId: topic._id,
                        sequence: topic.contentList.length,
                    }}
                />
            </Tab.Pane>
        );
    }

    const panes = _.concat(
        {
            menuItem: (
                <Menu.Item key="tree-header" className="content-tree-header">
                    {t("builder.contest.topicListHeader")}:
                </Menu.Item>
            ),
        },
        _.map(
            _.orderBy(contest.locations, ["sequence"], ["asc"]),
            (topic, idx) => {
                return {
                    menuItem: (
                        <Menu.Item key={"folder-" + idx}>
                            <Icon name="chevron right" />
                            <Div wd="75%" noWrap>
                                <Icon
                                    name={
                                        idx === activeTab - 1
                                            ? "folder open"
                                            : "folder outline"
                                    }
                                    color={
                                        idx === activeTab - 1 ? "black" : "grey"
                                    }
                                />
                                {text_truncate(topic.name, 25)}
                            </Div>
                        </Menu.Item>
                    ),
                    render: () => topicPanel(topic, idx),
                };
            }
        ),
        {
            menuItem: (
                <Menu.Item key="tree-footer" className="content-tree-footer">
                    <Button
                        fluid
                        primary
                        icon="folder"
                        labelPosition="left"
                        content={t("builder.contest.addTopic")}
                        onClick={addTopic}
                    />
                </Menu.Item>
            ),
        }
    );

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padTop flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 320px)" basepad>
                <Div large uppercase trench bold>
                    {t("components.editing")}: {contest?.name}
                </Div>
                <Div smoke fluid padSides className="content-area">
                    <Tab
                        menu={{ fluid: true, vertical: true }}
                        menuPosition="left"
                        activeIndex={activeTab}
                        panes={panes}
                        onTabChange={handleTabChange}
                    />
                </Div>
            </Div>
            <Confirm
                open={deleteDeckConfirm}
                onCancel={() => setDeleteDeckConfirm(false)}
                onConfirm={doDeleteDeck}
            />
            <Confirm
                open={deleteTopicConfirm}
                onCancel={() => setDeleteTopicConfirm(false)}
                onConfirm={doDeleteTopic}
            />
            <PreviewLayout
                key={`contest-preview-${contest.wrapper}`}
                url={`/contests/${contest._id}`}
                message={
                    contest.wrapper &&
                    contest.wrapper !== "none" &&
                    t("builder.dic.previewHelp")
                }
            />
        </Div>
    );
}
