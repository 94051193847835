import BadgeReader from "@player/decks/readers/BadgeReader.react";
import CertificateReader from "@player/decks/readers/CertificateReader.react";
import RewardReader from "@player/decks/readers/RewardReader.react";
import DocumentReader from "@player/decks/readers/DocumentReader.react";
import VideoReader from "@player/decks/readers/VideoReader.react";
import QdfReader from "@player/decks/readers/QdfReader.react";
import AssessmentReader from "@player/decks/readers/AssessmentReader.react";
import GameReader from "@player/decks/readers/GameReader.react";
import HTMLReader from "@player/decks/readers/HTMLReader.react";
import CourseArticleReader from "@player/decks/readers/CourseArticleReader.react";
import PodcastReader from "@player/decks/readers/PodcastReader.react";
import MemeReader from "@player/decks/readers/MemeReader.react";
import CheckListReader from "@player/decks/readers/CheckListReader.react";
import FAQReader from "@player/decks/readers/FAQReader.react";
import ProductCard from "@player/decks/readers/ProductCard.react";
import ProductFAQ from "@player/decks/readers/ProductFAQ.react";
import ProductCatalog from "@player/decks/readers/ProductCatalog.react";
import PlaylistReader from "@player/decks/readers/PlaylistReader.react";
import IframeReader from "@player/decks/readers/IframeReader.react";
import SurveyReader from "@player/decks/readers/SurveyReader.react";
import SCORMReader from "@player/decks/readers/SCORMReader.react";
import InfographicReader from "@player/decks/readers/InfographicReader.react";
import GalleryReader from "@player/decks/readers/GalleryReader.react";
import NewsReader from "@player/decks/readers/NewsReader.react";
import EventReader from "@player/decks/readers/EventReader.react";
import WebinarReader from "@player/decks/readers/WebinarReader.react";
import ProductGrid from "@player/decks/readers/ProductGrid.react";
import RatingReader from "../pages/player/decks/readers/RatingReader.react";

import HTMLField from "../pages/creator/decks/editors/formComponents/HTMLField.react";
import FileField from "../pages/creator/decks/editors/formComponents/FileField.react";
import InputField from "../pages/creator/decks/editors/formComponents/InputField.react";
// import SelectField from "../pages/creator/decks/editors/formComponents/SelectField.react";
import IconSelectField from "../pages/creator/decks/editors/formComponents/IconSelectField.react";
import ColorField from "../pages/creator/decks/editors/formComponents/ColorField.react";
import ImageField from "../pages/creator/decks/editors/formComponents/ImageField.react";
import DateField from "../pages/creator/decks/editors/formComponents/DateField.react";
import ButtonField from "../pages/creator/decks/editors/formComponents/ButtonField.react";
import LeaderboardReader from "../pages/player/decks/readers/LeaderboardReader.react";

export const deckFormats = {
    article: {
        name: "Blog Post",
        category: "article",
        readerType: "htmldeck",
        inPlayer: false,
        icons: ["bullhorn", "magazine", "newspaper", "typewriter"],
        reader: HTMLReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                formField: HTMLField,
                header: "Article Content",
                help: "Edit the content of your article in this WYSIWYG Content Editor",
                height: "100%",
            },
        ],
    },
    document: {
        name: "Document",
        category: "document",
        readerType: "docdeck",
        inPlayer: true,
        icons: ["file-text2", "page-break", "share3", "magazine"],
        reader: DocumentReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                formField: FileField,
                header: "Upload Document",
                help: "You can upload pdfs from the local drive on your computer. To upload PPTX and DOCX files, save them as PDF and upload.",
                accepts: ".pdf",
                maximum: 2048,
            },
        ],
    },
    video: {
        name: "Video",
        category: "video",
        readerType: "videck",
        inPlayer: false,
        icons: ["movie", "clapboard-play", "video-camera", "play3"],
        reader: VideoReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                formField: InputField,
                header: "Video Link",
                help: "Add a Video Link",
            },
            {
                target: null,
                formField: FileField,
                header: "Or Upload a Video",
                help: "You can upload videos from the local drive on your computer.",
                accepts: ".mp4",
                maximum: 2048,
            },
        ],
    },
    badge: {
        name: "Badge",
        category: "badge",
        readerType: "badgedeck",
        inPlayer: true,
        icons: ["star", "medal2", "rocket3", "chess-king"],
        reader: BadgeReader,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
                width: "50%",
            },
            {
                target: "description",
                formField: InputField,
                header: "Description",
                width: "50%",
            },
            {
                target: "icon",
                formField: IconSelectField,
                header: "Select an Icon",
                help: "Search for an icon and select it by clicking on it.",
            },
            {
                target: "bgColor",
                formField: ColorField,
                header: "Select Ring Color",
                width: "33.33%",
            },
            {
                target: "fgColor",
                formField: ColorField,
                header: "Select Disc Color",
                width: "33.33%",
            },
            {
                target: "textColor",
                formField: ColorField,
                header: "Select Icon Color",
                width: "33.33%",
            },
            {
                target: "points",
                formField: InputField,
                header: "Points",
                help: "Specify number of points the user will receive when he opens this badge",
            },
        ],
    },
    certificate: {
        name: "Certificate",
        category: "certificate",
        readerType: "certdeck",
        inPlayer: true,
        icons: ["certificate", "stars", "user-tie", "sun3"],
        reader: CertificateReader,
        ribbon: null,
        contentSchema: [
            {
                target: "title",
                formField: InputField,
                header: "Name",
                help: "Specify the name of the certificate ",
                width: "50%",
            },
            {
                target: "content",
                formField: InputField,
                header: "Achievement",
                help: "Specify the achievement to be mentioned for the user",
                width: "50%",
            },
            {
                target: "background",
                formField: ImageField,
                header: "Certificate Background",
                aspectRatio: 0.75,
                width: "50%",
            },
            {
                target: "signature",
                formField: ImageField,
                header: "Certificate Signature",
                aspectRatio: 1,
                width: "50%",
            },
            {
                target: "points",
                formField: InputField,
                header: "Points",
                help: "Specify number of points the user will receive when he opens this certificate",
            },
        ],
    },
    infographic: {
        name: "Infographic",
        category: "infographic",
        readerType: "infodeck",
        inPlayer: true,
        icons: ["chart-bars", "align-bottom", "angle", "dots"],
        reader: InfographicReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                formField: FileField,
                header: "Upload Infographic",
                help: "You can upload a high quality image as an infographic.",
                accepts: "image/*",
                maximum: 2048,
            },
        ],
    },
    gallery: {
        name: "Gallery",
        category: "gallery",
        readerType: "gallerydeck",
        inPlayer: false,
        icons: ["images", "pinter4", "stackoverflow", "finder"],
        reader: GalleryReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                formField: [ImageField],
                header: "Upload Images for the Gallery",
                subheader: "Gallery Image",
                help: "Select the images for your gallery",
                aspectRatio: 0.5625,
            },
        ],
    },
    reward: {
        name: "Reward",
        category: "reward",
        readerType: "rewarddeck",
        inPlayer: true,
        icons: ["gift2", "percent", "bubble-heart", "trophy4"],
        reader: RewardReader,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
            },
            {
                target: "image",
                formField: ImageField,
                header: "Image",
                aspectRatio: 0.5625,
            },
            {
                target: "header",
                formField: InputField,
                header: "Header",
                help: "Announce the Reward",
            },
            {
                target: "content",
                formField: InputField,
                header: "Description",
                help: "Describe the reward and how to collect it",
            },
        ],
    },
    webinar: {
        name: "Webinar",
        category: "webinar",
        readerType: "webinardeck",
        inPlayer: true,
        icons: ["bubble-video", "book-play", "collaboration", "earth"],
        reader: WebinarReader,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
                width: "50%",
            },
            {
                target: "date",
                formField: DateField,
                header: "Date",
                width: "50%",
                showTime: true,
            },
            {
                target: "image",
                formField: ImageField,
                header: "Image",
                aspectRatio: 0.5625,
            },

            {
                target: "description",
                formField: HTMLField,
                header: "Webinar Details",
                help: "Specify what the audience can expect from your webinar",
            },
            {
                target: "link",
                formField: InputField,
                header: "Registration Link / Video Link",
                help: "Add a registration link before the webinar or a video link for the webinar after it is completed",
            },
        ],
    },
    event: {
        name: "Event",
        category: "event",
        readerType: "eventdeck",
        inPlayer: true,
        icons: ["calender4", "bullhorn", "camera3", "mic5"],
        reader: EventReader,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
                width: "50%",
            },
            {
                target: "image",
                formField: ImageField,
                header: "Image",
                aspectRatio: 0.5625,
                width: "50%",
            },
            {
                target: "description",
                formField: HTMLField,
                header: "Event Details",
            },
            {
                target: "date",
                formField: DateField,
                header: "Date",
                showTime: true,
                width: "50%",
            },
            {
                target: "location",
                formField: InputField,
                header: "Location",
                width: "50%",
            },
            {
                target: "btn",
                formField: ButtonField,
                header: "Contact details",
            },
        ],
    },
    news: {
        name: "News",
        category: "news",
        readerType: "newsdeck",
        inPlayer: false,
        icons: ["newspaper", "antenna2", "archive", "display4"],
        reader: NewsReader,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
                width: "50%",
            },
            {
                target: "image",
                formField: ImageField,
                header: "Image",
                aspectRatio: 0.75,
                width: "50%",
            },
            {
                target: "date",
                formField: DateField,
                header: "Date",
                help: "Specify the date of the news article",
                showTime: true,
            },
            {
                target: "description",
                formField: HTMLField,
                header: "Summary",
                help: "Put in a summary of the new article",
            },
            {
                target: "link",
                formField: InputField,
                header: "Original Link",
                help: "Put in the link to the original news article",
            },
        ],
    },
    faq: {
        name: "FAQ",
        category: "faq",
        readerType: "faqdeck",
        inPlayer: false,
        icons: ["question3", "cancel-circle2", "menu8", "checkmark-circle"],
        reader: FAQReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                header: "Questions",
                help: "Add the frequently asked questions",
                subheader: "title",
                formField: [
                    {
                        target: "title",
                        formField: InputField,
                        header: "Title",
                    },
                    {
                        target: "description",
                        formField: HTMLField,
                        header: "Description",
                    },
                    {
                        target: "button",
                        formField: ButtonField,
                        header: "External Links",
                    },
                ],
            },
        ],
    },
    rating: {
        name: "Rating",
        category: "rating",
        readerType: "ratingdeck",
        inPlayer: true,
        icons: ["star-empty2", "stairs-up", "power2", "arrow-up16"],
        reader: RatingReader,
        ribbon: null,
        contentSchema: [
            {
                target: "instructions",
                formField: HTMLField,
                header: "Instructions",
                help: "Provide the instructioms for filling the rating form",
            },
            {
                target: "scale",
                formField: InputField,
                header: "Length of scale",
                help: "Provide the number of the units in the scale",
            },
            {
                target: "questions",
                flatField: true,
                formField: [InputField],
                header: "Rating Questions",
                help: "Put in the aspects you would like to be rated on",
                maxLength: 50,
            },
        ],
    },
    productcatalog: {
        name: "Product Catalog",
        category: "productcatalog",
        readerType: "productcatalogdeck",
        inPlayer: false,
        icons: ["notebook", "database-menu", "cart5", "benu2"],
        reader: ProductCatalog,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
                width: "50%",
            },
            {
                target: "image",
                formField: ImageField,
                header: "Image",
                aspectRatio: 0.5,
                width: "50%",
            },
            {
                target: "products",
                header: "Product Catalog",
                help: "Add the products",
                subheader: "title",
                formField: [
                    {
                        target: "title",
                        formField: InputField,
                        header: "Name",
                    },
                    {
                        target: "image",
                        formField: ImageField,
                        header: "Image",
                        aspectRatio: 0.5,
                    },
                    {
                        target: "description",
                        formField: InputField,
                        header: "Description",
                    },
                    {
                        target: "meta",
                        formField: InputField,
                        header: "Meta",
                    },
                    {
                        target: "link",
                        formField: InputField,
                        header: "Link",
                    },
                ],
            },
        ],
    },
    productcard: {
        name: "Product Card",
        category: "productcard",
        readerType: "productcarddeck",
        inPlayer: true,
        icons: ["android", "at-sign", "bag", "basket"],
        reader: ProductCard,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
                width: "50%",
            },
            {
                target: "image",
                formField: ImageField,
                header: "Image",
                aspectRatio: 0.5,
                width: "50%",
            },
            {
                target: "sections",
                header: "Product Sections",
                help: "Add the sections of the product",
                subheader: "title",
                formField: [
                    {
                        target: "title",
                        formField: InputField,
                        header: "Title",
                    },
                    {
                        target: "image",
                        formField: ImageField,
                        header: "Image",
                        aspectRatio: 0.33,
                    },
                    {
                        target: "description",
                        formField: HTMLField,
                        header: "Description",
                    },
                    {
                        target: "color",
                        formField: ColorField,
                        header: "Button Color",
                    },
                    {
                        target: "icon",
                        formField: IconSelectField,
                        header: "Button Icon",
                        help: "Select the icon for the button",
                    },
                    {
                        target: "buttons",
                        flatField: true,
                        formField: [ButtonField],
                        header: "External Links",
                    },
                ],
            },
        ],
    },
    productgrid: {
        name: "Product Grid",
        category: "productgrid",
        readerType: "productgriddeck",
        inPlayer: true,
        icons: ["grid5", "books", "cube3", "dropbox"],
        reader: ProductGrid,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
                width: "50%",
            },
            {
                target: "image",
                formField: ImageField,
                header: "Image",
                aspectRatio: 0.5,
                width: "50%",
            },
            {
                target: "sections",
                header: "Product Sections",
                help: "Add the sections of the product",
                subheader: "title",
                formField: [
                    {
                        target: "title",
                        formField: InputField,
                        header: "Title",
                    },
                    {
                        target: "image",
                        formField: ImageField,
                        header: "Image",
                        aspectRatio: 1,
                    },
                    {
                        target: "description",
                        formField: HTMLField,
                        header: "Description",
                    },
                    {
                        target: "buttons",
                        flatField: true,
                        formField: [ButtonField],
                        header: "External Links",
                    },
                ],
            },
        ],
    },
    productfaq: {
        name: "Product FAQ",
        category: "productfaq",
        readerType: "productfaqdeck",
        inPlayer: true,
        icons: ["question", "cancel-circle2", "menu8", "checkmark-circle"],
        reader: ProductFAQ,
        ribbon: null,
        contentSchema: [
            {
                target: "name",
                formField: InputField,
                header: "Name",
                width: "50%",
            },
            {
                target: "image",
                formField: ImageField,
                header: "Image",
                aspectRatio: 0.5,
                width: "50%",
            },
            {
                target: "questions",
                header: "Questions",
                help: "Add the frequently asked questions",
                subheader: "title",
                formField: [
                    {
                        target: "title",
                        formField: InputField,
                        header: "Title",
                    },
                    {
                        target: "description",
                        formField: HTMLField,
                        header: "Description",
                    },
                    {
                        target: "button",
                        flatField: true,
                        formField: ButtonField,
                        header: "External Links",
                    },
                ],
            },
        ],
    },
    meme: {
        name: "Meme",
        category: "meme",
        readerType: "memedeck",
        inPlayer: false,
        icons: ["wink2", "archery", "grin2", "bubble-star"],
        reader: MemeReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                header: "Memes",
                help: "Add the meme images",
                subheader: "title",
                formField: [
                    {
                        target: "title",
                        formField: InputField,
                        header: "Title",
                        width: "50%",
                    },
                    {
                        target: "image",
                        formField: ImageField,
                        header: "Image",
                        width: "50%",
                    },
                    {
                        target: "topText",
                        formField: InputField,
                        header: "Text on top",
                        help: "This text will appear on the top. Leave blank to skip...",
                        width: "50%",
                    },
                    {
                        target: "bottomText",
                        formField: InputField,
                        header: "Text on bottom",
                        help: "This text will appear on the bottom. Leave blank to skip...",
                        width: "50%",
                    },
                    {
                        target: "topTextColor",
                        formField: ColorField,
                        header: "Top Text Color",
                        width: "50%",
                    },
                    {
                        target: "bottomTextColor",
                        formField: ColorField,
                        header: "Bottom Text Color",
                        width: "50%",
                    },
                ],
            },
        ],
    },
    iframe: {
        name: "Webpage",
        category: "iframe",
        readerType: "iframedeck",
        inPlayer: true,
        icons: ["presentation", "contract", "exclude", "checkbox-partial2"],
        reader: IframeReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                formField: InputField,
                header: "Webpage Link",
                help: "Add a webpage link. Please note that the site should allow for iframe embedding.",
            },
        ],
    },
    leaderboard: {
        name: "Leaderboard",
        category: "leaderboard",
        readerType: "leaderboarddeck",
        inPlayer: true,
        icons: ["podium", "medal-star", "trophy", "archery"],
        reader: LeaderboardReader,
        ribbon: null,
        contentSchema: [
            {
                target: null,
                header: "Leaders",
                help: "Add the leaderboard data",
                subheader: "name",
                formField: [
                    {
                        target: "name",
                        formField: InputField,
                        header: "Name",
                        width: "50%",
                    },
                    {
                        target: "image",
                        formField: ImageField,
                        header: "Avatar",
                        aspectRatio: 1,
                        width: "50%",
                    },
                    {
                        target: "points",
                        formField: InputField,
                        header: "Points",
                    },
                ],
            },
        ],
    },
    story: {
        name: "Story",
        category: "story",
        readerType: "qdf",
        inPlayer: true,
        icons: ["quill2", "theater", "ticket2", "weather-windy"],
        reader: QdfReader,
        ribbon: {
            home: ["save", "file", "view", "settings"],
            design: ["palette", "colors", "background"],
        },
    },
    assessment: {
        name: "Assessment",
        category: "assessment",
        readerType: "assessment",
        inPlayer: true,
        icons: ["brain", "question4", "reading", "stack-text"],
        reader: AssessmentReader,
        ribbon: {
            home: ["save", "file", "view", "settings"],
            design: ["palette", "colors", "background"],
        },
    },
    survey: {
        name: "Survey",
        category: "survey",
        readerType: "survey",
        inPlayer: true,
        icons: ["file-text", "clipboard5", "file-stats2", "layers"],
        reader: SurveyReader,
        ribbon: {
            home: ["save", "file", "view", "settings"],
            design: ["palette", "colors", "background"],
        },
    },
    game: {
        name: "Game",
        category: "game",
        readerType: "game",
        inPlayer: true,
        icons: ["gamepad", "dice", "pacman", "puzzle"],
        reader: GameReader,
        ribbon: {
            home: ["save", "file", "view", "settings"],
            design: ["palette", "colors", "background"],
        },
    },

    // Not Done Yet
    //---------------------------------

    scratchcards: {
        name: "scratchcards",
        category: "scratchcards",
        readerType: "scratchcarddeck",
        inPlayer: true,
        icons: ["cash", "credit-card", "price-tags", "stack4"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    scorm: {
        name: "scorm",
        category: "scorm",
        readerType: "scormdeck",
        inPlayer: true,
        icons: ["cash", "credit-card", "price-tags", "stack4"],
        reader: SCORMReader,
        ribbon: null
    },
    course: {
        name: "Course",
        category: "Course",
        readerType: "coursedeck",
        inPlayer: false,
        icons: ["books", "graduation", "reading", "library2"],
        reader: CourseArticleReader,
        ribbon: null
    },
    poll: {
        name: "Poll",
        category: "poll",
        readerType: "polldeck",
        inPlayer: true,
        icons: ["equalizer", "stats-growth2", "rating3", "transmission2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    podcast: {
        name: "podcast",
        category: "podcast",
        readerType: "podcastdeck",
        inPlayer: true,
        icons: ["podcast", "headset", "media", "mic"],
        reader: PodcastReader,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    playlist: {
        name: "playlist",
        category: "playlist",
        readerType: "playlistdeck",
        inPlayer: true,
        icons: ["playlist", "file-music", "speakers", "next"],
        reader: PlaylistReader,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    guidelines: {
        name: "Guidelines",
        category: "guidelines",
        readerType: "guidelinesdeck",
        inPlayer: true,
        icons: ["clipboard2", "bubble-lines3", "checkmark", "bubble-lock"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    reel: {
        name: "Reel",
        category: "reel",
        readerType: "reeldeck",
        inPlayer: true,
        icons: ["camera3", "heart3", "cool", "video2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    discussion: {
        name: "Discussion",
        category: "discussion",
        readerType: "discussiondeck",
        inPlayer: false,
        icons: ["bubbles2", "lan3", "users2", "thumbs-up3"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    photosubmission: {
        name: "Photo Submission",
        category: "photosubmission",
        readerType: "photosubmissiondeck",
        inPlayer: true,
        icons: ["enlarge5", "enter2", "file-picture2", "happy2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    checklist: {
        name: "Checklist",
        category: "checklist",
        readerType: "checklistdeck",
        inPlayer: true,
        icons: ["clipboard5", "menu3", "list", "file-check"],
        reader: CheckListReader,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    profiler: {
        name: "Profiler",
        category: "profiler",
        readerType: "profilerdeck",
        inPlayer: true,
        icons: ["profile", "user5", "bubble-user", "safe"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    recommender: {
        name: "recommender",
        category: "recommender",
        readerType: "recommenderdeck",
        inPlayer: true,
        icons: ["map", "mail4", "puzzle3", "reminder"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    calculator: {
        name: "Calculator",
        category: "calculator",
        readerType: "calcdeck",
        inPlayer: true,
        icons: ["calculator2", "calculator3", "rulers", "angle"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    responsivetable: {
        name: "Responsive Table",
        category: "responsivetable",
        readerType: "responsetabledeck",
        inPlayer: true,
        icons: ["chart", "pie-chart8", "table2", "equalizer3"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    flashcards: {
        name: "Flash cards",
        category: "flashcards",
        readerType: "flashcardsdeck",
        inPlayer: true,
        icons: [
            "stack-pictures",
            "stack-overflow",
            "attachment",
            "bubble-lines3",
        ],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    videosubmission: {
        name: "Video Submission",
        category: "videosubmission",
        readerType: "visubmissiondeck",
        inPlayer: true,
        icons: ["file-video2", "stack-plus", "video-camera5", "upload5"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    documentsubmission: {
        name: "Document Submission",
        category: "documentsubmission",
        readerType: "docsubmisiondeck",
        inPlayer: true,
        icons: ["stack-up", "file-zip2", "file-upload2", "file-check"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    dialogue: {
        name: "Dialogue",
        category: "dialogue",
        readerType: "dialoguedeck",
        inPlayer: true,
        icons: ["bubble", "megaphone", "bubble-dots3", "finder"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    spotlight: {
        name: "Spotlight",
        category: "spotlight",
        readerType: "spotlightdeck",
        inPlayer: true,
        icons: ["spotlight2", "spotlight", "fire", "power2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    interactivevideo: {
        name: "Interactive Video",
        category: "interactivevideo",
        readerType: "interactivevideck",
        inPlayer: true,
        icons: ["file-play2", "film4", "book-play", "pause"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    listicle: {
        name: "Listicle",
        category: "listicle",
        readerType: "listicledeck",
        inPlayer: false,
        icons: ["list", "menu2", "file-text", "lan2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    offer: {
        name: "Offer",
        category: "offer",
        readerType: "offerdeck",
        inPlayer: true,
        icons: ["sun3", "price-tags", "gift2", "percent"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    interactivecharts: {
        name: "Interactive Charts",
        category: "interactivecharts",
        readerType: "interactivechartsdeck",
        inPlayer: true,
        icons: ["pie-chart", "factory", "equalizer3", "file-stats2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    jobpost: {
        name: "Job Post",
        category: "jobpost",
        readerType: "jobpostdeck",
        inPlayer: false,
        icons: ["magazine", "user-tie", "reading", "vcard"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    downloadable: {
        name: "Downloadable",
        category: "downloadable",
        readerType: "downloadabledeck",
        inPlayer: true,
        icons: ["download", "stack-down", "copy3", "envelop5"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    default: {
        inPlayer: true,
        category: "default",
        icons: ["bubble-quote", "bubble5", "bubble-smiley", "bubble-lines4"],
        reader: HTMLReader,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
};
