
import ReactTable from "react-table-v6";
import _, { result } from "lodash";
import { Loader } from "semantic-ui-react";
import { Grid, Icon } from "semantic-ui-react";
import { Div,Button } from "@components/Generics.react";

import { useGetBuilderCourseLeaderboardQuery} from "@api/apiV6"
import { useParams } from "react-router-dom";

export default function LeaderboardWrapper(props) {
    const { id } = useParams();
    const {
        data : courseData,
        isSuccess: isLogDataSuccess,
        isError: isLogDataError,
        isLoading: isLogDataLoading,
    } = useGetBuilderCourseLeaderboardQuery(id)
    console.log(courseData,"course data")
    let fullColumns = [
        {
            Header: "Name",
            accessor: "name",
            Cell: (row) =>
                `${row.original.user.first_name} ${row.original.user.last_name}`,
            style: { textAlign: "center" },
        },
        {
            Header:"Points",
            id: "points",
            accessor: (d) => d.points,
            style: { textAlign: "center" },
        },
        {
            Header: "Completion",
            id: "complete",
            accessor: (d) => d.complete,
            Cell: ({ value }) => (value ? "Yes" : "No"),
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "true") {
                    return row[filter.id] !== 0;
                }
                return row[filter.id] === 0;
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
    ];

    if (isLogDataLoading) {
        return <Loader />;
    } else if (isLogDataSuccess && !isLogDataError) {
        return (
            
            <Div>
                <Div basepad>
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column teal>
                                <Div spaced charcol bold big left-align large uppercase>
                                    Leaderboard
                                </Div>
                            </Grid.Column>
                            <Grid.Column floated='left'>
                                <Div spaced
                                    vapor
                                    right-align
                                >
                                    <Button charcol>
                                        <Icon name='download' />
                                        Download CSV
                                    </Button>
                                </Div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Div>
                
              
       <ReactTable
                data={courseData}
                filterable
                defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                        ? String(row[id])
                              .toLowerCase()
                              .includes(filter.value.toLowerCase())
                        : true;
                }}
                columns={fullColumns}
                pageSizeOptions={[5, 10,20]}
                defaultPageSize={20}
                className="-striped -highlight"
            />
           </Div>
           
        );
    } else {
        return <Div>Error</Div>;
    }
}
