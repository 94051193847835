import React from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";

import MasterLayout from "@layouts/MasterLayout.react";
import ProgressAnalytics from "./panels/ProgressAnalytics.react";
import PerformanceAnalytics from "./panels/PerformanceAnalytics.react";
import ActivityLog from "./panels/ActivityLog.react";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function AppReports(props) {
    const { t } = useTranslation("common");
    const panes = [
        {
            menuItem: "Progress",
            render: () => (
                <Tab.Pane>
                    <ProgressAnalytics />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Performance",
            render: () => (
                <Tab.Pane>
                    <PerformanceAnalytics />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Activity Log",
            render: () => (
                <Tab.Pane>
                    <ActivityLog />
                </Tab.Pane>
            ),
        },
    ];

    return (
        <MasterLayout
            title={"Analytics"}
            mainPanel={<Tab panes={panes} />}
            rightPanelName={t(`rightPanel.recommended`)}
            rightPanel={<RecommendedArticles />}
        />
    );
}
