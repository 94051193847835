import React from "react";
import _ from "lodash";
import { Grid, Divider } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import MasterLayout from "@layouts/MasterLayout.react";
import { Div } from "@components/Generics.react";
import Banner from "@components/Banner.react";
import { useGetBannersQuery } from "@api/apiV6";
import GeneralHome from "./panels/GeneralHome.react";
import { settings } from "@config/settings/app.settings";
import QuickLinks from "@components/QuickLinks.react";
import RecommendedArticles from "@components/RecommendedArticles.react";
import SearchField from "@components/SearchField.react";

export default function Home(props) {
    const { data: banners, isSuccess: bannerSuccess } = useGetBannersQuery();
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    const stages = settings.gamification.stages;
    const showStage = _.keys(stages).length > 1;

    const isPortrait = window.innerHeight > window.innerWidth;

    const pageContent = (
        <Div padTop={isPortrait}>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={8}>
                        {bannerSuccess && <Banner ads={banners} />}
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Div headline altText gutter>
                            {t("home.title")}
                        </Div>
                        <Div trench={!isPortrait}>{t("home.context")}</Div>
                        {showStage && !isPortrait && (
                            <Div
                                charcoal
                                relaxed
                                uppercase
                                center-align
                                rounded
                            >
                                {`${t("home.stage")} ${t(`stages.${user.stage}`)}`}
                            </Div>
                        )}
                    </Grid.Column>
                </Grid.Row>
                {isPortrait && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            {!settings.functionality.hasHome && <QuickLinks />}
                            <Div gutter>
                                <Divider horizontal>
                                    <SearchField />
                                </Divider>
                            </Div>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
            <br />
            {<GeneralHome />}
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={`Welcome to ${t("appName")}`}
            mainPanel={pageContent}
            rightPanelName={t(`rightPanel.recommended`)}
            rightPanel={<RecommendedArticles />}
            rootPage={!isPortrait || !settings.functionality.hasHome}
        />
    );
}
