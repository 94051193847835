import { Grid, Loader } from "semantic-ui-react";
import { useGetLearnerCoursePerformanceQuery } from "@api/apiV6";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

import BarChart from "@components/charts/BarChart.react";
import RadialProgressChart from "@components/charts/RadialProgressChart.react";

export default function CoursePerformance(props) {
    const { userId, courseId } = props;
    const {
        data: performanceData,
        isSuccess: isPerformanceSuccess,
        isError: isPerformanceError,
        isLoading: isPerformanceLoading,
    } = useGetLearnerCoursePerformanceQuery({ userId, courseId: courseId });
    const isPortrait = window.innerHeight > window.innerWidth;

    if (isPerformanceLoading) {
        return <Loader />;
    } else if (isPerformanceSuccess && !isPerformanceError) {
        return (
            <Div gapTop relaxed={!isPortrait} big>
                <Grid stackable>
                    <Grid.Row columns={3}>
                        <Grid.Column
                            color={
                                performanceData.completed_at != null
                                    ? "teal"
                                    : performanceData.started
                                    ? "orange"
                                    : "red"
                            }
                        >
                            <Div small>Status:</Div>
                            <span>
                                {performanceData.completed_at != null
                                    ? "COMPLETE"
                                    : performanceData.started
                                    ? "IN PROGRESS"
                                    : "NOT STARTED"}
                            </span>
                        </Grid.Column>
                        <Grid.Column color="grey">
                            {performanceData.sequential
                                ? "Sequentially accessed course content"
                                : "Freely accessed course content"}
                        </Grid.Column>
                        <Grid.Column color="grey">
                            {performanceData.has_final
                                ? "Final assessment based completion"
                                : "Coverage based completion"}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Div center-align compact bold uppercase>
                                Final Score
                            </Div>
                            <RadialProgressChart
                                width={100}
                                height={100}
                                color={settings.colors.main}
                                value={performanceData.final_score}
                            />
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Div center-align compact bold uppercase>
                                Assessment Scores
                            </Div>
                            <BarChart
                                width={400}
                                height={100}
                                data={performanceData.assessment_scores}
                                series={[
                                    {
                                        dataKey: "score",
                                        fill: settings.colors.main,
                                    },
                                ]}
                                skipXlabels={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Div>
        );
    } else {
        return <Div>Error</Div>;
    }
}
