// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";

// Import from Config
// -------------------------------------
import { isStringifiedJSON } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";
import { deckFormats } from "@schemas/deckFormats";

// Import Components
// -------------------------------------

export default function DeckPreviewer(props) {
    const { deck, readerType, slideEditor, slideNum, setSlideNum } = props;

    // console.log("Deck", deck);

    const DeckReader = _.find(deckFormats, {
        readerType: readerType,
    })?.reader;

    let adjDeck = deck;
    if (isStringifiedJSON(deck.content)) {
        adjDeck = {
            ...deck,
            ...{ content: JSON.parse(deck.content) },
        };
    }
    if (adjDeck.quo_game?.folder) {
        adjDeck.quo_game = adjDeck.quo_game.folder;
    }

    // console.log("Deck: ", adjDeck);

    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            relative
            vapor
            ht={`calc(100vh - ${slideEditor ? 220 : 60}px)`}
            superpad={!slideEditor}
            autoOverflowY
        >
            <Div
                white={!slideEditor}
                peat={slideEditor}
                rounded
                basepad={!slideEditor}
                autoOverflowY
                slightShadow
                minHt="600px"
            >
                <Div
                    ht={slideEditor ? "calc(100vh - 190px)" : "auto"}
                    maxHt="700px"
                    gapTop={!slideEditor}
                >
                    <DeckReader
                        key={`deck-reader-${adjDeck.id}`}
                        deck={adjDeck}
                        currentState={null}
                        colors={{ primary: settings.colors.main }}
                        recordAttempt={() => null}
                        inCourse={true}
                        closeDeck={() => null}
                        openDeckView={() => null}
                        setIsSaving={false}
                        isPreview={true}
                        slideNum={slideNum}
                        setSlideNum={setSlideNum}
                    />
                </Div>
            </Div>
        </Div>
    );
}
