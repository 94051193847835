import React, { useEffect } from "react";
import * as DOMPurify from "dompurify";

import "@styles/readers.scss";

import { Div } from "@components/Generics.react";

export default function HTMLReader(props) {
    const { deck, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ========================= Render Function =================================
    return (
        <Div fullht centered compact noOverflow relative>
            <Div
                padTop
                className="html-reader"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(deck.content, {
                        ADD_ATTR: ["target", "rel"],
                    }),
                }}
            />
        </Div>
    );
}
