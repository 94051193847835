import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "@styles/readers.scss";
import { Div, Button } from "@components/Generics.react";
import { Checkbox, Input, Modal } from "semantic-ui-react";
import Header from "@components/Header.react";
import html2pdf from "html2pdf.js";

export default function CheckListReader(props) {
    /**
     * Dummy Data with Responses
     */
    let dummyData = {
        name: "Sales Call Checklist",
        blob: {
            title: "Checklist Name",
            content: [
                "Record and review your call",
                "Start with a friendly greeting",
                "Make sure nothing has changed since the last communication",
                "Set call agenda and expectations.",
                "Reiterate pain points",
                "Talk about product value, not features",
                "Reference your unique differentiator",
                "Record and review your call",
                "Start with a friendly greeting",
                "Make sure nothing has changed since the last communication",
                "Set call agenda and expectations.",
                "Reiterate pain points",
                "Talk about product value, not features",
                "Reference your unique differentiator",
                "Record and review your call",
                "Start with a friendly greeting",
                "Make sure nothing has changed since the last communication",
                "Set call agenda and expectations.",
                "Reiterate pain points",
                "Talk about product value, not features",
                "Apurva-Reference your unique differentiator",
            ],
        },
        responses: [
            {
                seq: 0,
                responses: {
                    title: "Acme Corp",
                    options: [
                        {
                            d: "Fri Jun 09 2023 04:50:30 GMT+0000 (Coordinated Universal Time)",
                            r: [0, 1, 2],
                        },
                        {
                            d: "Fri Jun 09 2023 04:56:02 GMT+0000 (Coordinated Universal Time)",
                            r: [1, 2],
                        },
                    ],
                },
            },
            {
                seq: 1,
                responses: {
                    title: "Acme Corp1",
                    options: [
                        {
                            d: "Fri Jun 09 2023 04:50:30 GMT+0000 (Coordinated Universal Time)",
                            r: [0, 1, 2],
                        },
                        {
                            d: "Fri Jun 09 2023 04:56:02 GMT+0000 (Coordinated Universal Time)",
                            r: [1, 2],
                        },
                    ],
                },
            },
        ],
    };

    /**
     * Dummy Data without Responses
     */

    // let dummyData = {
    //   name: "Sales Call Checklist",
    //   blob: {
    //     title: "Checklist Name",
    //     content: [
    //       "Record and review your call",
    //       "Start with a friendly greeting",
    //       "Make sure nothing has changed since the last communication",
    //       "Set call agenda and expectations.",
    //       "Reiterate pain points",
    //       "Talk about product value, not features",
    //       "Reference your unique differentiator",
    //       "Record and review your call",
    //       "Start with a friendly greeting",
    //       "Make sure nothing has changed since the last communication",
    //       "Set call agenda and expectations.",
    //       "Reiterate pain points",
    //       "Talk about product value, not features",
    //       "Reference your unique differentiator",
    //       "Record and review your call",
    //       "Start with a friendly greeting",
    //       "Make sure nothing has changed since the last communication",
    //       "Set call agenda and expectations.",
    //       "Reiterate pain points",
    //       "Talk about product value, not features",
    //       "Apurva-Reference your unique differentiator",
    //     ],
    //   },
    //   responses: [],
    // };

    const { name, blob, responses } = dummyData;

    const { deck, currentState, recordAttempt, openDeckView } = props;
    const { t } = useTranslation("common");

    let checklist = blob?.content || [];
    let latestRecord = responses[responses.length - 1] || {};
    let latestRecordOptions = latestRecord.responses?.options || [];
    let latestCompleteResponse =
        latestRecordOptions[latestRecordOptions.length - 1] || {};
    let latestResponse = latestCompleteResponse.r || [];
    let formattedList = checklist.map((item, index) => {
        return {
            label: item,
            checked: latestResponse.includes(index),
        };
    });

    const [processedList, setProcessedList] = useState(formattedList);
    const [checklistTitle, setChecklistTitle] = useState(
        latestRecord.responses?.title
    );
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeunload", trackData);

        return () => {
            trackData();
            window.removeEventListener("beforeunload", trackData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function trackData() {
        recordAttempt({
            deckId: deck._id,
            completion: currentState.completion === 100 ? 100 : 0,
            date: Date.now(),
        });
    }

    function handleCheckboxChange(e, { value }) {
        const newItems = processedList.map((item, sidx) => {
            return {
                label: item.label,
                checked: sidx === value ? !item.checked : item.checked,
            };
        });
        setProcessedList(newItems);
    }

    function saveDraft() {}

    function downloadAsPDF() {
        var opt = {
            margin: 1,
            filename: checklistTitle || name,
            image: { type: "jpeg", quality: 0.5 },
            html2canvas: { scale: 1.1 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        const element = document.getElementById("checklist");
        html2pdf().set(opt).from(element).save();
        openDeckView(deck._id);
    }

    function openConfirmationModal() {
        setOpenModal(!openModal);
    }

    function createNewChecklist() {
        const newItems = processedList.map((item, sidx) => {
            return {
                label: item.label,
                checked: false,
            };
        });
        setProcessedList(newItems);
        setChecklistTitle("");
        setOpenModal(false);
    }

    return (
        <Div
            fluid
            ht="90%"
            white
            scrollOverflow
            left-align
            priText
            normal
            className="checklist-reader"
        >
            <Header title={name} />
            <Div bold gutter>
                {blob?.title}
            </Div>
            <Div id="checklist">
                <Div gutter>
                    <Input
                        fluid
                        placeholder="Please enter the title"
                        value={checklistTitle}
                        onChange={(e) => setChecklistTitle(e.target.value)}
                    />
                </Div>

                <Div trench>
                    {processedList.map((item, index) => {
                        return (
                            <Div key={`checklist-item-${index}`} gutter>
                                {" "}
                                <Checkbox
                                    {...item}
                                    value={index}
                                    onChange={handleCheckboxChange}
                                />
                            </Div>
                        );
                    })}
                </Div>
            </Div>

            <Div
                topRounded
                center-align
                compact
                className="checklist-button-group"
            >
                <Button
                    basepad="true"
                    primary
                    labelPosition="right"
                    icon="save outline"
                    content={t("deck.checklist.saveButtonText")}
                    onClick={saveDraft}
                />
                <Button
                    basepad="true"
                    primary
                    labelPosition="right"
                    icon="share"
                    content={t("deck.checklist.exportButtonText")}
                    onClick={downloadAsPDF}
                />
                <Button
                    basepad="true"
                    primary
                    labelPosition="right"
                    icon="angle double right"
                    content={t("deck.checklist.newButtonText")}
                    onClick={openConfirmationModal}
                />
            </Div>
            <Modal
                closeIcon
                size="tiny"
                open={openModal}
                onClose={openConfirmationModal}
            >
                <Modal.Header>Are you sure ?</Modal.Header>
                <Modal.Content>
                    <p>
                        You want to clear the existing checklist and create new?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        secondary
                        content={t("deck.checklist.denialButtonText")}
                        onClick={openConfirmationModal}
                    />
                    <Button
                        primary
                        content={t("deck.checklist.acceptanceButtonText")}
                        onClick={createNewChecklist}
                    />
                </Modal.Actions>
            </Modal>
        </Div>
    );
}
