import React, { useState} from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dropdown, Grid,Icon } from "semantic-ui-react";


import { Div,Button } from "@components/Generics.react";
import {
  useGetCourseBuilderQuery,
} from "@api/apiV6";
import SingalTopicAnalysis from "./SingalTopicAnalysis.react";

export default function FileAnalytics(props) {

  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);


  const [activeTopic, setActiveTopic] = useState(null);
  const { data: courses, isSuccess: courseSuccess } =
    useGetCourseBuilderQuery(id);
  const isPortrait = window.innerHeight > window.innerWidth;

  let deckOptions = _.flatten(
    _.map(courses.locations, (topic) => {
      return _.map(
        _.filter(
          topic.contentList,
          (deck) => !["survey", "assessment"].includes(deck.readerType)
        ),
        (deck) => {
          return {
            key: deck._id,
            value: deck._id,
            //icon: iconName(deck.readerType),
            text: deck.name,
            readertype: deck.readerType,
          };
        }
      );
    })
  );

  return (
    <Div fitted>
      <Div fitted>
        <Grid doubling columns={2}></Grid>
        <br />
        {courseSuccess && (
          <Div medpad vapor>
            <Div>
              <Grid stackable>
                <Grid.Row columns={2}>
                  <Grid.Column teal>
                    <Div spaced charcol bold nopad left-align large uppercase>
                      SELECT THE CONTENT
                    </Div>
                  </Grid.Column>
                  <Grid.Column floated='left'>
                    <Div spaced
                      vapor
                      right-align
                    >
                      <Button charcol>
                        <Icon name='download' />
                        Download CSV
                      </Button>
                    </Div>

                  </Grid.Column>
                </Grid.Row>
              </Grid>
           </Div>
            <Dropdown
              placeholder="Select a course to get progress details"
              search
              fluid
              selection
              upward={isPortrait}
              options={deckOptions}
              value={deckOptions.id}
              onChange={(e, { value }) => setActiveTopic(value)}
            />
            <br />

            <SingalTopicAnalysis
              deckId={activeTopic}
              userId={user._id}
              courseId={id}
            />
          </Div>
        )}
      </Div>
    </Div>
  );
}


