import "@styles/components.scss";
import "@styles/icomoon.css";

import { useState } from "react";
import { Div, Icon } from "@components/Generics.react";
import * as DOMPurify from "dompurify";

export default function Accordion(props) {
    const { panels } = props;
    const [activeIndex, setActiveIndex] = useState(props.activeIndex || 0);

    // ========================= Render Function =================================
    return (
        <Div className="accordion">
            {panels.map((panel, index) => {
                const isActive = index === activeIndex;
                return (
                    <Div
                        bottomRounded
                        medpad
                        relative
                        peat
                        className="panel-container"
                        key={`accordion-panel-${index}`}
                    >
                        <Div
                            gutter
                            white
                            rounded
                            medpad
                            priText
                            left-aligned
                            clickable
                            onClick={() =>
                                setActiveIndex(isActive ? -1 : index)
                            }
                        >
                            <Div flex spaceBetween>
                                <Div wd="calc(100% - 60px)" bold>
                                    {panel.title}
                                </Div>
                                <Icon
                                    nopad
                                    name={
                                        isActive
                                            ? "minus circle"
                                            : "plus circle"
                                    }
                                    txtCharcoal
                                />
                            </Div>
                            {isActive && (
                                <Div clear gapTop>
                                    <Div wd="40%" primary fitted />
                                    <Div gapTop priText small>
                                        {typeof panel.content === "string" ||
                                        panel.content instanceof String ? (
                                            <Div
                                                gapTop
                                                priText
                                                small
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(
                                                        panel.content
                                                    ),
                                                }}
                                            />
                                        ) : (
                                            <Div gapTop priText small>
                                                {panel.content}
                                            </Div>
                                        )}
                                    </Div>
                                </Div>
                            )}
                        </Div>
                    </Div>
                );
            })}
        </Div>
    );
}
