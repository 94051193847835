// Import from NPM
// -------------------------------------
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Div } from "@components/Generics.react";
import "@styles/editor.scss";

import SingleBtn from "@components/ribbon/SingleBtn.react";
import RowBtns from "@components/ribbon/RowBtns.react";
import OneTwoBtns from "@components/ribbon/OneTwoBtns.react";
import { downloadJSONAsFile, encrypt, decrypt } from "@utilities/helpers";

export default function HomeMenu(props) {
    const {
        id,
        deck,
        updateDeck,
        config,
        saveDeck,
        saveDeckAndExit,
        resetDeck,
        cancelExit,
        rightPanel,
        setRightPanel,
    } = props;
    const fileInputRef = useRef(null);
    const { t } = useTranslation("common");

    const handleUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const encryptedFile = e.target.result.replace(/['"]+/g, "");
                    const uploadedData = decrypt(encryptedFile);
                    const parsedData = JSON.parse(uploadedData);
                    updateDeck(parsedData);
                    toast(t("builder.ribbon.contentUpdated"));
                    setTimeout(() => {
                        fileInputRef.current.value = null; // Reset the file input
                    }, 3000);
                } catch (error) {
                    console.error(t("builder.ribbon.jsonError"), error);
                }
            };

            reader.readAsText(file);
        }
    };

    function handleDownload() {
        const encryptedData = encrypt(JSON.stringify(deck));
        downloadJSONAsFile(
            encryptedData,
            `${id}_${new Date(Date.now()).toLocaleDateString("en-IN")}.qdf`
        );
        toast(t("builder.ribbon.contentDownloaded"));
    }

    // ========================= Render Function =================================
    return (
        <Div flex flexStart>
            {config.includes("settings") && (
                <SingleBtn
                    name={t("components.save")}
                    icon="save"
                    action={saveDeck}
                    leftBordered={false}
                />
            )}
            {config.includes("save") && (
                <OneTwoBtns
                    name="Save Operations"
                    btns={[
                        {
                            name: t("components.saveExit"),
                            icon: "exit-up",
                            action: saveDeckAndExit,
                        },
                        {
                            name: t("builder.ribbon.reset"),
                            icon: "exit-up",
                            action: resetDeck,
                        },
                        {
                            name: t("components.cancelExit"),
                            icon: "cancel-square",
                            action: cancelExit,
                        },
                    ]}
                />
            )}
            {config.includes("file") && (
                <RowBtns
                    name={t("builder.ribbon.fileHandle")}
                    btns={[
                        {
                            name: t("components.upload"),
                            icon: "upload",
                            action: () => fileInputRef.current.click(),
                        },
                        {
                            name: t("components.download"),
                            icon: "download",
                            action: handleDownload,
                        },
                    ]}
                />
            )}
            {config.includes("view") && (
                <RowBtns
                    name={t("builder.ribbon.viewToggle")}
                    btns={[
                        {
                            name: t("builder.ribbon.mobile"),
                            icon: "smartphone",
                            active: rightPanel === "mobile",
                            action: () => setRightPanel("mobile"),
                        },
                        {
                            name: t("builder.ribbon.sorter"),
                            icon: "sort-amount-asc3",
                            active: rightPanel === "sorter",
                            action: () => setRightPanel("sorter"),
                        },
                        {
                            name: t("builder.ribbon.notes"),
                            icon: "bubbles8",
                            active: rightPanel === "notes",
                            action: () => setRightPanel("notes"),
                        },
                    ]}
                />
            )}
            {config.includes("settings") && (
                <SingleBtn
                    name={t("builder.ribbon.settings")}
                    icon="cog9"
                    action={() => setRightPanel("settings")}
                />
            )}
            <Div hidden>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept=".qdf"
                    onChange={handleUpload}
                />
            </Div>
        </Div>
    );
}
