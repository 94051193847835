// Import from NPM
// -------------------------------------
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Icon, Dropdown, Image } from "semantic-ui-react";
import _ from "lodash";
import "@styles/navbars.scss";
import "@styles/icomoon.css";
import { darkStyle } from "@utilities/helpers";
import { Div } from "@components/Generics.react";

import { useTranslation } from "react-i18next";
import { settings } from "@config/settings/app.settings";

export default function BottomMenu(props) {
    const { t } = useTranslation("common");
    const user = useSelector((state) => state.auth.user);
    const cores = _.filter(
        settings.menu.core,
        (tLink) =>
            tLink.hideInStage == null ||
            !tLink.hideInStage.includes(user.stage.toString())
    );

    // ========================= Render Function =================================
    return (
        <Menu
            className="qd-bottom-menu"
            fluid
            widths={cores.length}
            icon="labeled"
            style={darkStyle(settings.colors.main, "to top")}
        >
            {_.map(
                settings.menu.quick?.length > 0 ? _.initial(cores) : cores,
                (mainlink, index) => {
                    return (
                        <Menu.Item
                            name={mainlink.transCode}
                            key={`main-menu-link-${index}`}
                        >
                            <NavLink to={mainlink.link} className="mobile-link">
                                <i
                                    className={`icon icm icon-${mainlink.icon}`}
                                />
                                <br />
                                {t(`sidebar.mobile.${mainlink.transCode}`)}
                            </NavLink>
                        </Menu.Item>
                    );
                }
            )}
            {settings.menu.quick?.length > 0 && (
                <Menu.Item name="More">
                    <Dropdown
                        trigger={
                            <span
                                key={`main-menu-link-more`}
                                className="more mobile-link"
                            >
                                <Icon name="ellipsis vertical" />
                                <br />
                                {t(`sidebar.more`)}
                            </span>
                        }
                        upward
                        direction="left"
                    >
                        <Dropdown.Menu>
                            {_.map(
                                _.filter(
                                    settings.menu.quick,
                                    (tLink) =>
                                        tLink.hideInStage == null ||
                                        !tLink.hideInStage.includes(
                                            user.stage.toString()
                                        )
                                ),
                                (quicklink, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={`quick-menu-link-${index}`}
                                        >
                                            <Div maxWd="300px">
                                                <NavLink
                                                    to={quicklink.link}
                                                    className="quick-link"
                                                >
                                                    <Image
                                                        src={t(
                                                            `sidebar.quick.${quicklink.transCode}`
                                                        )}
                                                    />
                                                </NavLink>
                                            </Div>
                                        </Dropdown.Item>
                                    );
                                }
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
            )}
        </Menu>
    );
}
