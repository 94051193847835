import React, { useEffect } from "react";
import _ from "lodash";

import "@styles/readers.scss";

import { Div, Image } from "@components/Generics.react";
import Carousel from "@components/Carousel.react";

export default function GalleryReader(props) {
    const { deck, currentState, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    // ========================= Render Function =================================
    return (
        <Div rounded fluid fullht>
            <Carousel dots arrows customPaging autoPlay infinite>
                {_.map(deck.content, (img, idx) => (
                    <Image src={img} key={`gallery-deck-${idx}`} />
                ))}
            </Carousel>
        </Div>
    );
}
