import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import { Icon, Checkbox, Input } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import LearnerTable from "./LearnerTable.react";
import { settings } from "@config/settings/app.settings";
import BuilderHeader from "@components/BuilderHeader.react";
export default function CourseLearners(props) {
    const { course, updateCourse } = props;

    const { t } = useTranslation("common");
    const [showLaunch, setShowLaunch] = useState(true);
    const [search, setSearch] = useState("");

    async function launchCourse() {
        await updateCourse({
            id: course._id,
            launch: !course.launch,
        }).unwrap();
        toast(
            course.launch
                ? t("builder.course.unlaunched")
                : t("builder.course.launched")
        );
    }

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padTop flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 500px)" basepad>
                <BuilderHeader
                    image={course?.image}
                    header={`${t("builder.course.headers.launch.header")}: ${
                        course?.name
                    }`}
                    description={t("builder.course.headers.launch.help")}
                />
                <Div ivory fluid snubbed noOverflow flex>
                    <Div
                        fluid
                        basepad
                        danger={!course.launch}
                        success={course.launch}
                        snug
                        snubbed
                    >
                        <Div
                            clickable
                            onClick={() => setShowLaunch(!showLaunch)}
                        >
                            <Div float-right large>
                                <Icon
                                    name={
                                        showLaunch
                                            ? "chevron down"
                                            : "chevron up"
                                    }
                                />
                            </Div>
                            <Div bold big>
                                {t("builder.course.status")}:{" "}
                                {course.launch
                                    ? t("builder.course.published")
                                    : t("builder.course.unpublished")}
                            </Div>
                            <Div italics gutter>
                                {course.launch
                                    ? t("builder.course.publishedHelp")
                                    : t("builder.course.unpublishedHelp")}
                            </Div>
                        </Div>
                        {showLaunch && (
                            <Div vapor basepad snubbed>
                                <Checkbox
                                    toggle
                                    label={
                                        course.launch
                                            ? t("builder.course.launch")
                                            : t("builder.course.unlaunch")
                                    }
                                    checked={course.launch}
                                    onChange={launchCourse}
                                />
                                <br />
                                <br />
                                <Div white small slightShadow basepad snubbed>
                                    <Div big bold>
                                        {t(
                                            `builder.course.${
                                                course.launch
                                                    ? "unLaunch"
                                                    : "doLaunch"
                                            }.header`
                                        )}
                                    </Div>
                                    <Div>
                                        {t(
                                            `builder.course.${
                                                course.launch
                                                    ? "unLaunch"
                                                    : "doLaunch"
                                            }.message`
                                        )}
                                    </Div>
                                    <ul>
                                        {_.map(
                                            t(
                                                `builder.course.${
                                                    course.launch
                                                        ? "unLaunch"
                                                        : "doLaunch"
                                                }.points`
                                            ).split("||"),
                                            (pt, idx) => {
                                                return (
                                                    <li
                                                        key={`launch-pt-${idx}`}
                                                    >
                                                        {pt}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </Div>
                            </Div>
                        )}
                    </Div>
                </Div>
                <Div ivory fluid snubbed noOverflow>
                    <Div fluid basepad ash snug snubbed>
                        <Div bold big gutter>
                            {t("builder.course.enruleHeader")}:
                        </Div>
                        <Div gapBottom>{t("builder.course.enruleBody1")}</Div>
                        <Div gutter>{t("builder.course.enruleBody2")}</Div>
                        <Button
                            primary
                            fluid
                            content={t("builder.course.addEnrule")}
                        />
                    </Div>
                </Div>
            </Div>
            <Div fullht wd="500px" basepad>
                <Div
                    basepad
                    ivory
                    fluid
                    fullht
                    rounded
                    slightShadow
                    autoOverflowY
                >
                    <Div bold big uppercase>
                        {t("builder.course.manageLearners")}
                    </Div>
                    <Div fluid gapTop flex gapped spaceBetween gutter>
                        <Input
                            icon="search"
                            placeholder={t("components.search")}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Div>
                            <Button
                                primary
                                content={t("builder.course.invite")}
                            />
                            <Button primary content={t("builder.dic.upload")} />
                        </Div>
                    </Div>
                    {course.learnerCount > settings.largeUserbase ? (
                        <Div
                            white
                            fluid
                            center-align
                            superpad
                            flex
                            column
                            gapped
                            big
                            slightShadow
                        >
                            <Div gutter>{t("builder.course.tooHigh")}</Div>
                            <Button
                                primary
                                content={t("builder.course.checkEnrolled")}
                            />
                            <Button
                                primary
                                content={t("builder.course.unenrollUser")}
                            />
                            <Button
                                primary
                                content={t("builder.course.messageUser")}
                            />
                        </Div>
                    ) : (
                        <LearnerTable
                            course={course}
                            updateCourse={updateCourse}
                            search={search}
                        />
                    )}
                </Div>
            </Div>
        </Div>
    );
}
