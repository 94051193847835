import React, { useState, useEffect, useRef } from "react";
import _, { set } from "lodash";
import { Dropdown } from "semantic-ui-react";
import ReactTable from "react-table-v6";
import { Grid } from "semantic-ui-react";
import { useGetDeckPathQuery, useGetSurveyQuestionsPathQuery } from "@api/apiV6";
import { Div, Button,Icon } from "@components/Generics.react";
export default function AssessmentLearnersSubPanel(props) {
  const isPortrait = window.innerHeight > window.innerWidth;
  const reactTableRef = useRef(null);
  const [learnerData, setLearnerData] = useState(null);
  const [questionResponse, setQuestionResponse] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionData, setQuestionData] = useState(null)
  const { activeTopic, results } = props;
  const assessmentId = activeTopic;
  const deckId = activeTopic;
  const { data: assessmentData } = useGetDeckPathQuery({ deckId: deckId });
  const { data: surveyData } = useGetSurveyQuestionsPathQuery({ deckId: deckId });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionData = assessmentData?.content.map((slide, index) => ({
          key:
            assessmentId + "#" + slide.masterSeq,
          value:
            assessmentId + "#" + slide.masterSeq,
          text: slide.data.question,
          template: slide.template,
          data: slide.data,
          sequence: index,
        }));
        const learnerData = _.map(
          surveyData?.questions,
          (question, index) => {
            const targetQuestion = _.find(
              questionData,
              {
                key: question._id,
              }
            );
            return {
              id: targetQuestion.key,
              question: targetQuestion.text,
              template: targetQuestion.template,
              data: targetQuestion.data,
              learners: question.learners,
            };
          }
        )
        if (learnerData) {
          setLearnerData(learnerData);
          setQuestionData(questionData)
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [results]);


  useEffect(() => {

    const selectedResponse = _.find(learnerData, {
      id: selectedQuestion,
    });

    if (selectedResponse) {
      const questionResponse = _.map(selectedResponse?.learners, (learner) => {
        const user = _.find(results, {
          user_id: learner._id,
        });

        return {
          username: user?.username,
          response: handleResponse(
            selectedQuestion?.template,
            selectedQuestion?.data,
            learner.response
          ),
        };
      });
      setQuestionResponse(questionResponse);

    }

  }, [results, learnerData, selectedQuestion])

  const handleResponse = (template, data, response) => {
    try {
      switch (template) {
        case "true_false":
          return data.choice[response].text;
        case "single_select":
          return data.options[response].text;
        case "multiple_select":
          return _.map(response, (selection) => {
            return data.multiselect[selection].text;
          }).join("; ");
        case "fill_in_the_blanks":
        default:
          return response;
      }
    } catch (e) {
      console.log(e)
    }
  };

  const fullColumns = [
    {
      Header: "Learners",
      accessor: "username",
      minWidth: 128,
      style: { textAlign: "center", whiteSpace: "unset" },
    },
    {
      Header: "Response",
      id: "response",
      accessor: (d) => d.response,
      style: { textAlign: "center", whiteSpace: "unset" },
    },
  ];

  return (
    <Div vapor rounded relaxed={!isPortrait} small>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column teal>
            <Div spaced charcol bold nopad left-align large uppercase>
              Select a Question
            </Div>
          </Grid.Column>
          <Grid.Column floated='left'>
            <Div spaced
              vapor
              right-align
            >
              <Button charcol>
                <Icon name='download' />
                Download CSV
              </Button>
            </Div>

          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid.Row>
        <Dropdown
          placeholder="Select Learners"
          search
          fluid
          selection
          options={questionData}
          onChange={(e, { value }) => setSelectedQuestion(value)}
        />
        <br />
        <ReactTable
          ref={reactTableRef}
          data={questionResponse || []}
          filterable
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id]).includes(filter.value)
              : true;
          }}
          pageSizeOptions={[5, 10]}
          columns={fullColumns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </Grid.Row>
    </Div>
  );
}
