import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { deckFormats } from "@schemas/deckFormats";

import {
    Input,
    Image,
    TextArea,
    Form,
    Icon,
    Dropdown,
} from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import ImageUploader from "@components/ImageUploader.react";

import { settings } from "@config/settings/app.settings";
import PreviewLayout from "@layouts/PreviewLayout.react";

export default function ArticleSetup(props) {
    const { article, updateArticle, allArticles } = props;
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const [artName, setArtName] = useState(article.name);
    const [artDescription, setArtDescription] = useState(article.description);
    const [artSummary, setArtSummary] = useState(article.summary);
    const [artImage, setArtImage] = useState(article.image);
    const [artLang, setArtLang] = useState(article.languages);
    const [artSuggested, setArtSuggested] = useState(article.suggestedReading);

    const [imgEditorOpen, setImgEditorOpen] = useState(false);

    function saveArtImage(image) {
        setArtImage(image);
        setImgEditorOpen(false);
    }

    async function saveArticle() {
        await updateArticle({
            id: article._id,
            name: artName,
            image: artImage,
            description: artDescription,
            summary: artSummary,
            languages: artLang,
            suggestedReading: artSuggested,
        }).unwrap();
        toast(t("components.saved"));
    }
    async function saveArticleAndExit() {
        await saveArticle();
        navigate(-1);
    }
    async function cancelAndExit() {
        navigate(-1);
    }
    // ========================= Render Function =================================
    return (
        <Div fluid fullht basepad flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 320px)" basepad>
                <Div compact uppercase>
                    {t("builder.dic.setup")}{" "}
                    {
                        _.find(deckFormats, {
                            category: article?.category,
                        })?.name
                    }
                    :
                </Div>
                <Div large gutter compact bold>
                    {article?.name}
                </Div>

                <Div ivory fluid gapBottom snubbed noOverflow flex>
                    <Div grey relative wd="320px" minHt="105px">
                        <Image src={artImage} />
                        <Div
                            absolute
                            layer={3}
                            style={{ bottom: "10px", right: "10px" }}
                        >
                            <Div flex clickable gapped>
                                {artImage && (
                                    <Icon
                                        name={"trash"}
                                        color="red"
                                        circular
                                        inverted
                                        onClick={() => setArtImage(null)}
                                    />
                                )}
                                <Button
                                    size="mini"
                                    content={t("builder.dic.changeImage")}
                                    secondary
                                    onClick={() => setImgEditorOpen(true)}
                                />
                            </Div>
                            <ImageUploader
                                modalOpen={imgEditorOpen}
                                handleModalClose={() => setImgEditorOpen(false)}
                                handleModalSave={saveArtImage}
                                value={artImage}
                                aspectRatio={0.33}
                                mWidth={640}
                            />
                        </Div>
                    </Div>
                    <Div basepad wd="calc(100% - 320px)">
                        <Input
                            fluid
                            label={t("builder.dic.name")}
                            value={artName}
                            onChange={(e, { value }) => setArtName(value)}
                        />
                        <Input
                            fluid
                            label={t("builder.dic.description")}
                            value={artDescription}
                            onChange={(e, { value }) =>
                                setArtDescription(value)
                            }
                        />
                    </Div>
                </Div>
                <Div smoke basepad fluid snubbed snug>
                    <Div bold gapBottom>
                        {t("builder.article.summary")}
                    </Div>
                    <Form>
                        <TextArea
                            rows={2}
                            value={artSummary}
                            onChange={(e, { value }) => setArtSummary(value)}
                        />
                    </Form>
                </Div>
                {settings.languages && settings.languages.length > 1 && (
                    <Div basepad smoke snubbed snug>
                        <Div gapBottom bold>
                            {t("builder.dic.selectLang")}
                        </Div>
                        <Dropdown
                            placeholder={t("builder.dic.selectLang")}
                            fluid
                            selection
                            multiple
                            search
                            options={_.map(settings.languages, (l) => ({
                                key: l.code,
                                text: l.identifier,
                                value: l.code,
                            }))}
                            value={artLang}
                            onChange={(e, { value }) => setArtLang(value)}
                        />
                    </Div>
                )}
                <Div basepad smoke snubbed snug>
                    <Div gapBottom bold>
                        {t("builder.article.selectSuggested")}
                    </Div>
                    <Dropdown
                        placeholder={t("builder.article.selectSuggested")}
                        fluid
                        selection
                        multiple
                        search
                        options={_.map(allArticles, (a) => ({
                            key: a._id,
                            text: a.name,
                            value: a._id,
                        }))}
                        value={_.map(artSuggested, (a) => (a._id ? a._id : a))}
                        onChange={(e, { value }) => setArtSuggested(value)}
                    />
                </Div>

                <Div fluid padTop>
                    <Button
                        danger={+true}
                        content={t("components.cancel")}
                        onClick={cancelAndExit}
                    />
                    <Div float-right>
                        <Button
                            primary
                            content={t("components.save")}
                            onClick={saveArticle}
                        />
                        <Button
                            primary
                            content={t("components.saveExit")}
                            onClick={saveArticleAndExit}
                        />
                    </Div>
                </Div>
            </Div>
            <PreviewLayout
                url={`/content/${article.category}/${article.identifier}`}
            />
        </Div>
    );
}
