import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { Div, Image } from "@components/Generics.react";
import "@styles/components.scss";

export default function Certificate(props) {
    const { deck, disabled, logo } = props;
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    const certificateDate = new Date(deck?.currentState?.completionDate);
    return (
        <Div rounded noOverflow fluid relative className="certificate">
            <Image
                src={
                    deck.content?.background == null ||
                    deck.content?.background.indexOf("assets") !== -1 ||
                    deck.content?.background === ""
                        ? "/assets/images/configurable/certificate_back.jpg"
                        : deck.content?.background
                }
                className="cert-bg"
            />
            <Div className="cert-content-container" txtMidnight>
                <Div altText className="cert-title">
                    {deck.content?.title}
                </Div>
                <Div className="cert-leadLine">
                    {t("course.rewards.toCertify")}
                </Div>
                <Div className="cert-learner">
                    {user.first_name} {user.last_name}
                </Div>
                <Div className="cert-content">{deck.content?.content}</Div>
                <Div className="cert-logo">
                    <Image src={logo} />
                </Div>
                <Div className="cert-date">
                    {deck?.currentState?.completionDate &&
                        certificateDate.toLocaleDateString("en-IN")}
                </Div>
                <Div className="cert-ribbon">
                    <Image
                        src={
                            deck.content?.signature == null ||
                            deck.content?.signature.indexOf("assets") !== -1 ||
                            deck.content?.signature === ""
                                ? "/assets/images/configurable/certified.png"
                                : deck.content?.signature
                        }
                    />
                </Div>
            </Div>
            {disabled && (
                <Div className="cert-overlay">
                    <Icon name="lock" inverted size="huge" />
                </Div>
            )}
        </Div>
    );
}
