import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Div, Image, Icon } from "@components/Generics.react";
import "@styles/components.scss";
import { text_truncate } from "@utilities/helpers";

import { clientAssetsPath } from "../config/api/apiV6";
import CompletionIcon from "./CompletionIcon.react";

export default function CourseCard(props) {
    const { course, isLocked } = props;
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const imageExtensions = ["png", "jpeg", "jpg"];
    const extension = course.image?.split(".").pop().toLowerCase();
    const imageUrl = `${clientAssetsPath}/uploads/images/course/${course._id}.${extension}`;

    let locked = false;
    let lockMsg = "";

    if (isLocked) {
        locked = true;
        lockMsg = t("course.prereqLock");
    } else if (course.enable_timing) {
        const start_date = new Date(course.dates.start_date); // Replace this with your start_date
        const end_date = new Date(course.dates.end_date); // Replace this with your end_date
        const now = new Date();
        let localeDate;
        let localeTime;

        if (now < start_date) {
            localeDate = start_date.toLocaleDateString();
            localeTime = start_date.toLocaleTimeString();
            locked = true;
            lockMsg = t("course.notStarted")
                .replace("$date", localeDate)
                .replace("$time", localeTime);
        } else if (now > end_date) {
            localeDate = end_date.toLocaleDateString();
            localeTime = end_date.toLocaleTimeString();
            locked = true;
            lockMsg = t("course.hasEnded")
                .replace("$date", localeDate)
                .replace("$time", localeTime);
        }
    }

    // ========================= Render Function =================================
    return (
        <Div
            className="course-card"
            snubbed
            white
            slightShadow
            noOverflow
            clickable={!locked}
            relative
            onClick={() => (locked ? null : navigate(`/courses/${course._id}`))}
        >
            <Div float-left className="course-card-img-container">
                <Image
                    // src={`https://www.loremflickr.com/256/128/ai?random=${Math.random()}`}
                    src={
                        !imageExtensions.includes(extension)
                            ? "/assets/images/configurable/missing.jpg"
                            : imageUrl
                    }
                    alt={`Course ${course._id}`}
                />
            </Div>
            <Div float-left className="card-content">
                <Div compact small padTop>
                    <Div bold fitted title={course.name}>
                        {text_truncate(course.name, 40)}
                    </Div>
                    <Div
                        small
                        fitted
                        wd="80%"
                        gutter
                        title={course.description}
                    >
                        {text_truncate(course.description, 40)}
                    </Div>
                </Div>
                <Div className="category-progress-radial">
                    <CompletionIcon
                        completed={course?.currentState?.completion}
                        percentage={course?.currentState?.viewedPercentage}
                    />
                </Div>
            </Div>
            {locked && (
                <Div absolute fullht fluid overlaid flex relaxed>
                    <Div>
                        <Icon huge nudge-right float-left name="lock" />
                        {lockMsg}
                    </Div>
                </Div>
            )}
        </Div>
    );
}
