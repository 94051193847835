import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import { useGetPostsQuery, useSendSocialPostMutation } from "@api/apiV6";
import BuilderHeader from "@components/BuilderHeader.react";
import PostHandler from "./panels/PostHandler.react";
import { Input } from "semantic-ui-react";
import PostCreator from "./panels/PostCreator.react";

export default function CommunityManager(props) {
    const { data: posts } = useGetPostsQuery();
    const { t } = useTranslation("common");
    const [searchText, setSearchText] = useState("");
    const [sendSocialPost] = useSendSocialPostMutation();

    const allPosts = _.filter(posts, (post) => {
        return (
            _.includes(post.content, searchText) ||
            _.includes(post.description, searchText) ||
            _.includes(post.owner.first_name, searchText) ||
            _.includes(post.owner.last_name, searchText)
        );
    });

    const mainPanel = (
        <Div basepad fullht flex>
            <Div wd="calc(100% - 360px)" fullht>
                <BuilderHeader
                    image={"/assets/images/social.png"}
                    header={t("social.header")}
                    description={t("social.help")}
                    imgWidth="150px"
                />
                <Div ash medpad ht="calc(100% - 123px)">
                    <Input
                        fluid
                        placeholder={t("components.search")}
                        icon={{ name: "search" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Div basepad vapor autoOverflow ht="calc(100% - 40px)">
                        {_.map(allPosts, (post) => (
                            <PostHandler key={`Post-${post._id}`} post={post} />
                        ))}
                    </Div>
                </Div>
            </Div>

            <Div wd="360px" fullht basepad peat>
                <Div fullht basepad vapor>
                    <Div big bold uppercase gutter>
                        {t("social.createHeader")}:
                    </Div>
                    <PostCreator onSubmit={sendSocialPost} />
                </Div>
            </Div>
        </Div>
    );
    return (
        <CreatorLayout
            title={t("social.navName")}
            navName={t("social.navName")}
            pageCode={"social"}
            mainPanel={mainPanel}
            rootPage={true}
        />
    );
}
