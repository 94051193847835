import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Divider } from "semantic-ui-react";
import { Div, Button as XButton, Image } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

import { useSendUserSelfUpdateMutation } from "@api/apiV6";

import MasterLayout from "@layouts/MasterLayout.react";
import AvatarCreator from "./AvatarCreator.react";
import ImageUploader from "@components/ImageUploader.react";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function EditProfile(props) {
    const user = useSelector((state) => state.auth.user);
    const { isolated } = useParams();
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const [updateProfile] = useSendUserSelfUpdateMutation();

    const [avatarConfig, setAvatarConfig] = useState(null);
    const [imgEditorOpen, setImgEditorOpen] = useState(false);
    const [image] = useState(user.image);

    function changeAvatar(avatarData) {
        setAvatarConfig(avatarData);
    }
    const handleUpdateAvatar = async () => {
        try {
            await updateProfile({
                id: user._id,
                image: "remove",
                avatarConfig: avatarConfig,
            });
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    };
    const handleUpdateImage = async (image) => {
        try {
            await updateProfile({
                id: user._id,
                image: image,
                avatarConfig: null,
            });
            setImgEditorOpen(false);
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    };

    const editProfile = (
        <Div small charcoal superpad centered rounded>
            {user.image ? (
                <Div pureCircle noOverflow centered fit-content>
                    <Image src={user.image} />
                </Div>
            ) : (
                <AvatarCreator
                    avatarConfig={user.avatarConfig}
                    onChange={changeAvatar}
                />
            )}
            <Div centered fit-content padTop>
                <Button.Group size="large">
                    <XButton
                        content={user.image ? "Use Avatar" : "Save Avatar"}
                        primary
                        onClick={handleUpdateAvatar}
                    />
                    <Button.Or />
                    <XButton
                        content={"Upload Image"}
                        secondary
                        onClick={() => setImgEditorOpen(true)}
                    />
                </Button.Group>
            </Div>
            <ImageUploader
                modalOpen={imgEditorOpen}
                handleModalClose={() => setImgEditorOpen(false)}
                handleModalSave={handleUpdateImage}
                value={image}
                aspectRatio={1}
            />
            <Divider />
            {!settings.functionality.noLogout && (
                <Div basepad center-align>
                    <XButton
                        midnight
                        content={"Change your password"}
                        onClick={() => navigate("/setpass")}
                    />
                </Div>
            )}
        </Div>
    );

    return (
        <MasterLayout
            title={"Edit Profile"}
            navName={"Edit Profile"}
            mainPanel={editProfile}
            rightPanelName={t(`rightPanel.recommended`)}
            rightPanel={isolated === "update" ? null : <RecommendedArticles />}
            isolated={isolated === "update"}
        />
    );
}
