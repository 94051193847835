import { Message, Statistic, Grid, Loader } from "semantic-ui-react";
import { useGetLearnerCourseProgressQuery } from "@api/apiV6";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

import RadialProgressChart from "@components/charts/RadialProgressChart.react";

export default function CourseProgress(props) {
    const { userId, courseId } = props;
    const {
        data: progressData,
        isSuccess: isProgressSuccess,
        isError: isProgressError,
        isLoading: isProgressLoading,
    } = useGetLearnerCourseProgressQuery({ userId, courseId: courseId });
    const isPortrait = window.innerHeight > window.innerWidth;

    if (isProgressLoading) {
        return <Loader />;
    } else if (isProgressSuccess && !isProgressError) {
        let hours = Math.floor(progressData.time_spent / 3600);
        let minutes = Math.floor(progressData.time_spent / 60) % 60;
        let seconds = progressData.time_spent % 60;
        let result =
            (hours < 10 ? "0" + hours : hours) +
            ":" +
            (minutes < 10 ? "0" + minutes : minutes) +
            ":" +
            (seconds < 10 ? "0" + seconds : seconds);
        return (
            <Div gapTop relaxed={!isPortrait} big>
                <Grid stackable>
                    <Grid.Row columns={3}>
                        <Grid.Column
                            color={
                                progressData.complete
                                    ? "olive"
                                    : progressData.started
                                    ? "orange"
                                    : "red"
                            }
                        >
                            <Div small>Status:</Div>
                            <span>
                                {progressData.complete
                                    ? "COMPLETE"
                                    : progressData.started
                                    ? "IN PROGRESS"
                                    : "NOT STARTED"}
                            </span>
                        </Grid.Column>
                        <Grid.Column color="grey">
                            {progressData.sequential
                                ? "Sequentially accessed course content"
                                : "Freely accessed course content"}
                        </Grid.Column>
                        <Grid.Column color="grey">
                            {progressData.has_final
                                ? "Final assessment based completion"
                                : "Coverage based completion"}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Div center-align compact bold uppercase>
                                Content Coverage
                            </Div>
                            <RadialProgressChart
                                width={80}
                                height={80}
                                color={settings.colors.main}
                                value={progressData.content_coverage}
                            />
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Div gutter>
                                <Statistic.Group widths={isPortrait ? 1 : 3}>
                                    <Statistic>
                                        <Statistic.Value>
                                            {progressData.view_attempts}
                                        </Statistic.Value>
                                        <Statistic.Label>Views</Statistic.Label>
                                    </Statistic>
                                    <Statistic>
                                        <Statistic.Value
                                            text
                                            style={{
                                                margin: "0 auto",
                                                paddingTop: "20px",
                                            }}
                                        >
                                            {result}
                                        </Statistic.Value>
                                        <Statistic.Label>
                                            Time Spent
                                        </Statistic.Label>
                                    </Statistic>
                                    <Statistic>
                                        <Statistic.Value>
                                            {progressData.points}
                                        </Statistic.Value>
                                        <Statistic.Label>
                                            Points
                                        </Statistic.Label>
                                    </Statistic>
                                </Statistic.Group>
                            </Div>
                            <Div tiny center-align>
                                {progressData.complete && (
                                    <Message positive>
                                        Course completed on{" "}
                                        {new Date(
                                            progressData.completed_at
                                        ).toLocaleString("en-IN")}{" "}
                                    </Message>
                                )}
                                {!progressData.started && (
                                    <Message negative>
                                        This course has not been started yet
                                    </Message>
                                )}
                                {!progressData.complete &&
                                    progressData.started && (
                                        <Message
                                            positive={
                                                new Date() -
                                                    new Date(
                                                        progressData.last_accessed_at
                                                    ) <
                                                604800000
                                            }
                                            negative={
                                                progressData.last_accessed_at ===
                                                    null ||
                                                new Date() -
                                                    new Date(
                                                        progressData.last_accessed_at
                                                    ) >
                                                    604800000
                                            }
                                        >
                                            {progressData.last_accessed_at ===
                                            null ? (
                                                <div>
                                                    Access data is currently
                                                    unavailable
                                                </div>
                                            ) : (
                                                <div>
                                                    Last accessed the platform
                                                    on{" "}
                                                    {new Date(
                                                        progressData.last_accessed_at
                                                    ).toLocaleString(
                                                        "en-IN"
                                                    )}{" "}
                                                </div>
                                            )}
                                        </Message>
                                    )}
                            </Div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Div>
        );
    } else {
        return <Div>Error</Div>;
    }
}
