import React from "react";
import * as DOMPurify from "dompurify";
import { Div, Button } from "@components/Generics.react";
export default function OpenInternalLink(props) {
    const { paragraph } = props.data;
    return (
        <Div fluid fullht white scrollOverflow>
            <Div
                large
                superpad
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph),
                }}
            />
            <Div huge large>
                <a
                    href={props.data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button primary fluid size="huge">
                        Go
                    </Button>
                </a>
            </Div>
        </Div>
    );
}
