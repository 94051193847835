import React, { useEffect } from "react";
import * as DOMPurify from "dompurify";
import "@styles/readers.scss";
import { Image } from "semantic-ui-react";

import { Div, Button } from "@components/Generics.react";

export default function EventReader(props) {
    const { deck, currentState, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const event_date = new Date(deck.content?.date); // Replace this with your event_date
    const localeDate = event_date.toLocaleDateString("en-IN", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
    const localeTime = event_date.toLocaleTimeString("en-IN", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    });

    const linkValid = typeof deck.content?.btn?.btnLink === "string";

    // ========================= Render Function =================================
    return (
        <Div white rounded fluid fullht center-align>
            <Div rounded noOverflow>
                <Image src={deck.content?.image} />
            </Div>
            <Div fluid flex center-aligned column fullht superpad>
                <Div
                    txtColor="#333333"
                    gutter
                    altText
                    uppercase
                    headline
                    padTop
                >
                    {deck.content?.name}
                </Div>
                <Div gutter bold big>
                    {localeDate} at {localeTime}
                </Div>
                <Div gutter bold big>
                    {deck.content?.location}
                </Div>
                <Div
                    gutter
                    normal
                    small
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(deck.content?.description),
                    }}
                />
                {linkValid && (
                    <a href={deck.content?.btn?.btnLink}>
                        <Button primary content={deck.content?.btn?.text} />
                    </a>
                )}
            </Div>
        </Div>
    );
}
