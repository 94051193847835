import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "@layouts/MasterLayout.react";
import { baseUrl } from "@api/apiV6";
import { Div, Icon } from "@components/Generics.react";
import { devMode } from "@utilities/helpers";
import "@styles/readers.scss";

export default function SCORMReader(props) {
    const { deck, recordAttempt, closeDeck } = props;
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const { fullScreen, setFullScreen } = useContext(LayoutContext);

    const [loading, setLoading] = useState(true);
    const [suspendData, setSuspendData] = useState("");
    const isPortrait = window.innerHeight > window.innerWidth;

    let currentViewed = 0;
    let currentScore = 0;
    let pointsDelta = 0;

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);
        const sData = localStorage.getItem(
            `suspend_data_${deck._id}_${user._id}`
        );
        setSuspendData(sData);
        setLoading(false);

        return () => {
            window.removeEventListener("message", receiveMessage, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // The session time "0000:00:29.86" it appears to be in the format HHHH:MM:SS.ss
    // This function converts it to seconds
    function sessionTimeToSeconds(sessionTime) {
        const parts = sessionTime.split(":");
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseFloat(parts[2]);
    
        return Math.round(hours * 3600 + minutes * 60 + seconds);
    }

    function receiveMessage(event) {
        if (
            event.origin === baseUrl ||
            event.origin + "/" === baseUrl ||
            devMode
        ) {
            let messageData = isJson(event.data)
                ? JSON.parse(event.data)
                : {
                      suspend_data: "",
                      core: {
                          session_time: "",
                          interactions: { childArray: [] },
                          score: { raw: 0 },
                          lesson_status: "incomplete",
                      },
                  };
            console.log(messageData);
            window.localStorage.setItem(
                `suspend_data_${deck._id}_${user._id}`,
                messageData.suspend_data
            );
            let viewedPercentage =
                messageData.core &&
                (messageData.core.lesson_status === "passed" ||
                    messageData.core.lesson_status === "completed")
                    ? 100
                    : messageData.success_status === "passed" ||
                      messageData.success_status === "completed"
                    ? 100
                    : 0;
            let score =
                messageData.core && messageData.core.score
                    ? parseInt(messageData.core.score.raw, 0)
                    : messageData.score
                    ? parseInt(messageData.score.raw, 0)
                    : null;

            let sessionTime = messageData.core && messageData.core.session_time ? sessionTimeToSeconds(messageData.core.session_time) : 'NA';
            console.log("sessionTime: ", sessionTime);
            currentViewed = Math.max(currentViewed, viewedPercentage);
            currentScore = Math.max(currentScore, score || 0);
            pointsDelta =
                currentScore != null && deck.points != null
                    ? Math.max(
                          (currentScore * deck.points) / 100 -
                              (deck.currentState.points || 0),
                          0
                      )
                    : 0;
            recordAttempt({
                viewedPercentage: currentViewed,
                completion: currentViewed === 100,
                pointsDelta: pointsDelta,
                score: currentScore,
                sessionTime: sessionTime,
            });
        }
    }
    // function toggleFullScreen() {
    //     setFullScreen({ fullScreen: !fullScreen });
    // }

    if (loading) {
        return <Div />;
    } else {
        let userData = encodeURIComponent(
            JSON.stringify(
                (({ username, first_name, last_name }) => ({
                    v1: username,
                    v2: first_name,
                    v3: last_name,
                }))(user)
            )
        );
        let link =
            baseUrl +
            (baseUrl.charAt(baseUrl.length - 1) === "/" ? "" : "/") +
            "scorm?auth=" +
            userData +
            "&path=" +
            deck.path +
            "&suspend_data='" +
            suspendData +
            "'";
        return (
            <Div fluid fullht>
                <Div
                    relative
                    fluid
                    ht={isPortrait ? "calc(100vh - 200px)" : "100%"}
                    className="fullscreen-target"
                >
                    <iframe
                        title="scormframe"
                        id="scormframe"
                        src={link}
                        name="course"
                        width="100%"
                        height="100%"
                    />
                    {isPortrait &&
                        !fullScreen &&
                        !/iPad|iPhone|iPod/.test(navigator.userAgent) && (
                            <Div rimmed midnight className="gofull-btn">
                                <Icon
                                    name="expand"
                                    onClick={() => setFullScreen(true)}
                                />
                            </Div>
                        )}
                </Div>
                {isPortrait && <Div ht={"45px"} />}
                <Div className="close-icon" onClick={closeDeck}>
                    {t("deck.close")} <Icon name="close" inline fitted />
                </Div>
            </Div>
        );
    }
}

function isJson(json) {
    try {
        JSON.parse(json);
        return true;
    } catch (e) {
        return false;
    }
}
