import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Grid } from "semantic-ui-react";
import { Div, Image, Button } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service here
        console.error(error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

function ErrorPage(props) {
    const { t } = useTranslation("common");
    // ========================= Render Function =================================
    return (
        <Div fluid pageht style={darkStyle(settings.colors.main)}>
            <Helmet>
                <title>{t("components.errorHeader")}</title>
            </Helmet>
            <Div fluid pageht huge flex>
                <Div wd="80vw">
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Div giant altText center-align>
                                    <Image
                                        fluid
                                        src="/assets/images/configurable/error.png"
                                    />
                                </Div>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Div flex column fullht>
                                    <Div>
                                        <Div large altText uppercase gutter>
                                            {t("components.errorHeader")}
                                        </Div>
                                        <Div trench>
                                            {t("components.errorMsg")}
                                        </Div>
                                        <Button
                                            primary
                                            content={t("components.goBack")}
                                            onClick={() => {
                                                window.history.go(-1);
                                                setTimeout(
                                                    () =>
                                                        window.location.reload(),
                                                    500
                                                );
                                            }}
                                        />
                                        {settings.functionality.helpdeskActive && (
                                            <Button content={t("components.helpdeskReport")} />
                                        )}
                                    </Div>
                                </Div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Div>
            </Div>
        </Div>
    );
}
