import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import Carousel from "@components/Carousel.react";

export default function AnimatedBulletList(props) {
    const { bullets } = props.data;
    return (
        <Div fluid zoom superpad>
            <Carousel slidesToShow={1} arrows dots>
                {_.map(bullets, (bullet, idx) => {
                    return (
                        <Div
                            key={`list-item-${idx}`}
                            peat
                            superpad
                            slightShadow
                            left-aligned
                            spaced
                            rounded
                            big
                        >
                            {bullet}
                        </Div>
                    );
                })}
            </Carousel>
        </Div>
    );
}
