// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { List, Icon } from "semantic-ui-react";
import { text_truncate } from "@utilities/helpers";
import { Div, Image, Icon as XIcon } from "@components/Generics.react";
import { deckFormats } from "@schemas/deckFormats";

import "@styles/navbars.scss";
import "@styles/icomoon.css";
import CompletionIcon from "./CompletionIcon.react";

export default function LocationMenu(props) {
    const {
        content,
        openDeck,
        showMenu,
        closeMenu,
        sequential,
        wrapper,
        locCoords,
    } = props;
    const isPortrait = window.innerHeight > window.innerWidth;
    // ========================= Render Function =================================
    return (
        <Div
            fullht
            peat
            wd={showMenu ? (isPortrait ? "300px" : "350px") : 0}
            layer={1000}
            className="location-menu"
        >
            <Div ash className="location-menu-inner">
                {closeMenu && (
                    <XIcon
                        name="close"
                        float-right
                        huge
                        clickable
                        half
                        onClick={closeMenu}
                        style={{ padding: "13px 10px" }}
                    />
                )}
                <Div large basepad altText>
                    {text_truncate(content?.name, 24)}
                </Div>
                {content?.image && (
                    <Div
                        fluid
                        noOverflow
                        style={{ height: 0, paddingBottom: "50%" }}
                    >
                        <Image clear src={`${content.image}?${Date.now()}`} />
                    </Div>
                )}
                {(content?.image == null || content?.image === "") &&
                    wrapper && (
                        <Div
                            fluid
                            noOverflow
                            style={{ height: 0, paddingBottom: "50%" }}
                        >
                            <img
                                src={`/assets/images/wrappers/${wrapper}/play_backdrop.png`}
                                style={{
                                    width: "200%",
                                    marginLeft: -locCoords?.left + "%",
                                    marginTop: -locCoords?.top + "%",
                                }}
                                alt={"Topic Visuals"}
                            />
                        </Div>
                    )}
                <Div fluid relaxed half>
                    {text_truncate(content?.description, 140)}
                </Div>
                <Div compact fluid white>
                    <List divided>
                        {_.map(content?.contentList, (elm, idx) => {
                            const active = sequential
                                ? idx > 0
                                    ? content.currentState.subStates[
                                          content?.contentList[idx - 1]._id
                                      ]
                                    : true
                                : true;
                            const targetCompletion =
                                content.currentState.subStates[
                                    content?.contentList[idx]._id
                                ];
                            const targetReaderType = _.findKey(deckFormats, {
                                readerType: elm.readerType,
                            });
                            return (
                                <List.Item
                                    key={`loc-deck-item-${idx}`}
                                    className={`location-menu-item ${
                                        active ? "active" : "inactive"
                                    }`}
                                    onClick={() =>
                                        active
                                            ? openDeck(
                                                  elm._id,
                                                  targetReaderType
                                              )
                                            : null
                                    }
                                    title={elm.name}
                                >
                                    <List.Content verticalAlign="middle">
                                        <Div float-left middle big>
                                            <CompletionIcon
                                                completed={targetCompletion}
                                                icon={
                                                    deckFormats[
                                                        targetReaderType
                                                    ]?.icons[0] ||
                                                    deckFormats.default.icons[0]
                                                }
                                            />
                                        </Div>
                                        <Div nudgeLeft inline>
                                            {text_truncate(elm.name, 24)}
                                        </Div>
                                        <Div inline float-right>
                                            <Icon
                                                name={
                                                    elm.completion === 100
                                                        ? "check circle"
                                                        : "chevron right"
                                                }
                                            />
                                        </Div>
                                    </List.Content>
                                </List.Item>
                            );
                        })}
                    </List>
                </Div>
            </Div>
        </Div>
    );
}
