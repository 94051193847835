// Import from NPM
// -------------------------------------
import React, { useState } from "react";

// Import from Config
// -------------------------------------
import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

export default function IsolatedLandscape(props) {
    const { mainPanel, rightPanel, fullScreen } = props;

    const [showAltPanel, setShowAltPanel] = useState(false);

    function renderRightPanel() {
        if (showAltPanel) {
            return (
                <Div fullht>
                    {rightPanel.secondary.component}
                    <Div
                        center-align
                        className="alt-flip-button"
                        onClick={() => setShowAltPanel(false)}
                    >
                        See {rightPanel.primary.btnText}
                    </Div>
                </Div>
            );
        } else {
            return (
                <Div fullht>
                    {rightPanel.primary.component}
                    <Div
                        center-align
                        className="alt-flip-button"
                        onClick={() => setShowAltPanel(true)}
                    >
                        See {rightPanel.secondary.btnText}
                    </Div>
                </Div>
            );
        }
    }

    // ========================= Render Function =================================
    if (fullScreen) {
        return (
            <Div fullht className="single-course player-view" layer={-1} fluid>
                <Div relative float-left rounded compact>
                    {mainPanel}
                </Div>
            </Div>
        );
    } else {
        return (
            <Div className="single-course player-view" layer={-1} fluid>
                <Div className="left-panel" relative float-left fullht>
                    <Div
                        wd="30px"
                        float-right
                        fullht
                        style={darkStyle(settings.colors.main, "to top")}
                    />
                </Div>
                <Div fullht float-left className="main-panel-viewport">
                    <Div
                        className="main-panel"
                        relative
                        nopad
                        centered
                        rounded
                        fullht
                    >
                        {mainPanel}
                    </Div>
                </Div>
                {rightPanel !== null && (
                    <Div
                        className="right-panel"
                        relative
                        float-left
                        rounded
                        fullht
                        padTop
                    >
                        {renderRightPanel()}
                    </Div>
                )}
            </Div>
        );
    }
}
