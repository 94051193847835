import React, { useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getPointsDelta } from "@utilities/helpers";
import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import Leaderboard from "@components/Leaderboard.react";

export default function LeaderboardReader(props) {
    const { deck, currentState, recordAttempt } = props;
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta:
                currentState && deck.points
                    ? getPointsDelta(currentState, deck.points)
                    : 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    // ========================= Render Function =================================
    return (
        <Div fluid maxWd="400px" centered smoke rounded center-align>
            <Leaderboard
                leaderboardData={_.map(deck.content, (l, idx) => {
                    if (l.name) {
                        const nameSplit = l.name.split(" ");
                        return {
                            _id: "leader-" + idx,
                            first_name: nameSplit[0],
                            last_name: nameSplit[1] || "",
                            points: l.points,
                            image: l.image,
                        };
                    } else {
                        return {
                            _id: "leader-" + idx,
                            first_name: "",
                            last_name: "",
                            points: 0,
                        };
                    }
                })}
                header={t("appLeaderboard.header")}
                isLoading={false}
                isError={false}
                isSuccess={true}
                error={null}
            />
        </Div>
    );
}
