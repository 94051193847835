import { Helmet } from "react-helmet";
import "@styles/layouts.scss";
import { Div, Image } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";
export default function AuthLayout(props) {
    // ========================= Render Function =================================
    return (
        <Div fluid ivory>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Div fluid pageht flex column style={darkStyle(settings.colors.main)}>
                <Div wd="360px" flex white superpad rounded dropShadow>
                    <br />
                    <Div wd="60%" gutter>
                        <Image src="/assets/clientbranding/logo.png" />
                    </Div>
                    <Div clear fitted fluid center-align>
                        <br />
                        {props.children}
                    </Div>
                    <br />
                </Div>
            </Div>
        </Div>
    );
}
