import { useSelector } from "react-redux";
import ReactTable from "react-table-v6";
import _ from "lodash";
import { Loader, Grid } from "semantic-ui-react";
import { Div, Button, } from "@components/Generics.react";
import { Icon } from "semantic-ui-react";
import {
    useGetCourseLearnersPathQuery,
} from "@api/apiV6"
import { useParams } from "react-router-dom";
import MemberAnalytics from "./MemberAnalytics.ract"
import { useState } from "react";
export default function CourseAnalytics(props) {

    const { id } = useParams();
    const user = useSelector((state) => state.auth.user);
    const scope = useSelector((state) =>
        state.auth.user.license.license_type === "Creator" ||
            state.auth.user.license.license_type === "Admin" ||
            state.auth.user.license.license_type === "Inductor" ||
            state.auth.user.license.license_type === "FreeInductor"
            ? "universe"
            : "team");

    const userId = user._id;
    const courseId = id;
    const isPortrait = window.innerHeight > window.innerWidth;
    const [memberAnalytics, setMemberAnalytics] = useState(false);
    const {
        data: audienceData,
        isSuccess: isaudienceDataaSuccess,
        isError: isaudienceDataaError,
        isLoading: isaudienceDataaLoading,
    } = useGetCourseLearnersPathQuery({ userId, courseId, scope });
    let fullColumns = [
        {
            Header: ">",
            accessor: "name",
            maxWidth: 80,
            Cell: ({ row }) => {
                return (
                    <Button primary micro
                        style={{ padding: "3px 15px" }}
                        content={
                            <Icon tiny
                                name="area chart"
                                style={{ margin: -30, fontSize: "6.15em" }}
                            />
                        }
                        onClick={() => {
                            this.openLearnerAnalytics({
                                id: row._original.user_id,
                                name: row._original.name,
                                username: row._original.username,
                            });
                        }}
                        title="View Member Analytics"
                    />
                );
            },
        },
        {
            Header: "Name",
            accessor: "name",
            minWidth: 128,
            style: { textAlign: "center" },
        },
        {
            Header: "accessed",
            id: "accessed",
            accessor: (d) => d.view_attempts,
            Cell: ({ value }) =>
                value === "0" ? "No" : "Yes (" + value + " views)",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "true") {
                    return row[filter.id] !== 0;
                }
                return row[filter.id] === 0;
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="true">Yes</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "completion",
            id: "completion",
            accessor: (d) => d.completion,
            Cell: ({ value }) => (value === null ? "0%" : value + "%"),
            filterMethod: (filter, row) => {
                switch (filter.value) {
                    case "zero":
                        return row[filter.id] === 0;
                    case "low":
                        return row[filter.id] < 40 && row[filter.id] > 0;
                    case "medium":
                        return row[filter.id] < 80 && row[filter.id] > 40;
                    case "high":
                        return row[filter.id] < 100 && row[filter.id] > 80;
                    case "full":
                        return row[filter.id] === 100;
                    case "all":
                    default:
                        return true;
                }
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="zero">0%</option>
                    <option value="low">0-40%</option>
                    <option value="medium">40-80%</option>
                    <option value="high">80-99%</option>
                    <option value="full">100%</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "time",
            id: "time_spent",
            accessor: (d) => d.time_spent,
            Cell: ({ value }) =>
                value === null
                    ? "0 secs"
                    : value < 60
                        ? value + " secs"
                        : Math.round(value / 60, 0) + " mins",
            filterMethod: (filter, row) => row[filter.id] >= filter.value,
            style: { textAlign: "center" },
        },
    ];

    if (isaudienceDataaLoading) {
        return <Loader />;
    } else if (isaudienceDataaSuccess && !isaudienceDataaError) {
        return (
            <Div vapor rounded relaxed={!isPortrait} small>
                <Grid stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column teal >
                            <Div spaced charcol bold nopad left-align huge uppercase  >
                                Audience Analytics
                            </Div>
                        </Grid.Column>
                        <Grid.Column floated='left'>
                            <Div spaced
                                vapor
                                right-align
                            >
                                <Button charcol>
                                    <Icon name='download' />
                                    Download CSV
                                </Button>
                            </Div>

                        </Grid.Column>
                    </Grid.Row>

                </Grid>
                <Grid.Row >
                    <ReactTable
                        data={audienceData}
                        filterable
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined
                                ? String(row[id])
                                    .toLowerCase()
                                    .includes(filter.value.toLowerCase())
                                : true;
                        }}
                        columns={fullColumns}
                        pageSizeOptions={[5, 8]}
                        defaultPageSize={8}
                        className="-striped -highlight"
                    />
                    <MemberAnalytics

                    />
                </Grid.Row>
            </Div>
        );
    } else {
        return <Div>Error</Div>;
    }
}
