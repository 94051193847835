import React from "react";
import { Div } from "@components/Generics.react";
import * as DOMPurify from "dompurify";
import { Divider } from "semantic-ui-react";

export default function ParagraphLine(props) {
    const { paragraph, caption } = props.data;

    // ========================= Render Function =================================
    return (
        <Div superpad big>
            <Div fluid bold zoom>
                {caption}
            </Div>
            <Divider />
            <Div
                fluid
                zoom
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph),
                }}
            />
        </Div>
    );
}
