import _ from "lodash";
import moment from "moment";
import * as CryptoJS from "crypto-js";

export const devMode = process.env.NODE_ENV === "development";

function xOr(a, b) {
    return (a || b) && !(a && b);
}

export const formatDateAgo = (date) => {
    const now = moment();
    const pastDate = moment(date);

    const secondsAgo = now.diff(pastDate, "seconds");
    const minutesAgo = now.diff(pastDate, "minutes");
    const hoursAgo = now.diff(pastDate, "hours");
    const daysAgo = now.diff(pastDate, "days");

    if (secondsAgo < 60) {
        return `${secondsAgo} second${secondsAgo > 1 ? "s" : ""} ago`;
    } else if (minutesAgo < 60) {
        return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
    } else if (hoursAgo < 24) {
        return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    } else {
        return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
    }
};

export const findDeep = (items, children, key, value) => {
    if (!items) {
        return;
    }

    for (const item of items) {
        // Test current object
        if (item[key] === value) {
            return item;
        }

        // Test children recursively
        const child = findDeep(item[children], children, key, value);
        if (child) {
            return child;
        }
    }
};

export const gradient = (color, direction, flipper) => {
    if (flipper == null) flipper = false;
    return xOr(lightOrDark(color) === "dark", flipper)
        ? `linear-gradient(${
              direction == null ? "to bottom right" : direction
          },rgba(0,0,0,0.2),rgba(0,0,0,0.8)),${color}`
        : `linear-gradient(${
              direction == null ? "to bottom right" : direction
          },rgba(255,255,255,0.2),rgba(255,255,255,0.8)),${color}`;
};

export const darkStyle = (color, direction = "to top left") => {
    return {
        background: gradient(color, direction, lightOrDark(color) !== "dark"),
        color: "#ffffff",
    };
};
export const lightStyle = (color, direction = "to top left") => {
    return {
        background: gradient(color, direction, lightOrDark(color) !== "light"),
        color: "#000000",
    };
};
export const primaryStyle = (colors, direction) => {
    return {
        background: gradient(colors?.primary, direction, false),
        color: lightOrDark(colors?.primary) === "dark" ? "#ffffff" : "#000000",
    };
};
export const secondaryStyle = (colors, direction) => {
    return {
        background: gradient(colors?.primary, direction, true),
        color: lightOrDark(colors?.primary) !== "dark" ? "#ffffff" : "#000000",
    };
};

export const lightOrDark = (color) => {
    let r, g, b, hsp;
    if (color == null) color = "#ff0000";
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +(
            "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
        return "light";
    } else {
        return "dark";
    }
};

export const snaked = (phrase) => phrase.split(/ /g).join("_").toLowerCase();

export const text_truncate = function (str, length, ending) {
    if (str == null) {
        str = "";
    }
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = "...";
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};

export const parsePx = function (val) {
    return parseInt(val.split("px")[0], 0);
};

export const getPointsDelta = (currentState, deckPoints, score) => {
    let currentPoints = currentState?.points || 0;
    return Math.max(
        0,
        score
            ? (score * deckPoints) / 100 - currentPoints
            : deckPoints - currentPoints // Add only the delta amount it has increased. Do not decrease points.
    );
};

export const getHistory = (pastHistory, timeSpent, addAttempt, addSession) => {
    function buildHistory(unitHistory) {
        let newHistory = {
            timeSpent: (unitHistory?.timeSpent || 0) + timeSpent,
        };
        if (addAttempt || unitHistory?.viewAttempts)
            newHistory.viewAttempts =
                (unitHistory?.viewAttempts || 0) + addAttempt ? 1 : 0;
        if (addSession || unitHistory?.sessions)
            newHistory.sessions =
                (unitHistory?.sessions || 0) + addSession ? 1 : 0;
        return newHistory;
    }

    let currentHistory = Object.assign(
        {
            f: {},
            y: {},
            q: {},
            m: {},
            w: {},
        },
        _.cloneDeep(pastHistory)
    );
    let eventDate = new Date();

    // Select the appropriate History keys to update
    //-----------------------------------------------
    const fyStartQtr = 2;
    let month = eventDate.getMonth() + 1; //months from 1-12
    let quarter = Math.ceil(month / 3);
    let year = eventDate.getFullYear();
    let finYear = quarter < fyStartQtr ? year : year + 1;
    let startDate = new Date(year, 0, 1);
    let days = Math.floor((eventDate - startDate) / (24 * 60 * 60 * 1000));
    var week = Math.ceil(days / 7);

    currentHistory.f[finYear] = buildHistory(currentHistory.f[finYear]);
    currentHistory.y[year] = buildHistory(currentHistory.y[year]);
    currentHistory.q[year + "Q" + quarter] = buildHistory(
        currentHistory.q[year + "Q" + quarter]
    );
    currentHistory.m[year + "M" + month] = buildHistory(
        currentHistory.m[year + "M" + month]
    );
    currentHistory.w[year + "W" + week] = buildHistory(
        currentHistory.w[year + "W" + week]
    );

    return currentHistory;
};

export const getTimeSpent = (startTime) => {
    let endTime = _.now();
    let timeSpent = Math.abs(endTime - startTime);
    return Math.round(timeSpent / 1000);
};

export const getSlideImage = (image) =>
    checkImage(image, function (exists) {
        if (exists) {
            return image;
        } else {
            return "/assets/images/configurable/missing.jpg";
        }
    });

export const getImage = (image) =>
    checkImage(image, function (exists) {
        if (exists) {
            return image;
        } else {
            return "/assets/images/configurable/missing.jpg";
        }
    });

function checkImage(url, callback) {
    var img = new Image();
    img.onload = function () {
        callback(true);
    };
    img.onerror = function () {
        callback(false);
    };
    img.src = url;
}
export const includeScript = (path, cb) => {
    var node = document.createElement("script"),
        okHandler,
        errHandler;
    node.src = path;
    okHandler = function () {
        this.removeEventListener("load", okHandler);
        this.removeEventListener("error", errHandler);
        cb(node);
    };
    errHandler = function (error) {
        this.removeEventListener("load", okHandler);
        this.removeEventListener("error", errHandler);
        cb("Error loading script: " + path);
    };
    node.addEventListener("load", okHandler);
    node.addEventListener("error", errHandler);
    document.body.appendChild(node);
};

export function isStringifiedJSON(str) {
    try {
        const parsedJSON = JSON.parse(str);
        return _.isObject(parsedJSON);
    } catch (error) {
        return false;
    }
}

// Function to convert JSON to a Blob
const createBlobFromJSON = (jsonObject) => {
    const jsonString = JSON.stringify(jsonObject, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    return blob;
};

// Function to download the JSON Blob as a file
export function downloadJSONAsFile(jsonObject, fileName) {
    const blob = createBlobFromJSON(jsonObject);
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
}

export function handleDownloadClick(readerType) {
    // Construct the URL of the file in the public folder
    const fileUrl = `/assets/samples/${readerType}.qdf`;

    // Create an anchor (a) element to trigger the download
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = `${readerType}.qdf`; // Specify the desired filename

    // Trigger a click event to initiate the download
    a.click();
}

const secretKey = process.env.REACT_APP_SECRET_KEY;
export const encrypt = (plainText) => {
    const cipherText = CryptoJS.AES.encrypt(plainText, secretKey).toString();
    return cipherText;
};

export const decrypt = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    const plainText = bytes.toString(CryptoJS.enc.Utf8);
    return plainText;
};

export function moveElement(arr, index, direction) {
    if (index < 0 || index >= arr.length) {
        return arr; // Index out of bounds, return the original array.
    }

    if (direction === "up" && index > 0) {
        const temp = arr[index];
        arr[index] = arr[index - 1];
        arr[index - 1] = temp;
    } else if (direction === "down" && index < arr.length - 1) {
        const temp = arr[index];
        arr[index] = arr[index + 1];
        arr[index + 1] = temp;
    }

    return arr;
}
