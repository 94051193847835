export const learnerFaqTag = "Learner Queries";
export const supportSchema = {
    ticketCategory: [
        "System down",
        "App Access issues",
        "Login issues",
        "Communication issues",
        "Deck completion issues",
        "Enrollment issues",
        "Reporting issues/Analytics",
        "Role related issues",
        "Completion reset issues",
        "Social Feed issues",
        "User addition issues",
        "Creation issues",
        "Role-related issues",
        "Other issues",
    ],
    problemCategory: [
        "Technical problem",
        "Functional problem",
        "Feature Request",
        "UI/UX changes",
    ],
};

export const faqSchema = [
    {
        question: "How do I add content on my page?",
        answer: "To add content on your page you need to go to the content section tab from the navigation bar, refer to 'How do I add content' tutorial video in QuoDeck. If you are already on the page, click on the Add Content button, you will see a dropdown with various content formats, on the left panel you will see page flow where you can add 12 different content pieces. If you want to edit the content name and description please select the piece for which you want to change it by clicking on the respective box and you will be able to edit the same. Also, if you want to edit the content then you can click on the edit icon in the middle of the page to modify your content.",
    },
    {
        question: "Can I edit the page which I created earlier?",
        answer: "Yes, you can edit the page created before only when you launch the page for your current audience. You will not be able to edit or add content if the page is launched. After unpublishing the page your audience will not be able to access it, which is when you can make any changes you want. Once you have edited you can launch your page again for which you can send a new mail invite to your audience. Before editing, check if your page is sequential or not and also download all the reports before unluanching the page.",
    },
    {
        question: "How can I add different formats of content on my page?",
        answer: "To add content go to the content tab on yur page and click on the add content button. Mainly there are 5 types of content that are available as well as Gamification.The content types are as follows: 1.Document(PDF), 2 Video(Youtube/Vimeo), 3.Quiz, 4.Survey, 5.Game, 6. Gamification for adding rewards and badges in your course content. Choose the content format you would like to add and click on save.",
    },
    {
        question: "How can I invite my audience to the page?",
        answer: "There are 2 ways to invite your audience - one way is to go to the audience tab and click on the invite your user, one can enter the user's name and email ID and an invite will be sent to their email ID. If you would like to bulk invite users, click on the upload users list and download the sample excel file, fill out the sample excel file with your user details and upload it by using the choose file button, your users will receive an invite email. You can choose to write a customised message to add to your invite email in both the above cases by adding a subject line as well as a custom message and then clicking on save message in the manage notification section.",
    },
    {
        question: "How can I create a game on my page?",
        answer: "Click on the Add Topic tab under Page Flow, then click the add content button, and finally click the Launch a Game button.There you can download the Sample Quiz File to enter in your quiz in the required format. Once you have updated the Quiz File click on Choose file and upload it. Select one of the Game Tempaltes from below and click on Save.",
    },
    {
        question: "How can I create a quiz on my page?",
        answer: "Click on the Add Topic tab under Page Flow, then click the add content button, and finally click the Host a quiz button.There you can download the Sample Quiz File to enter in your quiz in the required format. Once you have updated the Quiz File, click on Choose file and upload it. Select the Passing Percentage of the Quiz as well as the Time Limit(Seconds). Finally add the maximum points a user can earn in the Quiz in the Max Points and click on Save.",
    },
    {
        question: "How can I create a badge on my page?",
        answer: "Click on the Add Topic tab under Page Flow, then click the add content button, and finally click the Gamification button. You will then have the option to Issue a Badge. Select the badge you would like to give your audience in your choice of colours, fill in the name of the Badge along with a description and the amount of points required to earn the badge and click on Save.",
    },
    {
        question: "How can I reward my audience?",
        answer: "Click on the Add Topic tab under Page Flow, then click the add content button, and finally click the Gamification button.You will then have the option to Offer a reward. Fill in the Name of the reward along with what the reward will be and the person to contact in order to collect the reward, optionally add an image of the reward and click on Save.",
    },
    {
        question: "How can I create a survey on my page?",
        answer: "Click on the Add Topic tab under Page Flow, then click the add content button, and finally click the Conduct a Survey button.There you can download the Sample Survey File to enter in your quiz in the required format. Once you have updated the Survey File, click on Choose file and upload it and click on Save.",
    },
    {
        question: "Who can Access page?",
        answer: "Users, who are invited on the page via a mail invite can click on the link provided in the mail to get access of your page.",
    },
    {
        question: "How do I check how many Users I have added to my page?",
        answer: "Login in to your QuoDeck account, you will be able to view the number of users you have added by looking in the top right corner of your screen next to the Upgrade button. If you have added the maximum number of users but would like to add more ,click on the upgrade button and upgrade your plan or click on Contact us in case you're on the Pro plan.",
    },
    {
        question: "How can I check my Audience activity this month?",
        answer: "Go to the Analytics tab on the left bar of your screen,click on the Dashboard tab there you can view your audience across different metrics.",
    },
    {
        question: "How can I view my Quiz Analytics?",
        answer: "Go to the Analytics tab on the left bar of your screen,click on the Quizzes tab,choose the Quiz for which you wish to view Analytics,below you can filter through -Competition Status,Member Responses,Question Responses.",
    },
    {
        question: "How can I view my Survey Analytics?",
        answer: "Go to the Analytics tab on the left bar of your screen,click on the Surveys tab,choose the Survey for which you wish to view Analytics,below you can filter through -Competition Status,Member Responses,Question Responses.",
    },
    {
        question: "How can I move a certain topic on the content page around?",
        answer: "Go to the Content Tab and go to the topic you wish to move ,to the right of the name of that topic there are two arrows, you can use those two arrows to move the selected topic up or down with respect to your other topics.",
    },
    {
        question: "How can you Launch your page?",
        answer: "Go to the Audience tab,there you will have the option to invite your audience,once you have invited your audience, you can Launch your page. In order to do this click on the toggle button that says Launch your page.",
    },
    {
        question: "How can I view Analytics of a specific user?",
        answer: "To view analytics for a particular user, navigate to the Audiences tab and enter the user's name in the search bar. Next to their name, there should be a graph icon that you can click on to access their individual analytics.",
    },
    {
        question: "What happens when you Unpublish your page?",
        answer: "When you Unpublish your page you will be able to freely modify it,also please note that all progress by your audience will be reset, please ensure you download all reports before unpublishing and your audience will have to start everything from scratch when you relaunch it.",
    },
    {
        question: "How can I create a story?",
        answer: "To create a story using the Advanced Authoring tool, start by going to the Content Tab and clicking on the add content button. From there, select Advanced Authoring and choose the option to create a story. This will provide you with access to the Advanced Authoring tool, which you can use to create your story.",
    },
    {
        question: "How to bulk delete users?",
        answer: "Go to the Audience tab, from there you can select the users you want to remove by clicking on the checkbox next to their name,once you have selected the users you would like removing, click on the Remove Selected Members button.If you would like to remove all your members select the checkbox next to the Name and click on Remove Selected Members.",
    },
    {
        question:
            "Hello is it possible to buy 1 code, and at later time buy more codes to upgrade my plan ?",
        answer: "You can begin using QuoDeck with a single code and purchase additional codes at a later time. However, it's important to note that this is a limited time offer, so we encourage you to take advantage of it while it lasts.",
    },
    {
        question:
            "Do you have plans to use GPT-4 engine in generating courses, games and quizzes?",
        answer: "Currently under development for the Agency version, once it is scaled, stable and uniformly legal globally, we will consider moving it to the DIY self-enrolment plans",
    },
    {
        question: "How do I add users to QuoDeck?",
        answer: "There are 2 ways to Add your audience - one way is to go to the audience tab and click on the invite your user, one can enter the user's name and email ID, a user will be added in the system and will reflect in the table below. If you would like to add bulk users, click on the upload users list and download the sample excel file, fill out the sample excel file with your user details and upload it by using the choose file button, your users will get added in the system.",
    },
    {
        question:
            "If I buy a new code here, can it upgrade my previous account? Or will it make me a new account?",
        answer: "If you buy a new code you either choose to stack that code which will upgrade your account to a higher tier, based on the number of codes you have purchased, you can also choose to use that code with another email ID to create a new account.",
    },
    {
        question: "Is there a possibility to create a subaccount?",
        answer: "The people you add to the system will all receive an invitation to become your audience; there is only one Admin per subscription.",
    },
    {
        question:
            "Can the platform interface, searches and results be in Spanish?",
        answer: "Yes, please let us know which language you would prefer and we will enable the same in 15 days, we would require help in any language correction",
    },
    {
        question: "Does this deal include AI?",
        answer: "Our Agency product uses AI extensively in the backend, if you need anything specifically please let us know and we will incorporate the same for you",
    },
    {
        question: "When will this offer end?",
        answer: "This offer is a limited time deal, so we encourage you to take advantage of it while it lasts.",
    },
    {
        question: "How to get the Plus discount? It's not working",
        answer: "I would reach out to AppSumo. I don't control the membership tiers on AppSumo.",
    },
    {
        question: "Is White Labelling being offered in this app?",
        answer: "We do offer white labeling for your page, which can be accessed by navigating to the Setup Tab. You can customize your page name and put your company logo, along with the option to choose your page, menu and text colors as well as a Page Image.",
    },
    {
        question:
            "How can I get more users? Is there a way for users to earn badges and rewards?",
        answer: "Number of users depends upon the code you buy.\nAll of the badges and rewards you have added to your page are accessible to all of your users/audience. ",
    },
    {
        question:
            "I redeemed my Code but a popup showed asking me to redeem more, what should I do?",
        answer: "A popup will show up every time you redeem a code. If you would like to go the platform, please click on either Go to QuoDeck button, or if you have more codes click on the Redeem More Codes button.",
    },
    {
        question:
            "How can I demo your App without giving you my credit card details?",
        answer: "You can go to https://demo.quodeck.com/ and click on try for free to get a 14 day free trial of QuoDeck. You will not have to enter any CC information to access this Free Trial",
    },
    {
        question: "Can I buy this product from AppSumo once the deal ends?",
        answer: "I don't think so, but I don't control that. You will need to reach out to AppSumo, as our product was listed as a temporary offer there.",
    },
    {
        question: "Do you have any explanatory videos for QuoDeck?",
        answer: "Yes, we do have tutorial videos for each of our product sections. \nPlease find the link for the same attached below\nSetup your page - https://youtu.be/F3G8UyneluI\nAdd your content - https://youtu.be/RN7KGTtFBYI\nManage your audience -https://youtu.be/CpiDPqZ56TY\nView page analytics - https://youtu.be/AxaIUDNsssk",
    },
    {
        question:
            'Can I recruit "course creators" to create courses on my platform? Would they have their own sub-accounts?',
        answer: "This not a course creation platform, and thus creating and selling courses is not possible on this platform, but you can always recruit a content creator to create your content marketing content for your page.\nNote- This is an one Admin subscription plan thus the content can only be added to the admin ID only.",
    },
    {
        question:
            "Do you have an affiliate feature setup. Example, if I want affiliate marketers to share my course, and pay them a %?",
        answer: "No, not at the moment. This has been incorporated into our product roadmap",
    },
    {
        question:
            "Can I make the quizzes mandatory with a mandatory pass level i.e. 80% etc.?",
        answer: "Yes, you can absolutely make the quiz with a mandatory passing %, and a user will not be allowed to proceed unless they complete the quiz.",
    },
    {
        question:
            "Can I ask for feedback/ have the student do a survey post a course? Our regulatory body requires this.",
        answer: "Of course, you may conduct a survey with your audience; one of the content forms we offer is survey, with access to a variety of question formats that are excellent for gathering feedback and conducting research.",
    },
    {
        question:
            "Is there a way for the instructor to get a summary of the quiz score of the students? This is required by our reg body.",
        answer: "Yes, our Analytics section provides a detailed analysis of all content formats, and for quizzes, you can get analytics on completion, time spent,�member-specific responses, and question-specific responses.",
    },
    {
        question: "What is the max number of students?",
        answer: "This is determined by the code you purchase; each code allows for a different set of users in the system.",
    },
    {
        question: "Can I allow unlimited access to users?",
        answer: "The page will only be viewable to users you invite via email. The number of users you can invite depends on the plan you are on.",
    },
    {
        question:
            "Can the quiz serve questions randomly from a test bank of questions? i.e. serves 6 random multiple choice questions from a test bank of 15 total questions",
        answer: "Yes, all of our quiz questions are served at random since we believe in spaced repetition. In a gamified quiz, the system will also repeat the questions.",
    },
    {
        question:
            "Does it support the Arabic language? Will it support the right-to-left text?",
        answer: "No, at the moment. This we have kept in our product roadmap, will be taken up in Q3 FY2023",
    },
    {
        question:
            "Is it possible to get to the place where I left off in a course when I go out and enter again? Is this saved?",
        answer: '"Yes, you can continue accessing the content from where you left it.\nNote: If the administrator has made modifications to the content, you may need to restart it or resync the platform."',
    },
    {
        question:
            "Is there a way to test drive the platform, especially the course creation without needing to purchase here or enter my CC information on your website for the trial?",
        answer: "You can go to https://demo.quodeck.com/ and click on try for free to get a 14 day free trial of QuoDeck. You will not have to enter any CC information to access this Free Trial",
    },
    {
        question:
            "Do you have options: 1. Conduct Live online training via zoom / Teams Meeting? 2. Conduct Online Quiz Test and Exams?",
        answer: "Currently we don't offer a live meeting option; however, we have included it on our product roadmap.\nYou can certainly conduct online quizzes or exams using QuoDeck",
    },
    {
        question: "Do you have a demo site we can have a look at?",
        answer: "We don't have a demo site. You can create a free account by directly going to the website, this will get you access to a 14 day free trial. We do not require your CC details for you to start your free trial, hope that is a good alternative.",
    },
    {
        question:
            "Can you host videos on the platform or do they need to be uploaded in YouTube/Vimeo?",
        answer: "You cannot host Videos on the platform as of now, you will have to upload them on YouTube or Vimeo and then paste their link on the Add a Video button in the Add content dropdown. Note that you can choose to keep these videos private on YouTube or Vimeo if you do not wish the general public to view it, only people with the link of the video will be able to view it.",
    },
    {
        question: "Why should anybody buy your product on AppSumo?",
        answer: "QuoDeck is offering a lifetime validity deal on AppSumo that provides the same product features as our Pro plan, but at a very competitive price. On our site, the Pro plan with 500 users costs $129/month, whereas on AppSumo, you can get the same features and user limit for lifetime access at just $147. This is an incredible opportunity to save big while enjoying the same benefits.",
    },
    {
        question: "Server page is not opening to redeem purchase. any idea?",
        answer: "Please try to connect to a better network or try disabling your browser's cache and reloading the URL as you may be experiencing network or cache issues.",
    },
    {
        question: "Will you be making an  IOS app ?",
        answer: "At the moment, you have access to QuoDeck through a microsite.\nApps for Android and iOS can be made on demand.",
    },
    {
        question:
            "I want to know the list of action for rewards. Can my audience receive rewards for doing some basic task like completing a content piece?",
        answer: "Yes, In our system, you can gain points by taking quizzes, playing games, or collecting badges. The longer you play a game, the more points you'll accumulate, which will appear on the leaderboard.\nNote - It's upon you where you want to put badges rewards and certificates for your audience throughout their journey.",
    },
    {
        question:
            "1) Can I purchase the product right now and activate it without a custom domain?\n\n2) Will I be able to add my own domain later?\n\n3) Can I add a different domain for each landing page?",
        answer: "1) Yes, you will get the feature of custom domain if you purchase three or more codes. Our team will get in touch with you to add your custom domain to the platform, you can choose to add it at a later date.\n\n2)Yes you can choose to add your own domain later\n\n3) No, you can add only one domain for your Page",
    },
    {
        question:
            "Is it possible to get a custom plan?\nDo you make custom pricing? ",
        answer: "We do not have custom plans.\n\nPlease choose from the existing plans, that suits your need.\n\nYou can start with a Single code, try it and when you are happy you can buy the next code or two and upgrade if you need more features or limits. You can get in touch with our sales team through the Contact Us button on the website if you would like to opt for the Agency plan.",
    },
    {
        question: "Is there a public roadmap ?",
        answer: "We don't provide public access to the product roadmap. For roadmap related queries, please contact us at info@quodeck.com.",
    },
    {
        question:
            "I see on your website 3 types of accounts possible with: Free, Essential, and Pro.\n\nWhich one is this Lifetime deal related to?",
        answer: "The Plans mentioned on the website are not Lifetime Deals, They can be purchased on either a monthly or yearly basis.",
    },
    {
        question:
            "I got the AppSumo code but didn't find anywhere to enter it on your registration page. Can you help?",
        answer: "After completing the registration process, Sumo-lings will be directed to a page where they can redeem their codes for QuoDeck. Simply enter your AppSumo code in the input box and click the Redeem button twice to successfully redeem it. Once you have redeemed your code, you will receive a pop-up asking if you would like to redeem more codes or go to your QuoDeck page. Access to QuoDeck is granted once your codes have been redeemed. If you wish to redeem more codes at a later time, simply click on the upgrade button and the AppSumo code redemption page will appear. You can redeem more AppSumo codes or purchase additional ones by clicking on the Go to AppSumo button.",
    },
    {
        question:
            "There is an option to buy three codes!\n\nMay I buy all 3 codes and not have to renew for 3 years or until 3 years later?",
        answer: "If you buy 3 codes you will get the Tier 3 plan with 500 users,60 content pieces, Custom Domain, Premium Themes, and Premium Games which will have Lifetime validity.",
    },
    {
        question: "Can I buy your lifetime offer once it ends on AppSumo",
        answer: "After the expiration of the AppSumo Offer, you will not be able to purchase it unless we re-introduce the offer. However, if you are an AppSumo Plus member, you will have an additional 48 hours to avail the offer. Beyond this period, you can still access QuoDeck and choose from the standard pricing plans or any other ongoing offers.",
    },
];
