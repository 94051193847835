// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import {
    useGetProgramBuilderQuery,
    useUpdateProgramMutation,
    useUpdateCourseMutation,
    clientAssetsPath,
} from "@api/apiV6";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { toast } from "react-toastify";

import { Div, Image, Button } from "@components/Generics.react";
import "@styles/navbars.scss";

import { Loader, Checkbox, Icon } from "semantic-ui-react";
import PreviewLayout from "@layouts/PreviewLayout.react";
import { NavLink } from "react-router-dom";

export default function ProgramView(props) {
    const { id, type } = props;
    const { data: program, isSuccess } = useGetProgramBuilderQuery(id);
    const [updateProgram] = useUpdateProgramMutation();
    const [updateCourse] = useUpdateCourseMutation();
    const features = useFeatures();
    const { t } = useTranslation("common");

    const courseList = isSuccess
        ? _.orderBy(program.node_list, ["priority"], ["desc"])
        : [];

    async function setPriority(index, direction) {
        const thisCourse = courseList[index];
        const otherIndex = direction === "down" ? index + 1 : index - 1;
        const otherCourse = courseList[otherIndex];

        if (otherCourse.priority === thisCourse.priority) {
            await updateCourse({
                id: direction === "down" ? otherCourse._id : thisCourse._id,
                priority: thisCourse.priority + 1,
            });
        } else {
            const thisPriority = thisCourse.priority;
            const otherPriority = otherCourse.priority;
            await Promise.all([
                updateCourse({ id: thisCourse._id, priority: otherPriority }),
                updateCourse({ id: otherCourse._id, priority: thisPriority }),
            ]);
        }
        toast(t("builder.program.updatePr"));
    }

    // ========================= Render Function =================================
    return isSuccess ? (
        <Div flex noWrap spaceAround fullht basepad>
            <Div superpad txtCharcoal fullht autoOverflowY>
                <Div uppercase>
                    {t(`components.edit`)} {type}:
                </Div>
                <Div massive altText uppercase gutter>
                    {program.name}
                </Div>
                <Div>
                    <Div wd="200px" float-left padRight>
                        <Image
                            src={`${clientAssetsPath}/uploads/images/program/${
                                program?._id
                            }.${program?.image
                                ?.split(".")
                                .pop()
                                .toLowerCase()}`}
                            snubbed
                        />
                    </Div>
                    <Div padded>
                        <Div gapBottom>{program.description}</Div>
                        <NavLink to={`/builder/programs/${program?._id}/setup`}>
                            <Button
                                primary
                                content={t("builder.program.editSettings")}
                            />
                        </NavLink>
                    </Div>
                </Div>
                <Div clear maxWd="460px">
                    <br />
                    <Div rimmed ash snubbed>
                        {features.programs.hasSequential && (
                            <Div rimmed snubbed gutter>
                                <Div gapBottom bold>
                                    {t("builder.program.sequential")}
                                </Div>
                                <Checkbox
                                    toggle
                                    label={
                                        program.sequential
                                            ? t("builder.program.seqProgram")
                                            : t("builder.program.openProgram")
                                    }
                                    checked={program.sequential}
                                    onChange={() =>
                                        updateProgram({
                                            id: program._id,
                                            sequential: !program.sequential,
                                        })
                                    }
                                />
                            </Div>
                        )}
                        <Div medpad smoke snubbed>
                            <Div gapBottom bold rimmed>
                                {t("builder.program.setOrder")}:
                            </Div>
                            {_.map(courseList, (course, idx) => (
                                <Div
                                    white
                                    rimmed
                                    flex
                                    spaceBetween
                                    snug
                                    key={`prognode-${idx}`}
                                >
                                    {course.name}
                                    <Div big clickable>
                                        {idx > 0 && (
                                            <Icon
                                                name="chevron up"
                                                onClick={() =>
                                                    setPriority(idx, "up")
                                                }
                                            />
                                        )}
                                        {idx < program.node_list.length - 1 && (
                                            <Icon
                                                name="chevron down"
                                                onClick={() =>
                                                    setPriority(idx, "down")
                                                }
                                            />
                                        )}
                                    </Div>
                                </Div>
                            ))}
                        </Div>
                    </Div>
                    <br />
                </Div>
            </Div>
            <PreviewLayout url={`/programs/${id}`} />
        </Div>
    ) : (
        <Loader active inverted />
    );
}
