import React, { useMemo } from "react";
import _ from "lodash";
import { useGetArticlesQuery } from "@api/apiV6";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Div } from "@components/Generics.react";
import "@styles/components.scss";
import ArticleCard from "@components/ArticleCard.react";
import { learnerFaqTag } from "@schemas/supportSchema";

export default function HelpArticles(props) {
    const { isSuccess } = useGetArticlesQuery();
    const articles = useSelector((state) => state.articles);
    const { t } = useTranslation("common");

    const helpArticles = useMemo(
        () =>
            isSuccess
                ? _.orderBy(
                      _.filter(articles?.list, (art) => {
                          return _.map(art.tags, "name").includes(
                              learnerFaqTag
                          );
                      }),
                      ["priority", "uniques"],
                      ["desc", "desc"]
                  ).slice(0, 10)
                : [],
        [articles, isSuccess]
    );

    // ========================= Render Function =================================
    return (
        <Div nopad fullht>
            <Div big padTop gutter bold uppercase>
                {t("helpdesk.helpArticles")}:
            </Div>
            {isSuccess && (
                <Div autoOverflow padBottom ht="calc(100% - 45px)">
                    {_.map(helpArticles, (article) => {
                        return (
                            <ArticleCard
                                key={`article-right-${article?._id}`}
                                article={article}
                                feedStyle
                            />
                        );
                    })}
                </Div>
            )}
        </Div>
    );
}
