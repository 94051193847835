import React from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { Image } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import MasterLayout from "@layouts/MasterLayout.react";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";
import SearchField from "@components/SearchField.react";
import { darkStyle, gradient } from "@utilities/helpers";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function AppHome(props) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const stages = settings.gamification.stages;
    const showStage = _.keys(stages).length > 1;
    const userStage = settings.home[user.stage || stages.length];
    const pageContent = (
        <Div
            basepad
            fullht
            style={{ background: gradient("#dddddd", "to top") }}
        >
            {userStage && !userStage.hideSearch && (
                <Div fit-content centered>
                    <SearchField />
                </Div>
            )}
            <br />
            {showStage && (
                <Div
                    topRounded
                    relaxed
                    charcoal
                    large
                    wd="240px"
                    centered
                    center-align
                    uppercase
                    style={darkStyle("#666666", "to top")}
                >
                    {`${t("home.stage")} ${t(`stages.${user.stage}`)}`}
                </Div>
            )}
            <Div relative fluid style={{ height: "0", paddingBottom: "133%" }}>
                {userStage &&
                    _.map(userStage.images, ({ src, coords }, idx) => {
                        return (
                            <NavLink
                                to={t(`home.links.${src}`)}
                                key={`homeelm-${idx}`}
                            >
                                <Image
                                    src={t(`home.images.${src}`)}
                                    style={{
                                        position: "absolute",
                                        left: `${coords[0]}%`,
                                        top: `${coords[1]}%`,
                                        width: `${coords[2]}%`,
                                    }}
                                />
                            </NavLink>
                        );
                    })}
            </Div>
        </Div>
    );
    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={"Welcome"}
            mainPanel={pageContent}
            rightPanelName={t(`rightPanel.recommended`)}
            rightPanel={<RecommendedArticles />}
            rootPage={true}
            hidePanelToggle
        />
    );
}
