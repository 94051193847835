import { Grid, Loader, Segment, Statistic, Icon, StatisticGroup } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";
import React from 'react';
import {
    BarChart,
    Bar,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';
import RadialProgressChart from "@components/charts/RadialProgressChart.react";

export default function CoursePerformance(props) {
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page H',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page H',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Page H',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        
    ];
    const isPortrait = window.innerHeight > window.innerWidth;

    return (
        <Div vapor rounded relaxed={!isPortrait} small>
            <Grid centered>
                <Grid.Row >
                    <Grid.Column teal >
                        <Div charcol bold left-align huge uppercase >
                            ACTIVITY
                        </Div>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row   >
                    <ResponsiveContainer centered width="100%" height="100%">
                        <Div basepad align-left  centered>
                            <BarChart width={800} height={350} data={data}>
                                <Tooltip />
                                <Bar dataKey="uv" fill="#bdbdbd" />
                            </BarChart>
                        </Div>
                    </ResponsiveContainer>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Statistic size="small">
                            <Statistic.Label>
                                <Div large basepad>
                                    Enrolled Team Members
                                </Div>
                            </Statistic.Label>
                            <Statistic.Value>
                                <Icon name="users" />{" "}
                                {/* {progressData?.enrolled_courses} */}
                                30
                            </Statistic.Value>
                           
                        </Statistic>
                        {/* <RadialProgressChart
                            width={200}
                            height={200}
                            color={settings.colors.main}
                            value='30'
                        /> */}
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Statistic size="small">
                            <Statistic.Label>
                                <Div large basepad>
                                    Completed
                                    Team Members
                                </Div>
                            </Statistic.Label>
                            <Statistic.Value>
                                <Icon name="pie graph" />{" "}
                                {/* {progressData?.enrolled_courses} */}
                                70
                            </Statistic.Value>
             
                        </Statistic>
                        {/* <RadialProgressChart
                            width={200}
                            height={200}
                            color={settings.colors.main}
                           // value='70'
                        /> */}
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Statistic size="small">
                            <Statistic.Label>
                                <Div large basepad>
                                    Average
                                    Completion
                                </Div>
                            </Statistic.Label>
                            <Statistic.Value>
                                <Icon name="compass outline" />{" "}
                                {/* {progressData?.enrolled_courses} */}
                                90
                            </Statistic.Value>
                          
                        </Statistic>
                        {/* <RadialProgressChart
                            width={200}
                            height={200}
                            color={settings.colors.main}
                            value='90'
                        /> */}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Div>
    );

}
