import React, { useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, Button } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";
import { ItemArranger } from "@components/ItemArranger.react";

export default function RankThemRight(props) {
    const { data, slideSeq, trackData, deckId, pageNumber, setPageNumber } =
        props;
    const [shuffledItems] = useState(_.shuffle(data.bullets));
    const [arrangedItems, setArrangedItems] = useState(null);
    const [correct, setCorrect] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    function handleChange(items) {
        setArrangedItems(items);
    }

    function trackInteraction() {
        const isCorrect = _.isEqual(data.bullets, arrangedItems);
        setCorrect(isCorrect);
        if (data.feedback != null && data.feedback.length > 0 && data.purpose !== "Provide Insight")
            setShowOverlay(true);
        else setPageNumber(pageNumber + 1);

        if (trackData) {
            trackData({
                correct: isCorrect,
                response: arrangedItems,
            });
        }
        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: slideSeq,
                userId: userId,
                response: arrangedItems,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
    }

    // ========================= Render Function =================================
    return (
        <Div fluid fullht zoom relative superpad>
            <Div big gutter>
                {data.question}
            </Div>
            <Div small italics gutter>
                {t("deck.qdf.rankThem")}
            </Div>
            <Div fluid zoom gutter>
                <ItemArranger items={shuffledItems} onArrange={handleChange} />
            </Div>
            <Button
                primary
                fluid
                content={"Submit"}
                onClick={trackInteraction}
            />
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
