import { Div, Icon } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";
import "@styles/components.scss";
import { text_truncate, darkStyle } from "@utilities/helpers";
import CompletionIcon from "./CompletionIcon.react";

export default function TopicCard(props) {
    const { locIndex, topic, status, clickable, handleClick } = props;

    // ========================= Render Function =================================
    return (
        <Div
            hintShadow
            noOverflow
            clickable
            compact
            white={status === "complete"}
            primary={status === "current"}
            className={`topic-card ${!clickable && "disabled"}`}
            onClick={() => clickable && handleClick(locIndex)}
            style={status === "lastopen" && darkStyle(settings.colors.main)}
        >
            <Div float-left nudgeRight>
                <CompletionIcon
                    completed={topic?.currentState?.completion}
                    percentage={topic?.currentState?.viewedPercentage}
                />
            </Div>
            <Div float-left nudgeLeft className="topic-name">
                {text_truncate(topic.name, 40)}
            </Div>
            <Icon float-right big name="chevron right" />
        </Div>
    );
}
