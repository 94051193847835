// Import from NPM
// -------------------------------------
import React from "react";
import { Div } from "@components/Generics.react";
import { Input } from "semantic-ui-react";

export default function SettingsInput(props) {
    const { deck, setDeck, targetKey, label, isType } = props;
    // ========================= Render Function =================================
    return (
        <Div compact fluid>
            <Input
                fluid
                label={label}
                type={isType}
                value={deck[targetKey]}
                onChange={(e) =>
                    setDeck({
                        ...deck,
                        ...{
                            [targetKey]:
                                isType === "number"
                                    ? Number(e.target.value)
                                    : e.target.value,
                        },
                    })
                }
            />
        </Div>
    );
}
