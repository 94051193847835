import React from "react";
import { Div } from "@components/Generics.react";
import "@styles/readers.scss";

export default function Conversation(props) {
    const { conversation } = props.data;
    return (
        <Div basepad className="conversation">
            {conversation.map((message, index) => (
                <Div
                    key={index}
                    className={`message ${index % 2 === 0 ? "theirs" : "mine"}`}
                >
                    {/* <div className="conversation-avatar">
                        <img src={message.image} alt="Avatar" />
                    </div> */}
                    <div className="message-content">{message.text}</div>
                </Div>
            ))}
        </Div>
    );
}
