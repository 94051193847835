import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { deckFormats } from "@schemas/deckFormats";
import { useTranslation } from "react-i18next";

import {
    useUpdateArticleMutation,
    useGetArticleQuery,
    useGetTagsQuery,
    useGetArticlesQuery,
} from "@api/apiV6";

import { Loader } from "semantic-ui-react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import ArticleSetup from "./panels/ArticleSetup.react";
import ArticleShare from "./panels/ArticleShare.react";

export default function ArticleEditor(props) {
    const { readerType, id, section } = useParams();
    const user = useSelector((state) => state.auth.user);
    const { data: article, isSuccess } = useGetArticleQuery(
        {
            id: id,
            user_id: user._id,
            readerType: deckFormats[readerType].readerType,
        },
        {
            enabled: false,
        }
    );
    const { data: allTags, isSuccess: tagsSuccess } = useGetTagsQuery();
    const { data: allArticles, isSuccess: articlesSuccess } =
        useGetArticlesQuery();
    const [updateArticle] = useUpdateArticleMutation();
    const { t } = useTranslation("common");

    const setupPanel = isSuccess && (
        <ArticleSetup
            article={article}
            updateArticle={updateArticle}
            allArticles={articlesSuccess ? allArticles.blog : []}
        />
    );
    const sharePanel = isSuccess && (
        <ArticleShare
            article={article}
            updateArticle={updateArticle}
            allTags={tagsSuccess ? allTags : []}
        />
    );
    const panelMap = {
        setup: setupPanel,
        share: sharePanel,
        // Add more sections and their respective panels as needed
    };

    // ========================= Render Function =================================
    if (isSuccess)
        return (
            <CreatorLayout
                title={`Editing: ${article.name}`}
                mainPanel={panelMap[section]}
                navName={`Article ${section}`}
                pageCode={"library"}
                rootPage={false}
                editMode={true}
            />
        );
    else return <Loader active>{t("components.loading")}</Loader>;
}
