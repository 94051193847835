// Import from NPM
// -------------------------------------
import React from "react";
import { Icon, Menu, Image, Tab } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "../../config/settings/app.settings";

export default function IsolatedPortrait(props) {
    const { course, mainPanel, rightPanel } = props;

    function tabBtn(icon, name) {
        const footerBtnWd = (window.innerWidth - 154) / 2;
        return (
            <Div
                relative
                wd={`${footerBtnWd}px`}
                className="footer-btn"
                style={darkStyle(settings.colors.main, "to top")}
            >
                <Div className="footer-btn-content">
                    <Icon name={icon} />
                    <Div>{name}</Div>
                </Div>
            </Div>
        );
    }
    const panes = rightPanel && [
        {
            menuItem: (
                <Menu.Item key="main-panel-trigger">
                    <Image
                        src={
                            course?.image ||
                            "/assets/images/configurable/missing_course.jpg"
                        }
                        className="mobile-tab-course-img"
                        style={{
                            background: settings.colors.main?.primary,
                        }}
                    />
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached="top" className="mobile-view-tab">
                    <Div
                        className="main-panel"
                        relative
                        fitted
                        white
                        fullht
                        autoOverflow
                    >
                        {mainPanel}
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="right-panel-trigger">
                    {tabBtn(
                        rightPanel.primary.icon,
                        rightPanel.primary.btnText
                    )}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached="top" className="mobile-view-tab">
                    {rightPanel.primary.component}
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="left-panel-trigger">
                    {tabBtn(
                        rightPanel.secondary.icon,
                        rightPanel.secondary.btnText
                    )}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached="top" className="mobile-view-tab">
                    {rightPanel.secondary.component}
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Div
            className="single-course-mobile player-view"
            layer={-1}
            fluid
        >
            {rightPanel ? (
                <Tab menu={{ attached: "bottom" }} panes={panes} />
            ) : (
                <Div
                    className="main-panel"
                    relative
                    fitted
                    white
                    fullht
                    autoOverflow
                >
                    {mainPanel}
                </Div>
            )}
        </Div>
    );
}
