import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { Input, Image, Tab, Checkbox, Divider } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import ImageUploader from "@components/ImageUploader.react";

import PreviewLayout from "@layouts/PreviewLayout.react";
import { WrapperList } from "@schemas/wrapperSchema";

export default function ContestSetup(props) {
    const { contest, updateContest } = props;

    const [search, setSearch] = useState("");
    const wrapperList = _.filter(_.keys(WrapperList), (template) =>
        template.includes(search)
    );

    const [contestName, setContestName] = useState(contest.name);
    const [contestDescription, setContestDescription] = useState(
        contest.description
    );
    const [contestImage, setContestImage] = useState(contest.image);
    const [imgEditorOpen, setImgEditorOpen] = useState(false);
    const { t } = useTranslation("common");

    function saveContestImage(image) {
        setContestImage(image);
        setImgEditorOpen(false);
    }

    async function saveContest() {
        await updateContest({
            id: contest._id,
            name: contestName,
            image: contestImage,
            description: contestDescription,
        }).unwrap();
        toast(t("components.saved"));
    }

    async function changeWrapper(selectedWrapper) {
        await updateContest({
            id: contest._id,
            wrapper: selectedWrapper,
        }).unwrap();
        toast(t("builder.contest.themeUpdated"));
    }

    async function toggleTiming() {
        await updateContest({
            id: contest._id,
            enable_timing: !contest.enable_timing,
        }).unwrap();
        toast(
            contest.enable_timing
                ? `${t("builder.contest.timed")} ${t("components.disabled")}!`
                : `${t("builder.contest.timed")} ${t("components.enabled")}!`
        );
    }

    async function toggleSequential() {
        await updateContest({
            id: contest._id,
            sequential: !contest.sequential,
        }).unwrap();
        toast(
            contest.sequential
                ? `${t("builder.contest.sequential")} ${t(
                      "components.disabled"
                  )}!`
                : `${t("builder.contest.sequential")} ${t(
                      "components.enabled"
                  )}!`
        );
    }

    async function toggleAutoNotification() {
        await updateContest({
            id: contest._id,
            autoNotification: !contest.autoNotification,
        }).unwrap();
        toast(
            contest.autoNotification
                ? `${t("builder.contest.autoNot")} ${t("components.disabled")}!`
                : `${t("builder.contest.autoNot")} ${t("components.enabled")}!`
        );
    }

    async function toggleReminders() {
        await updateContest({
            id: contest._id,
            notifications: {
                ...contest.notifications,
                ...{ reminders: !contest.notifications.reminders },
            },
        }).unwrap();
        toast(
            contest.notifications.reminders
                ? `${t("builder.contest.reminders")} ${t(
                      "components.disabled"
                  )}!`
                : `${t("builder.contest.reminders")} ${t("components.enabled")}!`
        );
    }

    async function updateContestDate(thisDate, dateType) {
        await updateContest({
            id: contest._id,
            dates: { ...contest.dates, ...{ [dateType]: thisDate } },
        }).unwrap();
        toast(t("builder.contest.accessDtUpdated"));
    }

    async function setPriority(value) {
        await updateContest({
            id: contest._id,
            priority: value,
        }).unwrap();
        toast(t("builder.contest.priorityUpdated"));
    }

    async function updateVintage(days) {
        await updateContest({
            id: contest._id,
            vintage: days,
        }).unwrap();
        toast(t("builder.contest.vintageUpdated"));
    }

    async function updateReminderProps(value, target) {
        await updateContest({
            id: contest._id,
            notifications: { ...contest.notifications, ...{ [target]: value } },
        }).unwrap();
        toast(t("builder.contest.remindersUpdated"));
    }

    const panes = [
        {
            menuItem: t("builder.contest.theme"),
            render: () => (
                <Tab.Pane>
                    <Div ht="400px" autoOverflowY>
                        <Div bold medpad flex spaceBetween>
                            <Div big>{t("builder.contest.selectTheme")}:</Div>
                            <Input
                                icon="search"
                                placeholder={t("components.search")}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Div>
                        <Div clear flex flexStart gapped>
                            {_.map(wrapperList, (wrapper, idx) => {
                                return (
                                    <Div
                                        wd="calc(33.33% - 7.5px)"
                                        key={`wrapper-${idx}`}
                                        float-left
                                        medpad
                                        rounded
                                        clickable
                                        vapor={wrapper !== contest.wrapper}
                                        charcoal={wrapper === contest.wrapper}
                                        onClick={() => changeWrapper(wrapper)}
                                    >
                                        <Image
                                            src={`/assets/images/wrappers/${wrapper}/play_backdrop.png`}
                                        />
                                        <Div uppercase center-align gapTop>
                                            {wrapper.replace(/_/g, " ")}
                                        </Div>
                                    </Div>
                                );
                            })}
                        </Div>
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: t("builder.contest.access"),
            render: () => (
                <Tab.Pane>
                    <Div ht="400px" autoOverflowY>
                        <Div basepad ivory snug>
                            <Div bold big>
                                {t("builder.contest.setSequential")}:
                            </Div>
                            <Div italics gapBottom>
                                {t("builder.contest.sequentialHelp")}
                            </Div>
                            <Checkbox
                                toggle
                                label={t("builder.contest.isSequential")}
                                checked={contest.sequential}
                                onChange={toggleSequential}
                            />
                        </Div>
                        <Div basepad ivory snug>
                            <Div big bold>
                                {t("builder.contest.setPriority")}
                            </Div>
                            <Div italics gapBottom>
                                {t("builder.contest.priorityHelp")}
                            </Div>
                            <Input
                                type="number"
                                value={contest.priority}
                                onChange={(e) => setPriority(e.target.value)}
                            />
                        </Div>
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: t("builder.contest.timing"),
            render: () => (
                <Tab.Pane>
                    <Div bold medpad flex spaceBetween>
                        <Div big>{t("builder.contest.setTiming")}:</Div>
                    </Div>
                    <Div medpad>
                        <Div gutter>
                            <Checkbox
                                toggle
                                label={t("builder.contest.checkTiming")}
                                checked={contest.enable_timing}
                                onChange={toggleTiming}
                            />
                        </Div>
                        {contest.enable_timing && (
                            <Div basepad ivory>
                                <Div flex spaceBetween gapped gutter>
                                    <Div wd="49%">
                                        <Div bold gapBottom>
                                            {t("builder.contest.startDt")}
                                        </Div>
                                        <DatePicker
                                            dateFormat="dd MMMM yyyy h:mm a"
                                            selected={
                                                contest.dates?.start_date &&
                                                moment(
                                                    contest.dates.start_date
                                                ).toDate()
                                            }
                                            onChange={(date) =>
                                                updateContestDate(
                                                    date,
                                                    "start_date"
                                                )
                                            }
                                            showTimeSelect={true}
                                        />
                                    </Div>
                                    <Div wd="49%">
                                        <Div bold gapBottom>
                                            {t("builder.contest.endDt")}
                                        </Div>
                                        <DatePicker
                                            dateFormat="dd MMMM yyyy h:mm a"
                                            selected={
                                                contest.dates?.end_date &&
                                                moment(
                                                    contest.dates.end_date
                                                ).toDate()
                                            }
                                            onChange={(date) =>
                                                updateContestDate(
                                                    date,
                                                    "end_date"
                                                )
                                            }
                                            showTimeSelect={true}
                                        />
                                    </Div>
                                </Div>
                                <Divider horizontal>
                                    {t("components.or")}
                                </Divider>
                                <Div center-align>
                                    <Input
                                        label={t("builder.contest.vintage")}
                                        type="number"
                                        value={contest.vintage}
                                        onChange={(e) =>
                                            updateVintage(e.target.value)
                                        }
                                    />{" "}
                                    {t("components.days")}
                                    <Div gapTop italics gutter>
                                        {t("builder.contest.vintageHelp")}
                                    </Div>
                                </Div>
                            </Div>
                        )}
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: t("builder.contest.notifications"),
            render: () => (
                <Tab.Pane>
                    <Div bold medpad flex spaceBetween>
                        <Div big>{t("builder.contest.notiSettings")}:</Div>
                    </Div>
                    <Div medpad>
                        <Div gapBottom>
                            <Checkbox
                                toggle
                                label={t("builder.contest.notiSend")}
                                checked={contest.autoNotification}
                                onChange={toggleAutoNotification}
                            />
                        </Div>
                        <Div gutter>
                            <Checkbox
                                toggle
                                label={t("builder.contest.notiReminders")}
                                checked={contest.notifications?.reminders}
                                onChange={toggleReminders}
                            />
                        </Div>
                        {contest.notifications?.reminders && (
                            <Div medpad ivory>
                                <Div>
                                    <Input
                                        label={t("builder.contest.notiAfter")}
                                        type="number"
                                        value={contest.notifications?.inactivity}
                                        onChange={(e) =>
                                            updateReminderProps(
                                                e.target.value,
                                                "inactivity"
                                            )
                                        }
                                    />{" "}
                                    {t("builder.contest.notiInactivity")}
                                </Div>
                                <Div>
                                    <Input
                                        label={t("builder.contest.notiMax")}
                                        type="number"
                                        value={contest.notifications?.max}
                                        onChange={(e) =>
                                            updateReminderProps(
                                                e.target.value,
                                                "max"
                                            )
                                        }
                                    />{" "}
                                    {t("builder.contest.notiMaxNo")}
                                </Div>
                            </Div>
                        )}
                    </Div>
                </Tab.Pane>
            ),
        },
    ];

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padTop flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 320px)" basepad>
                <Div large uppercase gutter bold>
                    {t("components.editing")}: {contest?.name}
                </Div>
                <Div ivory fluid gutter snubbed noOverflow flex>
                    <Div grey relative wd="280px" minHt="140px">
                        <Image src={contestImage} />
                        <Div
                            absolute
                            layer={3}
                            style={{ bottom: "10px", right: "10px" }}
                        >
                            <Div flex clickable gapped>
                                <Button
                                    size="mini"
                                    content={t("builder.dic.changeImage")}
                                    secondary
                                    onClick={() => setImgEditorOpen(true)}
                                />
                            </Div>
                            <ImageUploader
                                modalOpen={imgEditorOpen}
                                handleModalClose={() => setImgEditorOpen(false)}
                                handleModalSave={saveContestImage}
                                value={contestImage}
                                aspectRatio={0.5}
                                mWidth={640}
                            />
                        </Div>
                    </Div>
                    <Div medpad wd="calc(100% - 280px)">
                        <Input
                            fluid
                            label={t("builder.dic.name")}
                            value={contestName}
                            onChange={(e, { value }) => setContestName(value)}
                        />
                        <Input
                            fluid
                            label={t("builder.dic.description")}
                            value={contestDescription}
                            onChange={(e, { value }) =>
                                setContestDescription(value)
                            }
                        />
                        <Div fluid gapTop small>
                            <Button
                                primary
                                content={t("components.update")}
                                onClick={saveContest}
                            />
                        </Div>
                    </Div>
                </Div>
                <Div smoke fluid>
                    <Tab panes={panes} />
                </Div>
            </Div>
            <PreviewLayout
                key={`contest-preview-${contest.wrapper}`}
                url={`/contests/${contest._id}`}
                message={
                    contest.wrapper &&
                    contest.wrapper !== "none" &&
                    t("builder.dic.previewHelp")
                }
            />
        </Div>
    );
}
