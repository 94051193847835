import { useEffect, useRef } from "react";
import _ from "lodash";
import "@styles/readers.scss";
import { Div, Image } from "@components/Generics.react";

export default function ProductCatalog(props) {
    const { deck, currentState, recordAttempt } = props;
    const imageRef = useRef(null);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    return (
        <Div
            fluid
            fullht
            ash
            scrollOverflow
            priText
            normal
            className="product-catalog-reader"
        >
            <Div relative topRounded noOverflow>
                <div ref={imageRef}>
                    <Image fluid src={deck?.content?.image} />
                    <Div
                        midnight
                        centered
                        bold
                        topRounded
                        className="product-name"
                        uppercase
                    >
                        {deck?.content?.name}
                    </Div>
                </div>
            </Div>

            <Div className="main-section">
                {_.map(deck?.content?.products, (product) => {
                    return (
                        <Div white rounded className="product-card">
                            <Image snubbed src={product.image} />
                            <Div padded padTop bold>{product.title}</Div>
                            <Div padded mini>
                                {product.description}
                            </Div>
                            <Div padded bold txtColor="#FFA500">
                                {product.meta}
                                <br />
                                <br />
                            </Div>
                        </Div>
                    );
                })}
            </Div>
        </Div>
    );
}
