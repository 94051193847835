import React, { useState } from "react";
import _ from "lodash";
import { Checkbox } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, Button } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";
import "@styles/readers.scss";

export default function MultipleSelect(props) {
    const { data, slideSeq, trackData, deckId, pageNumber, setPageNumber } =
        props;
    const [correct, setCorrect] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(
                selectedOptions.filter((item) => item !== option)
            );
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    function trackInteraction() {
        const correctSelected = _(data.multiselect)
            .filter({ correct: "checked" })
            .map("text")
            .value();
        const userSelected = _.map(selectedOptions, "text");

        const isCorrect =
            _.every(correctSelected, (item) => userSelected.includes(item)) &&
            _.every(userSelected, (item) => correctSelected.includes(item));

        setCorrect(isCorrect);
        if (
            data.feedback != null &&
            data.feedback.length > 0 &&
            data.purpose !== "Provide Insight"
        )
            setShowOverlay(true);
        else setPageNumber(pageNumber + 1);
        if (trackData) {
            trackData({
                correct: isCorrect,
                response: userSelected,
            });
        }
        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: slideSeq,
                userId: userId,
                response: userSelected,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
    }

    const getOptions = _.map(data.multiselect, (option, index) => {
        return (
            <Div clear spaced key={`ms-option-${index}`}>
                <Checkbox
                    toggle
                    key={option.text}
                    label={option.text}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                />
            </Div>
        );
    });

    // ========================= Render Function =================================
    return (
        <Div fluid fullht zoom superpad relative className="multiselect">
            <Div big={data.question.length < 120} gutter>
                {data.question}
            </Div>
            <Div small italics gutter>
                {t("deck.qdf.multiSelect")}
            </Div>
            <Div trench ash rounded compact padTop padBottom>
                {getOptions}
            </Div>
            <Button
                content={"Submit"}
                fluid
                primary
                onClick={trackInteraction}
            />
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
