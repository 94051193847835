import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/lazy";

import "@styles/readers.scss";

import { Div } from "@components/Generics.react";

export default function VideoReader(props) {
    const { deck, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Stop resizing to allow for Video FullScreening
    //---------------------------------------------------------------------------
    useEffect(() => {
        window.doNotResize = true;
        return () => (window.doNotResize = false);
    });

    //---------------------------------------------------------------------------
    // Time and Play tracking
    //---------------------------------------------------------------------------
    const [playing, setPlaying] = useState(true);
    function trackPlay() {
        setPlaying(true);
    }
    //---------------------------------------------------------------------------
    // Video Tracking
    //---------------------------------------------------------------------------
    const [viewedPercentage, setViewedPercentage] = useState(0);
    const [finished, setFinished] = useState(false);
    function trackProgress(obj) {
        setViewedPercentage(parseInt(obj.played * 100, 0));
        if (viewedPercentage > 90) setFinished(true);
    }
    function trackFinish() {
        setFinished(true);
    }

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: viewedPercentage,
            completion: finished,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewedPercentage, finished]);

    // ========================= Render Function =================================
    return (
        <Div fullht fluid centered noOverflow relative>
            <Div rounded gutter className="video-responsive">
                <ReactPlayer
                    url={deck.content}
                    className="react-player"
                    playing={playing}
                    width="100%"
                    height="100%"
                    controls
                    onProgress={trackProgress}
                    onPlay={trackPlay}
                    onEnded={trackFinish}
                />
            </Div>
        </Div>
    );
}
