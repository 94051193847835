import React from "react";
import { Div } from "@components/Generics.react";
export default function Blurb(props) {
    const { blurb } = props.data;
    const isPortrait = window.innerHeight > window.innerWidth;

    return (
        <Div fluid flex={isPortrait} superpad big>
            {blurb}
        </Div>
    );
}
