import { Divider } from "semantic-ui-react";
import { Div, Image, Button } from "@components/Generics.react";
import { darkStyle, primaryStyle } from "@utilities/helpers";
import { useTranslation } from "react-i18next";
import { settings } from "@config/settings/app.settings";

export default function DeckEnding(props) {
    const {
        name,
        attemptData,
        passmark,
        colors = { primary: "#666666" },
        points = 0,
        deckHeight,
        deckHash,
        inCourse,
        closeDeck,
    } = props;
    const isPortrait = window.innerHeight > window.innerWidth;
    const { t } = useTranslation("common");

    const passed = attemptData.score && attemptData.score >= passmark;

    const endingBlock =
        attemptData.score == null ? (
            <Div centered center-align superpad>
                <Div
                    fit-content
                    big
                    centered
                    circled
                    relaxed
                    trenched
                    style={primaryStyle(colors)}
                >
                    {t("deck.welldone")}
                </Div>
                <br />
                <Image gutter src={t("deck.completion")} />
                {name != null && name !== "" && (
                    <Div>
                        <Div large gutter>
                            {t("deck.completionMsg")}
                        </Div>
                        <Div trench basepad smoke rounded>
                            {name}
                        </Div>
                    </Div>
                )}
                <Divider />
                {closeDeck != null && (
                    <Button
                        primary
                        fluid
                        content={t("deck.close")}
                        onClick={closeDeck}
                    />
                )}
            </Div>
        ) : (
            <Div centered center-align superpad>
                <Div
                    fit-content
                    big
                    centered
                    circled
                    relaxed
                    trenched
                    style={primaryStyle(colors)}
                >
                    {t(passed ? "deck.welldone" : "deck.tryagain")}
                </Div>
                <br />
                <Div
                    wd="150px"
                    ht="150px"
                    pureCircle
                    headline
                    centered
                    flex
                    altText
                    gutter
                    success={passed}
                    failure={!passed}
                >
                    {attemptData.score}%
                </Div>
                {passed && points > 0 && (
                    <Div
                        fit-content
                        large
                        bold
                        centered
                        relaxed
                        rounded
                        trench
                        style={primaryStyle(colors)}
                    >
                        {parseInt((attemptData.score * points) / 100, 0)}{" "}
                        {t("deck.points")}
                    </Div>
                )}
                {name != null && name !== "" && (
                    <Div>
                        <Div large gutter>
                            {t(passed ? "deck.passedMsg" : "deck.failedMsg")}
                        </Div>
                        <Div trench basepad smoke rounded>
                            {name}
                        </Div>
                    </Div>
                )}
                <Divider />
                {closeDeck != null && (
                    <Button
                        primary
                        fluid
                        content={t("deck.submit")}
                        onClick={closeDeck}
                    />
                )}
            </Div>
        );

    return (
        <Div
            fluid
            ht={deckHeight}
            rounded
            basepad
            relative
            style={deckHash.inPlayer && !isPortrait ? darkStyle("#999999") : {}}
            className={`deck-viewer ${inCourse && "in-course"}`}
        >
            <Div
                fluid
                ht={`calc(100vh - ${isPortrait ? 170 : 155}px)`}
                maxWd="400px"
                centered
                center-align
                white
                flex
                rounded
                style={darkStyle(settings.colors.main)}
            >
                {endingBlock}
            </Div>
        </Div>
    );
}
