// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Div } from "@components/Generics.react";
import { Input } from "semantic-ui-react";
export default function FeedbackField(props) {
    const {
        target,
        header,
        help,
        value,
        setValue,
        height,
        flatField = false,
        maxLength,
    } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [correctValue, setCorrectValue] = useState(
        value
            ? target && value[target]
                ? value[target][0] || ""
                : value[0] || ""
            : ""
    );
    const [incorrectValue, setIncorrectValue] = useState(
        value
            ? target && value[target]
                ? value[target][1] || ""
                : value[1] || ""
            : ""
    );
    const { t } = useTranslation("common");

    // Debouncing the user input
    //==================================
    useEffect(() => {
        setIsMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const updatedValue = [correctValue, incorrectValue];
        const timeoutId = setTimeout(() => {
            if (isMounted)
                setValue(
                    target && !flatField
                        ? { [target]: updatedValue }
                        : updatedValue
                );
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correctValue, incorrectValue]);

    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} ivory basepad snug>
            <Div big rimmed>
                {header}
                {help && (
                    <Div tiny italics txtHalf gapSlice>
                        {help}
                    </Div>
                )}
            </Div>
            <Div ivory rimmed ht={"calc(100% - 60px)"} fluid>
                <Div relative>
                    {correctValue && maxLength && (
                        <Div
                            small
                            absolute
                            compact
                            ash={correctValue.length < maxLength}
                            danger={correctValue.length > maxLength}
                            style={{ top: "-5px", right: "5px", zIndex: 2 }}
                            slightShadow
                        >
                            {correctValue.length}/{maxLength}
                        </Div>
                    )}
                    <Input
                        fluid
                        value={correctValue || ""}
                        label={t("builder.deck.correctResponse")}
                        onChange={(e) => setCorrectValue(e.target.value)}
                    />
                </Div>
                <Div gapTop relative>
                    {incorrectValue && maxLength && (
                        <Div
                            small
                            absolute
                            compact
                            ash={incorrectValue.length < maxLength}
                            danger={incorrectValue.length > maxLength}
                            style={{ top: "0", right: "5px", zIndex: 2 }}
                            slightShadow
                        >
                            {incorrectValue.length}/{maxLength}
                        </Div>
                    )}
                    <Input
                        fluid
                        value={incorrectValue || ""}
                        label={t("builder.deck.incorrectResponse")}
                        onChange={(e) => setIncorrectValue(e.target.value)}
                    />
                </Div>
            </Div>
        </Div>
    );
}
