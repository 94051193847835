// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Progress } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { primaryStyle, darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import { Div, Image } from "@components/Generics.react";

export default function GameOver(props) {
    const {
        deck,
        points,
        // score,
        level,
        replay,
        showLeaderboard,
        win,
        inCourse,
    } = props;
    const { t } = useTranslation("common");
    return (
        <Div
            fluid
            fullht
            white
            maxWd="400px"
            zoom
            centered
            superpad
            style={darkStyle(settings.colors.main)}
        >
            <Div
                fit-content
                big
                centered
                relaxed
                circled
                clickable
                gutter
                style={primaryStyle(deck?.colors)}
            >
                {win ? t("deck.welldone") : t("deck.tryagain")}
            </Div>
            <Div>
                {inCourse && (
                    <Div midnight relaxed rounded>
                        {win ? t("deck.game.passed") : t("deck.game.failed")}
                    </Div>
                )}
                <Div superpad small>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Div onClick={replay} centered clickable>
                                    <Image src="/assets/images/configurable/replay.png" />
                                    <Div uppercase compact>{t("deck.game.replay")}</Div>
                                </Div>
                            </Grid.Column>
                            <Grid.Column>
                                <Div
                                    onClick={showLeaderboard}
                                    centered
                                    clickable
                                >
                                    <Image src="/assets/images/configurable/showlb.png" />
                                    <Div uppercase compact>
                                        {t("deck.game.leaderboard")}
                                    </Div>
                                </Div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Div>
                {deck.points > 0 && (
                    <Div basepad charcoal rounded>
                        <Div padTop>You won</Div>
                        <Div massive>
                            {points} {t("deck.points")}
                        </Div>
                        <Div>for reaching Level {level}</Div>
                        <Div basepad>
                            <Progress
                                indicating
                                inverted
                                percent={parseInt(
                                    (points * 100) / deck.points,
                                    0
                                )}
                            />
                            {points < deck.points && (
                                <Div style={{ marginTop: "-15px" }}>
                                    {t("deck.game.aimHigher").replace(
                                        "$total",
                                        deck.points
                                    )}
                                </Div>
                            )}
                        </Div>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
