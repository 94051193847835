import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "@styles/readers.scss";

import { Div, Icon } from "@components/Generics.react";
import Reward from "@components/Reward.react";

export default function RewardReader(props) {
    const { deck, recordAttempt, closeDeck } = props;
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            completion: true,
            timeSpent: 0,
            pointsDelta: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ========================= Render Function =================================
    return (
        <Div maxWd="350px" centered center-align>
            <Div fluid centered gutter>
                <Reward deck={deck} />
            </Div>
            <Div className="close-icon" onClick={closeDeck}>
                {t("deck.close")} <Icon name="close" inline fitted />
            </Div>
        </Div>
    );
}
