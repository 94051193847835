// Import from NPM
// -------------------------------------
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div, Button } from "@components/Generics.react";
import { TemplateList, ComponentMap } from "@schemas/templateSchema";
import SlideToolbar from "./slideEditorComponents/SlideToolbar.react";
import SlideBuilder from "./SlideBuilder.react";

export default function DeckBuilder(props) {
    const { readerType, deck, setDeck, slideNum, setSlideNum, setRightPanel } =
        props;
    const [currentTemplateName, setCurrentTemplateName] = useState(
        deck.content[slideNum]?.template
    );
    const [slideTemplate, setSlideTemplate] = useState(
        TemplateList[deck.content[slideNum]?.template]
    );
    const [slideContent, setSlideContent] = useState(
        deck.content[slideNum]?.data
    );
    const { t } = useTranslation("common");

    useEffect(() => {
        if (deck && deck.content) {
            setCurrentTemplateName(deck.content[slideNum]?.template);
            setSlideTemplate(TemplateList[deck.content[slideNum]?.template]);
            setSlideContent(deck.content[slideNum]?.data);
        }
        if (slideNum === -1) setSlideNum(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deck, slideNum]);

    useEffect(() => {
        if (deck && deck.content) {
            setSlideTemplate(TemplateList[currentTemplateName]);
        }
    }, [deck, currentTemplateName]);

    function getDefaultSlide(slideSeq) {
        return readerType === "qdf"
            ? {
                  template: "title",
                  slideSeq: slideSeq,
                  data: { image: null, title: "" },
              }
            : {
                  template: "single_select",
                  slideSeq: slideSeq,
                  data: {
                      image: null,
                      question: "",
                      options: [],
                      feedback: [],
                  },
              };
    }

    function addSlide() {
        let newContent = [...deck.content];
        if (deck.content.length > 0) {
            newContent.splice(slideNum + 1, 0, getDefaultSlide(slideNum + 1));
            newContent = newContent.map((item, index) => ({
                ...item,
                slideSeq: index,
            }));
            setDeck({ ...deck, ...{ content: newContent } });
            setSlideNum(slideNum + 1);
        } else {
            setDeck({
                ...deck,
                ...{
                    content: [getDefaultSlide(0)],
                },
            });
            setSlideNum(0);
        }
    }

    function cloneSlide() {
        let newContent = [...deck.content];
        newContent.splice(slideNum + 1, 0, newContent[slideNum]);
        newContent = newContent.map((item, index) => ({
            ...item,
            slideSeq: index,
        }));
        setDeck({ ...deck, ...{ content: newContent } });
        setSlideNum(slideNum + 1);
    }

    function deleteSlide() {
        let newContent = [...deck.content];
        newContent.splice(slideNum, 1);
        newContent = newContent.map((item, index) => ({
            ...item,
            slideSeq: index,
        }));
        setDeck({ ...deck, ...{ content: newContent } });
        if (slideNum === newContent.length) setSlideNum(slideNum - 1);
    }

    function transpileContent(data) {
        setRightPanel("mobile");
        const thisSlideData = { ...deck.content[slideNum]?.data, ...data };
        const thisSlide = {
            ...deck.content[slideNum],
            ...{ template: currentTemplateName, data: thisSlideData },
        };
        let thisDeckContent = [...deck.content];
        _.fill(thisDeckContent, thisSlide, slideNum, slideNum + 1);
        const thisDeck = { ...deck, content: thisDeckContent };
        setDeck(thisDeck);
    }

    const slideType = readerType === "qdf" ? "Slide" : "Question";
    const slideCount = deck.content?.length;

    // ========================= Render Function =================================
    if (deck.content == null || deck.content.length === 0)
        return (
            <Div flex column ht="calc(100vh - 325px)">
                <Div bold>{t("builder.deck.noContent")}</Div>
                <br />
                <Button
                    primary
                    content={t("builder.deck.addSlide")}
                    onClick={addSlide}
                />
            </Div>
        );
    else
        return (
            <Div clearfix>
                <Div shade clearfix basepad slightShadow>
                    <SlideToolbar
                        {...{
                            readerType,
                            slideType,
                            slideNum,
                            setSlideNum,
                            slideCount,
                            currentTemplateName,
                            setCurrentTemplateName,
                            addSlide,
                            cloneSlide,
                            deleteSlide,
                        }}
                    />
                </Div>
                {slideContent && (
                    <Div basemargin ht={"calc(100vh - 300px)"} autoOverflow>
                        <SlideBuilder
                            slideNum={slideNum}
                            content={slideContent}
                            setContent={transpileContent}
                            readerType={readerType}
                            fields={_.map(slideTemplate?.components, (comp) => {
                                return ComponentMap[comp];
                            })}
                        />
                        <br />
                        <br />
                    </Div>
                )}
            </Div>
        );
}
