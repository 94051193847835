import React from "react";
import ReactPlayer from "react-player/lazy";
import { Div } from "@components/Generics.react";
import "@styles/readers.scss";

export default function Video(props) {
    const { video, caption } = props.data;
    // ========================= Render Function =================================
    return (
        <Div fluid>
            <Div className="video-responsive">
                <ReactPlayer url={video} width="100%" height="100%" />
            </Div>
            {caption && (
                <Div superpad ash big>
                    {caption}
                </Div>
            )}
        </Div>
    );
}
