// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Div } from "@components/Generics.react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
import Editor from "@ckeditor/ckeditor5-build-balloon-block";
import api from "./../../../../../config/api/app.endpoints.json";

export default function HTMLField(props) {
    const auth = useSelector((state) => state.auth);
    const { target, header, help, value, setValue, height, maxLength } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [inputValue, setInputValue] = useState(
        target && value ? value[target] || "" : value
    );

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const base64Image = reader.result;
                            
                            fetch(`${api.apiUrl}${api.endpoints.builderPaths.updateArticleImage}`, {
                                method: "post",
                                body: JSON.stringify({ image: base64Image }),
                                headers: { 
                                    "Content-Type": "application/json",
                                    "access-token": auth.token 
                                },
                            })
                            .then((res) => {
                                if (!res.ok) {
                                    throw new Error(`HTTP error! Status: ${res.status}`);
                                }
                                return res.json();
                            })
                            .then((res) => {
                                console.log("res", res);
                                if (res) {
                                    resolve({
                                        default: `${res.url}`
                                    });
                                } else {
                                    reject(new Error("Filename not provided in response"));
                                }
                            })
                            .catch((err) => {
                                reject(err);
                            });
                        };
                        reader.onerror = function (error) {
                            reject(error);
                        };
                        reader.readAsDataURL(file);
                    });
                });
            }
        };
    }
    

    function uploadPlugin(editor) {
        console.log("uploadPlugin");
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
    }

    // Debouncing the user input
    //==================================
    useEffect(() => {
        setIsMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isMounted)
                setValue(target ? { [target]: inputValue } : inputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    const inputLength = inputValue
        ? inputValue.toString().replace(/<[^>]*>?/gm, "").length
        : 0;

    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} basepad ivory snug>
            {header && (
                <Div big rimmed>
                    {inputValue && maxLength && (
                        <Div
                            small
                            float-right
                            compact
                            slightShadow
                            ash={inputLength < maxLength}
                            danger={inputLength > maxLength}
                            style={{ marginTop: "-10px" }}
                        >
                            {inputLength}/{maxLength}
                        </Div>
                    )}
                    {header}
                    {help && (
                        <Div tiny italics txtHalf gapSlice>
                            {help}
                        </Div>
                    )}
                </Div>
            )}
            <Div ash ht={"calc(100% - 60px)"} flex>
                <Div wd="30px" fullht />
                <Div fluid white wd="calc(100% - 30px)" fullht autoOverflow>
                    <CKEditor
                        editor={Editor}
                        data={inputValue}
                        config={{
                            link: {
                                // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                addTargetToExternalLinks: true,
                            },
                            extraPlugins: [uploadPlugin],
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setInputValue(data);
                        }}
                    />
                </Div>
            </Div>
        </Div>
    );
}
