import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Div, Button } from "@components/Generics.react";

export default function Brancher(props) {
    const { data, setPageNumber } = props;
    const { t } = useTranslation("common");

    const getOptions = _.map(data.brancher, (option, index) => {
        return (
            <Button
                content={option.text}
                fluid
                primary
                key={"button-" + index}
                style={{ marginBottom: "10px" }}
                onClick={() => setPageNumber(option.slideLink)}
            />
        );
    });

    return (
        <Div fluid fullht zoom superpad>
            <Div big gutter>
                {data.paragraph}
            </Div>
            <Div small italics>
                {t("deck.qdf.brancher")}
            </Div>
            <Div padTop>{getOptions}</Div>
        </Div>
    );
}
