import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import {
    Grid,
    Button,
    Icon,
    Label,
    Divider,
    Card,
    Image,
} from "semantic-ui-react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";
import { Tutorials } from "../../../config/settings/app.tutorials";

export default function BuilderHome(props) {
    const { t } = useTranslation("common");
    const features = useFeatures();

    const pageContent = (
        <Div superpad vapor>
            <Div megapad fluid white rounded>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Div wd="256px">
                                <Image
                                    src={
                                        "/assets/images/configurable/logo-full.png"
                                    }
                                />
                            </Div>
                            <Div mega altText gutter>
                                {t("builder.welcome.title")}
                            </Div>
                            <Div big bold>
                                {t("builder.welcome.context")}
                            </Div>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Div>
                                <Div fluid padTop>
                                    {_.map(
                                        _.filter(
                                            settings.sidebar,
                                            (elm) =>
                                                !["home"].includes(elm) &&
                                                (elm !== "social" ||
                                                    features.social)
                                        ),
                                        (elm, idx) => (
                                            <Div snug key={`panel-btn-${idx}`}>
                                                <Button
                                                    as="div"
                                                    labelPosition="right"
                                                >
                                                    <Button icon color="grey">
                                                        <Icon
                                                            name={t(
                                                                `builder.sidebar.${elm}.icon`
                                                            )}
                                                        />{" "}
                                                        <Div
                                                            leftAlign
                                                            inlineBlock
                                                            minWd="128px"
                                                        >
                                                            {t(
                                                                `builder.sidebar.${elm}.header`
                                                            )}
                                                        </Div>
                                                    </Button>
                                                    <Label
                                                        as="a"
                                                        basic
                                                        pointing="left"
                                                    >
                                                        {t(
                                                            `builder.sidebar.${elm}.tooltip`
                                                        )}
                                                    </Label>
                                                </Button>
                                            </Div>
                                        )
                                    )}
                                </Div>
                            </Div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Div>
            <Divider horizontal>
                <Div large gapTop wd="250px">
                    {t(`builder.welcome.howWorks`)}
                </Div>
                <Div>{t(`builder.welcome.tutorials`)}</Div>
            </Divider>
            <Div fluid grey basepad rounded>
                <Card.Group itemsPerRow={4}>
                    {_.map(Tutorials, (card, idx) => {
                        return (
                            <Card key={`tutorial-${idx}`}>
                                <Div fluid relative clickable>
                                    <Image fluid src={card.image} />
                                    <Div
                                        absolute
                                        snubbed
                                        style={{
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <Icon
                                            name="youtube play"
                                            color="red"
                                            size="huge"
                                        />
                                    </Div>
                                </Div>
                                <Card.Content>
                                    <Card.Header>{card.name}</Card.Header>
                                    <Card.Meta>{card.meta}</Card.Meta>
                                    <Card.Description>
                                        {card.description}
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        );
                    })}
                </Card.Group>
            </Div>
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={`QuoDeck Creator`}
            navName={"Home"}
            pageCode="home"
            mainPanel={pageContent}
            rootPage={true}
        />
    );
}
