import React from "react";
import _ from "lodash";
import { Tab } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import QuizAnalytics from "./quize/QuizAnalytics.react";
import SurveyAnalytics from "./survey/SurveyAnalytics.react";
import FileAnalytics from "./FileAnalytics.react";
import TeamSummary from "./TeamSummary.react";
import CourseAnalytics from "./CourseAnalytics.react";
export default function ReportWrappers(props) {
    const panes = [
        {
            menuItem: {
                key: "dashboard",
                icon: "dashboard",
                content: "Dashboard",
            },
            render: () => (
                <Tab.Pane>
                    <Div vapor rounded>
                        <TeamSummary />
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "audience",
                icon: "chart area",
                content: "Audience",
            },
            render: () => (
                <Tab.Pane>
                    <Div vapor>
                        <CourseAnalytics />
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "content",
                icon: "add",
                content: "Contents",
            },
            render: () => (
                <Tab.Pane>
                    {" "}
                    <Div vapor>
                        <FileAnalytics />
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "quizzers",
                icon: "chart area",
                content: "Quizzers",
            },
            render: () => (
                <Tab.Pane vapor>
                    <Div vapor>
                        <QuizAnalytics />
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "surveys",
                icon: "chart area",
                content: "Surveys",
            },
            render: () => (
                <Tab.Pane>
                    <Div vapor>
                        <SurveyAnalytics />
                    </Div>
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Div margin fullht fluid relative rounded charcol vapor>
            <Tab menu={{ widths: 5 }} panes={panes} />
        </Div>
    );
}
