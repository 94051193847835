import React, { useState, useEffect } from "react";
import { Input, Divider } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { login } from "@reducers/authSlice";
import axios from "axios";
import { baseUrl } from "@api/apiV6";

import { Div, Button } from "@components/Generics.react";
import AuthLayout from "@layouts/AuthLayout.react";

export default function Register(props) {
    const [username, setUsername] = useState("");
    const [validUsername, setValidUsername] = useState(false);
    const [org, setOrg] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [password, setPassword] = useState("");
    // const [validPassword, setValidPassword] = useState(false);

    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation("common");

    useEffect(() => {
        (async () => {
            if (username.length > 3) {
                const response = await axios({
                    method: "get",
                    url: `${baseUrl}api/v3/users/check/${username}`,
                });
                response.data.available
                    ? setValidUsername(true)
                    : setValidUsername(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);
    useEffect(() => {
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
            ? setValidEmail(true)
            : setValidEmail(false);
    }, [email]);

    const tryRegister = async () => {
        let formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        formData.append("email", email);
        formData.append("org", org);
        formData.append("name", fullName);

        try {
            const signupResponse = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/register`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            if (signupResponse.status === 200) {
                const response = await axios({
                    method: "post",
                    url: `${baseUrl}api/v3/auth/sign_in`,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });
                await dispatch(
                    login({
                        user: response.data,
                        token: response.headers["access-token"],
                    })
                );
                navigate("/");
            }
        } catch (error) {
            console.log(error);
            setError(error.response.message);
        }
    };

    const checkStyle = { float: "right", margin: "-30px 10px 0 0" };

    // ========================= Render Function =================================
    return (
        <AuthLayout title={`Register on ${t("appName")}`}>
            <Div fluid white fullHt>
                <Div massive altText fluid center-align gutter>
                    SIGN UP
                </Div>
                {error && (
                    <Div padded fluid danger gutter>
                        {error}
                    </Div>
                )}
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.registration.usernameLabel")}
                        placeholder={t("auth.registration.usernamePlaceholder")}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    {!validUsername && username?.length > 3 && (
                        <Div relaxed danger fluid>
                            <i className="fa fa-times" /> &nbsp;{" "}
                            {t("auth.registration.usernameUnavailable")}
                        </Div>
                    )}
                    {validUsername && (
                        <Div txtColor="success" style={checkStyle}>
                            <i className="fa fa-check-circle" />
                        </Div>
                    )}
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.registration.nameLabel")}
                        placeholder={t("auth.registration.namePlaceholder")}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.registration.orgLabel")}
                        placeholder={t("auth.registration.orgPlaceholder")}
                        value={org}
                        onChange={(e) => setOrg(e.target.value)}
                    />
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label="Email"
                        type="email"
                        placeholder="Where can we contact you?"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!validEmail && email?.length > 0 && (
                        <Div relaxed danger fluid>
                            <i className="fa fa-times" /> &nbsp; Malformed email
                            address.
                        </Div>
                    )}
                    {validEmail && (
                        <Div txtColor="success" style={checkStyle}>
                            <i className="fa fa-check-circle" />
                        </Div>
                    )}
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label="Password"
                        type="password"
                        placeholder="Input Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Div>
                <Div fluid gutter>
                    <Button
                        fluid
                        primary
                        content="SUBMIT"
                        onClick={tryRegister}
                        disabled={
                            username.length < 3 ||
                            !validUsername ||
                            !validEmail ||
                            password.length < 3
                        }
                    />
                    <Divider horizontal>OR</Divider>
                    <NavLink to="/login">
                        <Button success={+true} content={t("auth.login")} />
                    </NavLink>
                </Div>
            </Div>
        </AuthLayout>
    );
}
