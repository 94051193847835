import React, { useEffect } from "react";
import "@styles/readers.scss";
import { Div, Image } from "@components/Generics.react";

export default function InfographicReader(props) {
    const { deck, currentState, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
            recordAttempt({
                viewedPercentage: 100,
                completion: true,
                pointsDelta: 0,
                score: null,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    // ========================= Render Function =================================

    return (
        <Div
            fullht
            fluid
            centered
            center-align
            compact
            noOverflow
            relative
            half
            gapTop
            className="fullscreen-target"
        >
            <Image fluid src={deck.content} />
        </Div>
    );
}
