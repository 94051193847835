import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Button, Dropdown,Icon } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import ReactTable from "react-table-v6";
import { useGetDeckPathQuery, useGetSurveyStatusPathQuery } from "@api/apiV6";
import { Div } from "@components/Generics.react";
export default function AssessmentLearnersSubPanel(props) {
    const isPortrait = window.innerHeight > window.innerWidth;
    const reactTableRef = useRef(null);
    const [activeLearner, setActiveLearner] = useState(null);
    const [questionResponse, setQuestionResponse] = useState(null);
    const [learnerList, setLearnerList] = useState(null);
    const [questionList, setQuestionList] = useState(null);
    const { activeTopic, results } = props;
    const assessmentId = activeTopic;
    const deckId = activeTopic;

    const { data: assessmentData } = useGetDeckPathQuery({ deckId: deckId });
    const { data: learnerData } = useGetSurveyStatusPathQuery({ deckId: deckId });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const questionData = _.map(assessmentData?.content, (slide, index) => {
                    return {
                        key: assessmentId + "#" + slide.masterSeq,
                        value: assessmentId + "#" + slide.masterSeq,
                        text: slide.data.question,
                        template: slide.template,
                        data: slide.data,
                        sequence: index,
                    };
                });
                const learnerset = _.map(learnerData?.learners, (learners, index) => {
                    let user = _.find(results, {
                        user_id: learners._id,
                    });

                    return {
                        key: learners._id,
                        value: learners._id,
                        sequence: index,
                        text:
                            learnerData.learners[0]._id === "1"
                                ? learners.name
                                : user
                                    ? user.username
                                    : "",
                        data: learners,
                    };
                });
                if (learnerset) {
                    setLearnerList(learnerset);
                    setQuestionList(questionData);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchData();
    }, [results]);

    useEffect(() => {
        const selectedLearner = _.find(learnerList, {
            key: activeLearner,
        });

        if (selectedLearner) {
            const results = _.map(
                _.sortBy(selectedLearner.data.questions, "seq"),
                (question) => {
                    const selectedQuestion = _.find(questionList, {
                        key: question.slideId,
                    });

                    return {
                        name: selectedQuestion?.text,
                        response: handleResponse(
                            selectedQuestion?.template,
                            selectedQuestion?.data,
                            question.response
                        ),
                    };
                }
            );
            setQuestionResponse(results);
        }
    }, [activeLearner, questionList, learnerData, learnerList]);

    const handleResponse = (template, data, response) => {
        try {
            switch (template) {
                case "true_false":
                    return data.choice[response].text;
                case "single_select":
                    return data.options[response].text;
                case "multiple_select":
                    return _.map(
                        response,
                        (selection) => data.multiselect[selection].text
                    ).join(", ");
                case "fill_in_the_blanks":
                default:
                    return response;
            }
        } catch (e) {
            console.log(e)
        }
    };


    const downloadCSV = () => {
        const items = _.map(ReactTable.getResolvedState().sortedData, "_original");

        if (items.length > 0) {
            const replacer = (key, value) => (value === null ? "" : value);
            const header = Object.keys(items[0]);
            let csv = items.map((row) =>
                header
                    .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                    .join(",")
            );

            csv.unshift(header.join(","));
            csv = csv.join("\r\n");

            const hiddenElement = document.createElement("a");
            hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download =
                props.course.name.split(" ").join("_") + "-data.csv";
            hiddenElement.click();
        }
    };


    const fullColumns = [
        {
            Header: "Question",
            accessor: "name",
            minWidth: 128,
            style: { textAlign: "left", whiteSpace: "unset" },
        },
        {
            Header: "Response",
            id: "response",
            accessor: (d) => d.response,
            style: { textAlign: "left", whiteSpace: "unset" },
        },
    ];

    return (
        <Div vapor rounded relaxed={!isPortrait} small>
            <Grid stackable>
                <Grid.Row columns={2}>
                    <Grid.Column teal>
                        <Div spaced charcol bold nopad left-align large uppercase>
                            Select a Learner
                        </Div>
                    </Grid.Column>
                    <Grid.Column floated='left'>
                        <Div spaced
                            vapor
                            right-align
                        >
                            <Button charcol>
                                <Icon name='download' />
                                Download CSV
                            </Button>
                        </Div>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid.Row>
                <Dropdown
                    placeholder="Select Learners"
                    search
                    fluid
                    selection
                    // value={learnerData._id}
                    options={learnerList}
                    onChange={(e, { value }) => setActiveLearner(value)}
                />
                <br />
                <ReactTable
                    ref={reactTableRef}
                    data={questionResponse || []}
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id]).includes(filter.value)
                            : true;
                    }}
                    pageSizeOptions={[5, 10]}
                    columns={fullColumns}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
            </Grid.Row>
        </Div>
    );
}
