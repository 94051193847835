import React, { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { deckFormats } from "@schemas/deckFormats";
import { useTranslation } from "react-i18next";

import { Checkbox, Dropdown } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";

import PreviewLayout from "@layouts/PreviewLayout.react";

export default function ArticleShare(props) {
    const { article, updateArticle, allTags } = props;
    const [previewHash, setPreviewHash] = useState(Math.random());
    const [artTags, setArtTags] = useState(article.tags);
    const { t } = useTranslation("common");

    const articleType =
        _.find(deckFormats, {
            readerType: article?.category,
        })?.name || t("tagCodes.article");

    async function publishArticle() {
        await updateArticle({
            id: article._id,
            published: !article.published,
        }).unwrap();
        setPreviewHash(Math.random());
        toast(
            article.published
                ? `${articleType} ${t("builder.article.unpublished")}`
                : `${articleType} ${t("builder.article.published")}`
        );
    }
    async function makeShareable() {
        await updateArticle({
            id: article._id,
            shareable: !article.shareable,
        }).unwrap();
        setPreviewHash(Math.random());
        toast(t("components.saved"));
    }
    async function tagArticle() {
        await updateArticle({
            id: article._id,
            tags: artTags,
        }).unwrap();
        setPreviewHash(Math.random());
        toast(t("builder.article.tagsUpdated"));
    }

    // ========================= Render Function =================================
    return (
        <Div fluid fullht basepad flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 320px)" basepad>
                <Div compact uppercase>
                    {t("builder.article.publish")} {articleType}:
                </Div>
                <Div large gutter compact bold>
                    {article?.name}
                </Div>
                <Div
                    basepad
                    success={article.published}
                    danger={!article.published}
                    snug
                    trench
                    snubbed
                >
                    <Div bold>
                        {t("components.status")}:{" "}
                        {article.published
                            ? t("builder.article.published")
                            : t("builder.article.unpublished")}
                    </Div>
                    <Div italics gutter>
                        {article.published
                            ? t("builder.article.publishedHelp").replace(
                                  "$articleType",
                                  articleType
                              )
                            : t("builder.article.unpublishedHelp").replace(
                                  "$articleType",
                                  articleType
                              )}
                    </Div>
                    <Div white basepad snubbed>
                        <Checkbox
                            toggle
                            label={
                                article.published
                                    ? t("builder.article.published")
                                    : t("builder.article.publishIt")
                            }
                            checked={article.published}
                            onChange={publishArticle}
                        />
                    </Div>
                </Div>
                <Div basepad smoke snubbed snug gutter>
                    <Div bold>
                        {t("builder.article.assignTags")} {articleType}:
                    </Div>
                    <Div italics gutter>
                        {t("builder.article.tagsHelp")}
                    </Div>
                    <Dropdown
                        placeholder={t("builder.article.selectTags")}
                        fluid
                        selection
                        multiple
                        search
                        options={_.map(allTags, (t) => ({
                            key: t._id,
                            text: t.name,
                            value: t._id,
                        }))}
                        value={_.map(artTags, (a) => (a._id ? a._id : a))}
                        onChange={(e, { value }) => setArtTags(value)}
                    />
                    <br />
                    <Button
                        primary
                        content={t("builder.article.updateTags")}
                        onClick={tagArticle}
                    />
                </Div>
                <Div basepad smoke snug snubbed>
                    <Div bold>{t("builder.article.allowSharing")}</Div>
                    <Div italics gutter>
                        {t("builder.article.sharingHelp").replace(
                            "$articleType",
                            articleType
                        )}
                    </Div>
                    <Checkbox
                        toggle
                        label={
                            article.shareable
                                ? t("builder.article.sharingAllowed")
                                : t("builder.article.allowSharing")
                        }
                        checked={article.shareable}
                        onChange={makeShareable}
                    />
                </Div>
            </Div>
            <PreviewLayout
                url={`/content/${article.category}/${article.identifier}?hash=${previewHash}`}
            />
        </Div>
    );
}
