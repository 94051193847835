import React, { useContext } from "react";
import _ from "lodash";
import { clientAssetsPath } from "@api/apiV6";
import { Div, Image } from "@components/Generics.react";
import Carousel from "@components/Carousel.react";
import { LayoutContext } from "@layouts/MasterLayout.react";

export default function PopupList(props) {
    const { popupitems } = props.data;
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    return (
        <Div wd={isPortrait ? "100%" : "33%"} zoom centered={!isPortrait}>
            <Carousel slidesToShow={1} enableButtons>
                {_.map(popupitems, (card, idx) => {
                    return (
                        <Div
                            key={`list-item-${idx}`}
                            slightShadow
                            left-aligned
                            rounded
                            noOverflow
                            flex={!isPortrait}
                        >
                            <Image
                                fluid
                                src={`${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`}
                            />
                            <Div superpad ash big={isPortrait}>
                                {card.caption}
                            </Div>
                        </Div>
                    );
                })}
            </Carousel>
        </Div>
    );
}
