import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shortid from "shortid";
import { Div, Icon } from "@components/Generics.react";
import "@styles/components.scss";

export default function Carousel(props) {
    const {
        children,
        initialSlide = 0,
        activeSlide = 0,
        speed = 500,
        autoPlay = false,
        arrows = false,
        dots = false,
        infinite = false,
        asNavFor,
        handleChange,
        handleClick,
        slidesToShow = 1,
        slidesToScroll = 1,
        lazyLoad = false,
        enableButtons = false,
        customPaging,
    } = props;
    const [sliderId] = useState(shortid.generate());
    const [active, setActive] = useState(activeSlide);
    const [settings, setSettings] = useState(null);
    const [sliderRef, setSliderRef] = useState(null);

    useEffect(() => {
        let carouselSettings = {
            speed: speed,
            initialSlide: initialSlide,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToScroll,
            arrows: arrows,
            dots: dots,
            autoplay: autoPlay,
            asNavFor: asNavFor,
            infinite: infinite,
            lazyLoad: lazyLoad,
            swipe: activeSlide !== children.length - 1,
            beforeChange: (current, next) => {
                handleItmChange(next);
            },
        };
        if (customPaging) {
            carouselSettings.customPaging = function (i) {
                // eslint-disable-next-line
                return <a>{children[i]}</a>;
            };
        }

        setSettings(carouselSettings);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleJump(e) {
        // Get the total width of the div
        const totalWidth = e.currentTarget.offsetWidth;

        // Get the x-coordinate of the click event relative to the left edge of the div
        const clickX = e.clientX - e.currentTarget.getBoundingClientRect().left;

        // Calculate the percentage
        const clickPercentage = (clickX / totalWidth) * 100;

        // Update the state with the calculated percentage
        const targetIndex = Math.round(
            (clickPercentage / 100) * (children.length - 1)
        );

        // Use slickGoTo method to set the slider index
        sliderRef?.slickGoTo(targetIndex);
    }

    function handleItmChange(index) {
        if (handleChange != null) handleChange(index);
        setActive(index);
    }
    function handleItmClick(index) {
        if (handleClick) handleClick(index);
        setActive(index);
    }

    // ========================= Render Function =================================
    if (settings != null)
        return (
            <Div
                wd={arrows ? "calc(100% - 30px)" : "100%"}
                center-aligned
                centered
            >
                <Slider
                    key={`slider-${sliderId}`}
                    ref={setSliderRef}
                    {...settings}
                >
                    {children.map((content, index) => {
                        return (
                            <div
                                key={`slideItm-${sliderId}-${index}`}
                                onClick={() => {
                                    handleItmClick(index);
                                }}
                                className={
                                    active === index
                                        ? "slideItm active"
                                        : "slideItm"
                                }
                            >
                                {content}
                            </div>
                        );
                    })}
                </Slider>
                {enableButtons && (
                    <Div
                        centered
                        tiny
                        clearfix
                        rounded
                        relative
                        padTop
                        wd={slidesToShow > 1 ? "100%" : "80%"}
                    >
                        <Icon
                            relative
                            float-left
                            circular
                            rimmed
                            inverted
                            snubbed
                            layer={2}
                            name="chevron left"
                            clickable={active !== 0}
                            primary={active !== 0}
                            onClick={sliderRef?.slickPrev}
                            disabled={active === 0}
                            className={active === 0 ? "disabled" : ""}
                        />
                        <Div
                            absolute
                            wd={"calc(100% - 20px)"}
                            peat
                            rimmed
                            spaced
                            clickable
                            onClick={handleJump}
                        />
                        <Icon
                            relative
                            float-right
                            circular
                            rimmed
                            inverted
                            snubbed
                            layer={2}
                            name="chevron right"
                            clickable={
                                active <=
                                children.length - parseInt(slidesToShow, 0)
                            }
                            primary={
                                active <=
                                children.length - 1 - parseInt(slidesToShow, 0)
                            }
                            className={
                                active >
                                children.length - 1 - parseInt(slidesToShow, 0)
                                    ? "disabled"
                                    : ""
                            }
                            disabled={
                                active >
                                children.length - 1 - parseInt(slidesToShow, 0)
                            }
                            onClick={sliderRef?.slickNext}
                        />
                    </Div>
                )}
            </Div>
        );
    else return <div />;
}
