// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { clientAssetsPath, useGetContestBuilderQuery } from "@api/apiV6";
import { useDeleteContestMutation } from "@api/apiV6";

import { Div, Image } from "@components/Generics.react";
import "@styles/navbars.scss";

import { Loader, Icon, Confirm } from "semantic-ui-react";
import ActionCard from "@components/ActionCard.react";
import PreviewLayout from "@layouts/PreviewLayout.react";

export default function ContestView(props) {
    const { id } = props;
    const { data: contest, isSuccess } = useGetContestBuilderQuery(id);

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteContest] = useDeleteContestMutation();

    const { t } = useTranslation("common");

    function doDelete() {
        const storedRecents = window.localStorage.getItem("libraryRecents");
        let libraryRecents = [];
        if (storedRecents) {
            libraryRecents = JSON.parse(storedRecents);
        }
        _.remove(libraryRecents, {
            key: contest._id,
        });
        window.localStorage.setItem(
            "libraryRecents",
            JSON.stringify(libraryRecents)
        );
        deleteContest(contest._id);
    }

    const actionMap = {
        edit: `/builder/contests/${contest?._id}/editor`,
        setup: `/builder/contests/${contest?._id}/setup`,
        learners: `/builder/contests/${contest?._id}/learners`,
        analytics: ``,
    };

    // ========================= Render Function =================================
    return isSuccess ? (
        <Div flex noWrap spaceAround fullht padTop padSides>
            <Div basepad txtCharcoal fullht autoOverflowY>
                <Div flex spaceBetween gapped>
                    <Div
                        fullht
                        wd="15px"
                        ht="175px"
                        style={{ background: "#800080" }}
                    />
                    <Div wd="calc(100% - 30px)">
                        <Div
                            float-right
                            big
                            clickable
                            style={{ marginTop: "15px" }}
                        >
                            <Icon
                                bordered
                                color="red"
                                inverted
                                circular
                                name="trash"
                                onClick={() => setDeleteConfirm(true)}
                            />
                        </Div>
                        <Div uppercase>
                            {t("components.edit")} {t("components.contest")}:
                        </Div>
                        <Div massive altText uppercase gutter>
                            {contest.name}
                        </Div>
                        <Div>
                            <Div wd="150px" float-left padRight>
                                <Image
                                    src={`${clientAssetsPath}/uploads/images/contest/${
                                        contest?._id
                                    }.${contest?.image
                                        ?.split(".")
                                        .pop()
                                        .toLowerCase()}`}
                                    snubbed
                                />
                            </Div>
                            <Div padded>{contest.description}</Div>
                        </Div>
                    </Div>
                </Div>
                <Div clear maxWd="460px">
                    <br />
                    <Div basepad ash snubbed>
                        {_.map(_.keys(actionMap), (act, idx) => (
                            <ActionCard
                                key={`${contest._id}-action-${idx}`}
                                icon={t(
                                    `builder.viewPanels.contest.${act}.icon`
                                )}
                                header={t(
                                    `builder.viewPanels.contest.${act}.header`
                                )}
                                content={t(
                                    `builder.viewPanels.contest.${act}.content`
                                )}
                                link={actionMap[act]}
                            />
                        ))}
                    </Div>
                </Div>
            </Div>
            <PreviewLayout
                url={`/contests/${contest.id}`}
                message={
                    contest.wrapper &&
                    contest.wrapper !== "none" &&
                    t("builder.dic.previewHelp")
                }
            />
            <Confirm
                open={deleteConfirm}
                onCancel={() => setDeleteConfirm(false)}
                onConfirm={() => {
                    doDelete();
                    setDeleteConfirm(false);
                    toast(t("builder.contest.deleted"));
                }}
            />
        </Div>
    ) : (
        <Loader active inverted />
    );
}
