
import { Div } from "@components/Generics.react";

export default function Header(props) {
    return <>
        <Div
            gutter
            big
            altText
            bold
            paletteColor="charcoal">
            {props.title || ""}
        </Div>
        <Div
            gutter
            compact
            wd="80%"
            primary
        />
    </>
}