import React, { useContext } from "react";
import { SlideContext } from "@layouts/SlideLayout.react";
import ReactPlayer from "react-player/lazy";
import { Div } from "@components/Generics.react";
import "@styles/readers.scss";

export default function VideoFullParagraph(props) {
    const { title, paragraph, video } = props.data;
    const { colors } = useContext(SlideContext);
    // ========================= Render Function =================================
    return (
        <Div className="video-full-paragraph" relative fluid fullht noOverflow>
            <Div fluid fullht relativ noOverflow>
                <ReactPlayer url={video} width="100%" height="100%" />
            </Div>
            <Div
                className="overlay-style"
                absolute
                fluid
                fullht
                bgColor={colors.primary}
            />
            <Div
                className="header-style"
                wd="80%"
                absolute
                basepad
                big
                bold
                bgColor={colors.base}
                txtColor={colors.baseText}
                large={!paragraph}
            >
                {title ? title : paragraph}
            </Div>
        </Div>
    );
}
