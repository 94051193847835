import React, { useState, useContext } from "react";
import _ from "lodash";
import { Div, Image, Button } from "@components/Generics.react";
import "@styles/readers.scss";
import { clientAssetsPath } from "@api/apiV6";
import { LayoutContext } from "@layouts/MasterLayout.react";

export default function Grid(props) {
    const { grid } = props.data;
    const [showImage, setShowImage] = useState(null);
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    return (
        <Div fluid zoom centered={!isPortrait} flex={!isPortrait} top-aligned>
            {(showImage == null || !isPortrait) && (
                <Div
                    wd={isPortrait ? "100%" : "45%"}
                    zoom
                    className="grid-container"
                >
                    {_.map(grid, (card, idx) => {
                        return (
                            <Image
                                fluid
                                clickable
                                key={`list-item-${idx}`}
                                className="grid-item"
                                src={`${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`}
                                onClick={() => setShowImage(idx)}
                            />
                        );
                    })}
                </Div>
            )}
            {showImage != null && (
                <Div
                    wd={isPortrait ? "100%" : "48%"}
                    zoom
                    centered={!isPortrait}
                >
                    <Image
                        fluid
                        src={`${clientAssetsPath}/uploads/qdf/slides/${grid[showImage].image.id}${grid[showImage].image.extension}`}
                    />
                    <Div
                        superpad
                        ash
                        relative
                        big={isPortrait}
                        layer={2}
                        style={{ marginTop: isPortrait ? 0 : "-100px" }}
                    >
                        {grid[showImage].text}
                    </Div>
                    <Div fit-content centered padTop>
                        <Button
                            content={"Close"}
                            primary
                            onClick={() => setShowImage(null)}
                        />
                    </Div>
                </Div>
            )}
        </Div>
    );
}
