import React from "react";
import _ from "lodash";
import { Grid } from "semantic-ui-react";

import { Div } from "@components/Generics.react";
import LocationMenu from "@components/LocationMenu.react";
import Banner from "@components/Banner.react";
import TopicCard from "@components/TopicCard.react";
import CourseHeader from "./CourseHeader.react";
import { clientAssetsPath } from "@api/apiV6";

export default function FormalWrapper(props) {
    const {
        locData,
        setActiveLoc,
        activeLoc,
        course,
        showMenu,
        closeMenu,
        openDeck,
        courseHeader = true,
    } = props;
    const isPortrait = window.innerHeight > window.innerWidth;

    const hasCurrent =
        _.map(locData, "status").includes("current") || activeLoc;
    const locationArray = locData.map((thisLoc, locIndex) => {
        if (thisLoc != null) {
            return (
                <TopicCard
                    key={`topic-${locIndex}`}
                    locIndex={locIndex}
                    colors={course.colors}
                    topic={_.omit(thisLoc, "status")}
                    status={
                        (!hasCurrent && locIndex === 0) ||
                        activeLoc === locIndex
                            ? "lastopen"
                            : thisLoc.status
                    }
                    clickable={
                        !course.sequential || thisLoc.status !== "incomplete"
                    }
                    handleClick={setActiveLoc}
                />
            );
        } else {
            return <Div key={"location-" + locIndex} className="no-loc" />;
        }
    });
    const imageExtensions = ["png", "jpeg", "jpg"];
    const extension = course?.image?.split(".").pop().toLowerCase();
    const imageUrl = `${clientAssetsPath}/uploads/images/course/${course?._id}.${extension}`;
    const imageSrc = !imageExtensions.includes(extension)
        ? "/assets/images/configurable/missing.jpg"
        : imageUrl;

    function showDeck(deckId, targetReaderType) {
        setActiveLoc(activeLoc);
        openDeck(deckId, targetReaderType);
    }

    // ========================= Render Function =================================
    return (
        <Div
            fullht={isPortrait && showMenu}
            padded={!isPortrait}
            noOverflow={isPortrait && showMenu}
        >
            <Grid stackable divided="vertically">
                <Grid.Row>
                    <Grid.Column width={isPortrait ? 16 : 8}>
                        {courseHeader && (
                            <CourseHeader course={course} compact />
                        )}
                        <Banner ads={[{ image: imageSrc }]} />
                        <br />
                        <Div rounded noOverflow slightShadow>
                            {locationArray}
                        </Div>
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
            </Grid>
            {isPortrait && showMenu && (
                <Div fullht className="overlay loc-menu" />
            )}
            <Div fullht>
                <LocationMenu
                    sequential={course.sequential}
                    showMenu={!isPortrait || showMenu}
                    content={locData[activeLoc]}
                    openDeck={showDeck}
                    closeMenu={isPortrait && closeMenu}
                />
            </Div>
        </Div>
    );
}
