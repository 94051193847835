import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Grid, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import MasterLayout from "@layouts/MasterLayout.react";
import { Div } from "@components/Generics.react";
import Banner from "@components/Banner.react";
import { useGetBannersQuery, useGetCourseLibraryQuery } from "@api/apiV6";
import CategoryCard from "@components/CategoryCard.react";
import RecommendedArticles from "@components/RecommendedArticles.react";
// import FilterField from "@components/FilterField.react";
import CourseCard from "@components/CourseCard.react";

export default function Library(props) {
    const { data: banners, isSuccess: bannerSuccess } = useGetBannersQuery();
    const { data: library, isSuccess: librarySuccess } =
        useGetCourseLibraryQuery();
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const [courses] = useState(library?.courses);
    // const [courses, setCourses] = useState(library?.courses);

    const isPortrait = window.innerHeight > window.innerWidth;

    const pageContent = (
        <Div fluid trench>
            <Grid stackable divided="vertically">
                <Grid.Row>
                    {bannerSuccess && (
                        <Grid.Column width={8}>
                            <Banner ads={banners} />
                        </Grid.Column>
                    )}
                    <Grid.Column width={bannerSuccess ? 8 : 16}>
                        <Div headline altText gutter>
                            {t("library.title")}
                        </Div>
                        <Div>{t("library.context")}</Div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Div gutter>
                <Divider horizontal>
                    {/* <FilterField
                        srcData={library?.courses}
                        setResult={setCourses}
                        searchKey="name"
                    /> */}
                </Divider>
            </Div>
            <Div padded={isPortrait}>
                <Grid
                    centered
                    columns={
                        librarySuccess &&
                        courses?.length === library?.courses?.length
                            ? isPortrait
                                ? 2
                                : 3
                            : isPortrait
                            ? 1
                            : 2
                    }
                >
                    {librarySuccess &&
                    courses?.length === library?.courses?.length ? (
                        <Grid.Row>
                            {librarySuccess &&
                                _.map(
                                    _.orderBy(
                                        _.filter(
                                            library.categories,
                                            (category) =>
                                                category.languages == null ||
                                                category.languages.length ===
                                                    0 ||
                                                (user.lang &&
                                                    category.languages.includes(
                                                        user.lang
                                                    ))
                                        ),
                                        ["priority"],
                                        ["desc"]
                                    ),
                                    (category, idx) => {
                                        const completion =
                                            _.meanBy(
                                                _.map(
                                                    _.map(
                                                        category.node_list,
                                                        "_id"
                                                    ),
                                                    (courseId) =>
                                                        library?.courses[
                                                            courseId
                                                        ].currentState
                                                            ? library?.courses[
                                                                  courseId
                                                              ].currentState
                                                                  .completion
                                                            : false
                                                ),
                                                _.identity
                                            ) * 100;
                                        return (
                                            <Grid.Column
                                                key={`category-card-${idx}`}
                                            >
                                                <CategoryCard
                                                    category={category}
                                                    completion={completion}
                                                />
                                            </Grid.Column>
                                        );
                                    }
                                )}
                        </Grid.Row>
                    ) : (
                        <Grid.Row>
                            {_.map(courses, (course, idx) => {
                                return (
                                    <Grid.Column key={`course-card-${idx}`}>
                                        <CourseCard
                                            course={{
                                                ...course,
                                                ...{
                                                    currentState:
                                                        library?.courses[
                                                            course._id
                                                        ].currentState,
                                                },
                                            }}
                                        />
                                    </Grid.Column>
                                );
                            })}
                        </Grid.Row>
                    )}
                </Grid>
            </Div>
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={"Welcome"}
            mainPanel={pageContent}
            rightPanelName={t(`rightPanel.recommended`)}
            rightPanel={<RecommendedArticles />}
        />
    );
}
